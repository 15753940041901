import React from 'react'
import styled from 'styled-components'
import { devices } from '../config'
import TurnCard from './TurnCard';

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    @media (max-width: ${devices.mobileM}px) {
        height: 100%;   
}
`
const TurnsContainer = styled.div`
    display: flex;
    width: 70%;
    flex-direction: column;
    box-sizing: border-box;
    align-items: start;
    justify-content: space-around;
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
    }
`
const Text = styled.div`
    
    width: 100%;
    max-width: 650px;
    color: #707070;
    text-align: left;
    font-family: 'Lato';
    align-self: start;
    margin-bottom: 10px;
    cursor: default;
    @media (max-width: ${devices.tablet}px) {
        font-size: 1rem;
        padding: 0 3rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1.5rem;
        width: 100%;
        padding: 0 3rem;
    }
`
const TextTitle = styled(Text)`
  font-size:24px;
  color: #7A7A7A;
  font-weight: bold;
  font-family: 'Ubuntu';
`
const baseButton = styled.a`
  height: 2rem;
  margin: 0 0.25rem;
  border-radius: 24px;
  font: Bold 1rem Lato;
  letter-spacing: 1.35px;
  text-decoration: none;
  cursor: pointer;
  display: flex;    
  align-items: center;
  justify-content: center;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  &:hover {
    background: linear-gradient(270deg, #d5ee51 0%, #3dcce9 100%);
  }
  @media (max-width: ${devices.tablet}px) {
    width: 20%;
    height: 4rem;
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom : 2rem;
  }
  @media (max-width: ${devices.mobileM}px) {
    width: 40%;
    height: 5rem;
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom : 2rem;
  }
`
const SecButton = styled(baseButton)`
  top: 2rem;
  left: 60rem;
  height: 2rem;
  margin: 20px;
  border-radius: 24px;
  padding: 0 10px;
  border: 2px solid #3dcce9;
  opacity: 1;
  color: #3dcce9;
  &:hover {
    color: white;
    border: 0;
  }
  @media (max-width: ${devices.mobileM}px) {
    width: 90%;
  }
`

function TurnDisplay(props) {
    
    const titleTurn = (props.turnData) ? "Estos son sus turnos agendados:" : "Usted no posee turnos agendados";
    const textTurno = (props.turnData) ? "Para reprogramar un turno agendado seleccione la opción <b>Cancelar Turno</b> y luego vuelva al menú <b>Turnos Online</b> para solicitar nuevo turno.": "Para sacar un nuevo turno seleccione la opción Solicitar un nuevo turno.";
    
    return(
      <Root>
        <Container>
          <TurnsContainer>
            <TextTitle>{titleTurn}</TextTitle>
            <Text dangerouslySetInnerHTML={{ __html: textTurno }}></Text>
            {
              (props.turnData) ?
                props.turnData.map((consult, index) => (
                  <TurnCard key={index} turnData={consult} actionDetail={props.handleActionDetail} actionCancelation={props.handleActionCancelation} ></TurnCard>
              ))
              :
              <SecButton target="_self" rel="noopener noreferrer" href="/turnos">SOLICITAR UN NUEVO TURNO</SecButton>
            }
          </TurnsContainer>
        </Container>     
      </Root>
    )
}

export default TurnDisplay;