import { bannerConstants } from '../constants';
import { alertActions } from './alert.actions';
import axios from 'axios';
import  { authHeader } from '../auth-header';

export const bannerActions = {
    createBanner,
    updateBanner,
    getBanners,
    deleteBanner,
    getKeyBanners
};

function createBanner(banner) {
    return dispatch => {
        axios
        .post( '/api/banners' , banner)
        .then(response => {
            dispatch(alertActions.success('Banner creado'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function updateBanner(id, banner) {
    return dispatch => {
        axios
        .put( '/api/banners/' + id, banner)
        .then(response => {
            dispatch(alertActions.success('Banner actualizado'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}


function getBanners() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
      }
    return dispatch => {
        dispatch({ type: bannerConstants.FETCHING });
        axios.get('/api/banners/all',config)
        .then(bannersData => {
            const banners = bannersData.data.banners
            dispatch({ type: bannerConstants.SUCCESS, banners });
        })
        .catch(err => {
            dispatch({ type: bannerConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function getKeyBanners(t) {
    return dispatch => {
        if (t === 'principal'){
            axios.get('/api/banners/key/principal')
            .then(principalData => {
                const principal = principalData.data.banners
                dispatch({ type: bannerConstants.PRINCIPAL_SUCCESS, principal });
            })
            .catch(err => {
                dispatch({ type: bannerConstants.ERROR });
                dispatch(alertActions.error('Hubo un error...'));
            })
        } else if(t === 'secondary'){
            axios.get('/api/banners/key/secondary')
            .then(secondaryData => {
                const secondary = secondaryData.data.banners
                dispatch({ type: bannerConstants.SECONDARY_SUCCESS, secondary });
            })
            .catch(err => {
                dispatch({ type: bannerConstants.ERROR });
                dispatch(alertActions.error('Hubo un error...'));
            })
        }
    }
}

function deleteBanner(id) {
    return dispatch => {
        axios
        .delete( '/api/banners/' + id)
        .then(response => {
            dispatch(alertActions.success('Banner eliminado'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}