import React from 'react'
import { connect } from 'react-redux';
import { alertActions } from '../../redux/actions/alert.actions';
import { publicationActions } from '../../redux/actions/publication.actions';
import axios from 'axios';
import styled from 'styled-components'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Input } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import BasicEditor from '../../components/textEditors/basicEditor';

const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
`

const ModalContent = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 60%;
    height: 90%;
    align-items: center;
    justify-content: center;
    overflow: auto;
`

const ModalTitle = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 10%;
    color: white;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #3f51b5;
`

const ModalContentSup = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 50%;
    padding-left: 25px;
    padding-right: 25px;
`

const ModalContentInf = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 40%;
    align-items: flex-start;
    justify-content: flex-start;
    padding-left: 25px;
    padding-right: 25px;
    flex-direction: column;
    gap: 20px;
`

const ModalContentSupLeft = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 65%;
    height: 100%;
`

const ModalContentSupRight = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 35%;
    height: 100%;
`

const Img = styled.img`
    position: relative;
    display: flex;
    width: 250px;
    height: 250px;
    align-self: center;
`

const Field = styled(TextField)`
    width: 85%;

`
const MediumField = styled(TextField)`
    width: 45%;
`

const TagTypeContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 85%;
`

const UploadImage = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
`

//Root Wrapper of the Editor 
const EditorWrapper = styled.div`
  min-width: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 95%;
  * {
    box-sizing: border-box;
  }
`;
//DraftEditor Container 
const EditorContainer = styled.div`
  display: flex;
  min-height: 9em;
  height: 100%;
  border-radius: 0 0 3px 3px;
  background-color: #fff;
  padding: 10px;
  font-size: 16px;
  font-weight: 300;
  box-shadow: 0px 0px 3px 1px rgba(15, 15, 15, 0.17);
  overflow: auto;
`;


function ModalCreatePub({ createPublication, alertMessage, user, handleClose, open }) {

    const [ image, setImage ] = React.useState('');
    const inputImageRef = React.useRef();
    const [ pub, setPub ] = React.useState({ 
        text: '',
        date: new Date(), 
        createdBy: user.id
    });
    const [ errors, setErrors ] = React.useState({
        title: false,
        date: false,
        img: false,
        tag: false,
        resumen: false,
        copete: false,
        text: false,
        publicationType: false,
        video: false
    })
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [publicationType, setPublicationType] = React.useState(null);    

    const handleCloseModal = () => {
        setErrors({
            title: false,
            date: false,
            img: false,
            tag: false,
            resumen: false,
            copete: false,
            text: false,
            publicationType: false,
            video: false
        })
        setImage('')
        setSelectedDate(new Date());
        setPub({ date: new Date() })
        handleClose();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPub({
            ...pub,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
    }
  
    

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setPub({
            ...pub,
            date: date
        })
        setErrors({
            ...errors,
            date: false
        })
    };

    const onFileChange = (e) => {
        setImage(e.target.files[0])
    }

    const handleFileUpload = (e) => {
        if(image !== '' ){
            if(!pub.img){
                const formData = new FormData()
                formData.append('image', image)
                axios.post("/api/images/upload", formData, {
                }).then(res => {
                    const url_img = '/uploads/'+res.data.filename
                    setPub({
                        ...pub,
                        img: url_img
                    })
                    setErrors({
                        ...errors,
                        img: false
                    })    
                })
                .catch(error => {
                    alertMessage('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                })
            } else {
                // eslint-disable-next-line no-restricted-globals
                if(confirm('¿Desea subir esta nueva imagen y reemplazar la anterior?')){
                    const formData = new FormData()
                    formData.append('image', image)
                    axios.post("/api/images/upload", formData, {
                    }).then(res => {
                        const url_img = '/uploads/'+res.data.filename
                        setPub({
                            ...pub,
                            img: url_img
                        })
                        setErrors({
                            ...errors,
                            img: false
                        })    
                    })
                    .catch(error => {
                        alertMessage('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                    })  
                }
            }
        }else{
            alertMessage("Debe selecionar una imagen...")
        }
    }

    

    const handleChangePublicationType = (event) => {
        setPublicationType(event.target.value);
        setPub({
            ...pub,
            publicationType: event.target.value,
            link: '/'+event.target.value+'/',
        })
        setErrors({
            ...errors,
            publicationType: false
        }) 
    };

    const handleForm = async (e) => {
        e.preventDefault();
        if(pub.title && pub.date && pub.img && pub.tag && pub.resumen && pub.copete && pub.text && pub.publicationType && pub.createdBy){
            createPublication(pub)
            handleCloseModal()            
        }else{
            setErrors({
                title: !(pub.title),
                date: !(pub.date),
                img: !(pub.img),
                tag: !(pub.tag),
                resumen: !(pub.resumen),
                copete: !(pub.copete),
                text: !(pub.text),
                publicationType: !(pub.publicationType),
                video: !(pub.video),
            })
        }
    }

    const handleEditor = (e, dataeditor) => {
        setPub({
            ...pub,
            text: dataeditor
        })
    }


    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <ModalContent>
                    <ModalTitle>
                        CREAR PUBLICACIÓN
                        <Button variant="contained" onClick={handleForm}>PUBLICAR</Button>
                    </ModalTitle>
                    <ModalContentSup>
                        <ModalContentSupLeft>
                            <Field id="title" name="title" label="Título de la publicación" variant="outlined" size='small' error={errors.title} onChange={handleChange} required />
                            <TagTypeContainer>
                                <MediumField id="tag" name="tag" label="Etiqueta" variant="outlined" size='small' error={errors.tag} onChange={handleChange} required />
                                <FormControl style={{ margin: '2px', width: '45%'}}>
                                    <InputLabel error={errors.publicationType} id="publicationType" required>Tipo de publicación</InputLabel>
                                    <Select
                                        labelId="publicationType"
                                        id="publicationType-select"
                                        value={publicationType}
                                        onChange={handleChangePublicationType}
                                        error={errors.publicationType}
                                    >
                                        <MenuItem value={'evento'}>EVENTO</MenuItem>
                                        <MenuItem value={'noticia'}>NOTICIA</MenuItem>
                                    </Select>
                                </FormControl>
                            </TagTypeContainer>
                            <Field id="resumen" name="resumen" label="Resumen" variant="outlined" size='medium' multiline rows="2" error={errors.resumen} onChange={handleChange} required />
                            <Field id="copete" name="copete" label="Introducción" variant="outlined" size='medium' multiline rows="6" error={errors.copete} onChange={handleChange} required />
                        </ModalContentSupLeft>
                        <ModalContentSupRight>
                            <Img src={pub.img ? pub.img : "https://colegiolovaina.cl/wp-content/plugins/learnpress/assets/images/no-image.png"} alt="Imagen subida" />
                            
                            <UploadImage>
                                <Input style={{fontSize: '0.75rem', width: '300px', color: errors.img ? 'red' : 'black'}} ref={inputImageRef} id="image" name="image" type="file" onChange={onFileChange} error={errors.img} required /><CloudUploadIcon onClick={handleFileUpload} />
                            </UploadImage>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="dd/MM/yyyy"
                                    margin="normal"
                                    id="date"
                                    name="date"
                                    label="Fecha"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>
                        </ModalContentSupRight>
                    </ModalContentSup>
                    <ModalContentInf>
                        {
                            pub &&
                            <BasicEditor
                                name="text"
                                value={pub.text}
                                onChange={handleEditor}
                                maxContentHeight="100px"
                                minContentHeight="100px"
                                placeholder="Inserte aquí el contenido de la publicación"
                            />
                        }
                        <Field id="video" name="video" label="Video URL" variant="outlined" size='medium' error={errors.video} onChange={handleChange} />
                    </ModalContentInf>
                </ModalContent>
            </Fade>
        </ModalStyled>
    )
}

function mapState(state) {
    const { user } = state.authentication;
    return { user };
  }
  
const actionCreators = {
    createPublication: publicationActions.createPublication,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(ModalCreatePub);