import React from 'react'
import styled from 'styled-components'
import { devices } from '../config'
import IconDireccion from '../icons/inicio/direccion.svg'
import IconPractice from '../icons/inicio/icon_estudio.svg'
import IconSchedule from '../icons/inicio/icon_fecha.svg'

const Root = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    @media (max-width: ${devices.mobileM}px) {
        height: 100%;   
}
`
const TurnsContainer = styled.div`
    display: flex;
    width: 80%;
    flex-direction: column;
    box-sizing: border-box;
    align-items: start;
    justify-content: space-around;
    align-items: center;
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
    }
`
const Icon = styled.img`
    width: 1.2rem;
    height: 1.2rem;
    margin-right:5px;
    @media (max-width: ${devices.mobileM}px) {
        width: 3rem;
        height: 3rem;
    }
`
const Text = styled.span`
    font-size: 13px;
    font-family: 'Lato';
    font-weight: 700;
    color: #000;
    opacity: 0.92;
    text-align: left;
    @media (max-width: ${devices.tablet}px) {
      font-size: 1.5rem; 
     }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 2.5rem;
    }
`
const GreyText = styled(Text)`
  font: normal normal normal 12px/16px Lato;
  color: #707070;
  font-family: 'Ubuntu';
`

const TextTitle = styled(Text)`
  font-size:24px;
  color: #E55858;
  font-weight: bold;
  font-family: 'Ubuntu';
`
const baseButton = styled.a`
  height: 2rem;
  margin: 0 0.25rem;
  border-radius: 24px;
  font: Bold 1rem Lato;
  letter-spacing: 1.35px;
  text-decoration: none;
  cursor: pointer;
  display: flex;    
  align-items: center;
  justify-content: center;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  &:hover {
    background: linear-gradient(270deg, #d5ee51 0%, #3dcce9 100%);
  }
  @media (max-width: ${devices.tablet}px) {
    width: auto;
    height: 5rem;
    font-size: 2.3rem;
    margin-top: 2rem;
    margin-bottom : 2rem;
  }
  @media (max-width: ${devices.mobileM}px) {
    width: auto;
    font-size: 2.3rem;
    height: 5rem;
    margin-top: 2rem;
    margin-bottom : 2rem;
  }
`
const SecButton = styled(baseButton)`
  top: 2rem;
  left: 60rem;
  height: 2rem;
  margin: 20px;
  border-radius: 24px;
  padding: 0 10px;
  border: 2px solid #3dcce9;
  opacity: 1;
  color: #3dcce9;
  &:hover {
    color: white;
    border: 0;
  }
`
const TurnContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    width:100%;
    height:auto;
    opacity: 1;
    border: solid 1px;
    padding: 10px;
    border-radius: 25px;
    color: grey;
    margin: 10px;
    cursor: default;
    justify-content: space-between;
    @media (max-width: ${devices.mobileM}px) {
      height: auto;
      margin: 5px auto;
      width: auto;
      flex-direction: column;
    }
`
const FieldContainer = styled.div`
    display: flex;  
    width: ${(props) => props.width ? props.width :'30%'};
    justify-content: flex-start;
    align-items: center;
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
        margin:10px 0 ;
        padding: 0 10px;
    }
`
function TurnProvious(props) {
    
    const titleTurn = "Usted ya posee un turno agendado para esta práctica";
    const textTurno = "En caso de querer reprogramar su turno o visualizar mas informacion, vaya al menú <b>Turnos online</b> y elija la opción <b>Consultar turno agendado</b>, ahí seleccione y cancele su turno actual. Posteriormente podrá sacar un nuevo turno con normalidad.";
    
    return(
      <Root>
            <Container>
                <TurnsContainer>
                  <TextTitle>{titleTurn}</TextTitle>
                  <TurnContainer>
                    <FieldContainer width="40%">
                      <Icon src={IconPractice}/> 
                      <Text>{props.turnData.agenda} </Text>
                    </FieldContainer>
                    <FieldContainer>
                      <Icon src={IconDireccion}/> 
                      <Text>{props.turnData.nombreSucursal} </Text>
                    </FieldContainer>
                    <FieldContainer>
                      <Icon src={IconSchedule}/> 
                      <Text>{props.turnData.fecha} </Text>
                    </FieldContainer> 
                  </TurnContainer>                 
                  <GreyText dangerouslySetInnerHTML={{ __html: textTurno }}></GreyText>
                </TurnsContainer>
                <SecButton target="_self" rel="noopener noreferrer" href="/TurnConsultatiosPage">CONSULTA TURNO AGENDADO</SecButton>
            </Container>     
      </Root>
    )
}

export default TurnProvious;