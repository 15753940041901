import { bannerConstants } from '../constants';

let banners = JSON.parse(localStorage.getItem('banners'));
const initialState = banners ? 
  { 
      error: false,
      isFetching: false,
      banners: banners
  } : 
  { 
    error: false,
    isFetching: true,
  };

export function banner(state = initialState, action) {
  switch (action.type) {
    case bannerConstants.FETCHING:
      return { 
            ...state,
            isFetching: true
        };
    case bannerConstants.SUCCESS:
      return { 
            ...state,
            isFetching: false,
            banners: action.banners
        };
    case bannerConstants.PRINCIPAL_SUCCESS:
      return { 
            ...state,
            isFetching: false,
            principal: action.principal
        };
    case bannerConstants.SECONDARY_SUCCESS:
      return { 
            ...state,
            isFetching: false,
            secondary: action.secondary
        };
    case bannerConstants.ERROR:
      return { 
            ...state,
            error: true
        };
    default:
      return state
  }
}