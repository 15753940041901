import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => ({
  root: {
    width: '90%',
  },
  heading: {
    fontSize: "18px",
    fontFamily: "Lato",
    color: '#7A7A7A',
    letterSpacing: "0.96px",
  },
}));

function SimpleExpansionPanel(props){
  const classes = useStyles();
  return (
    <div className={classes.root}>
        <ExpansionPanel>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon style={{ color: "#7A7A7A" }}/>} aria-controls="panel1a-content" id="panel1a-header">
                <Typography className={classes.heading}>{props.question}</Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
                <Typography className={classes.heading}>
                {props.answer}
                </Typography>
            </ExpansionPanelDetails>
        </ExpansionPanel>
    </div>
  )
}

export default SimpleExpansionPanel