import { publicationConstants } from '../constants';
import { alertActions } from './alert.actions';
import axios from 'axios';
import  { authHeader } from '../auth-header';

export const publicationActions = {
    createPublication,
    updatePublication,
    getPublications,
    deletePublication,
    getTypePublications
};

function createPublication(publication) {
    return dispatch => {
        axios
        .post( '/api/publications' , publication)
        .then(response => {
            dispatch(alertActions.success('Publicación creada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function updatePublication(id, publication) {
    return dispatch => {
        axios
        .put( '/api/publications/' + id, publication)
        .then(response => {
            dispatch(alertActions.success('Publicación actualizada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}


function getPublications() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
      }
    return dispatch => {
        dispatch({ type: publicationConstants.FETCHING });
        axios.get('/api/publications/all',config)
        .then(publicationsData => {
            const publications = publicationsData.data.publications
            dispatch({ type: publicationConstants.SUCCESS, publications });
        })
        .catch(err => {
            dispatch({ type: publicationConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function getTypePublications(t) {
    return dispatch => {
        if (t === 'evento'){
            axios.get('/api/publications/type/evento')
            .then(eventsData => {
                const events = eventsData.data.publications
                dispatch({ type: publicationConstants.EVENT_SUCCESS, events });
            })
            .catch(err => {
                dispatch({ type: publicationConstants.ERROR });
                dispatch(alertActions.error('Hubo un error...'));
            })
        } else if(t === 'noticia'){
            axios.get('/api/publications/type/noticia')
            .then(newsData => {
                const news = newsData.data.publications
                dispatch({ type: publicationConstants.NEWS_SUCCESS, news });
            })
            .catch(err => {
                dispatch({ type: publicationConstants.ERROR });
                dispatch(alertActions.error('Hubo un error...'));
            })
        }
    }
}

function deletePublication(id) {
    return dispatch => {
        axios
        .delete( '/api/publications/' + id)
        .then(response => {
            dispatch(alertActions.success('Publicación eliminada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}