import { publicationConstants } from '../constants';

let publications = JSON.parse(localStorage.getItem('publications'));
const initialState = publications ? 
  { 
      error: false,
      isFetching: false,
      publications: publications
  } : 
  { 
    error: false,
    isFetching: true,
  };

export function publication(state = initialState, action) {
  switch (action.type) {
    case publicationConstants.FETCHING:
      return { 
            ...state,
            isFetching: true
        };
    case publicationConstants.SUCCESS:
      return { 
            ...state,
            isFetching: false,
            publications: action.publications
        };
    case publicationConstants.EVENT_SUCCESS:
      return { 
            ...state,
            isFetching: false,
            events: action.events
        };
    case publicationConstants.NEWS_SUCCESS:
      return { 
            ...state,
            isFetching: false,
            news: action.news
        };
    case publicationConstants.ERROR:
      return { 
            ...state,
            error: true
        };
    default:
      return state
  }
}