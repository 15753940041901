import React from 'react'
import styled from 'styled-components'
import { devices } from '../config'

const Root = styled.div`
    position: relative;
    display: flex;
    flex: auto;
    flex-direction: column;
    padding-left: 10%;
    padding-right: 10%;
    box-sizing: border-box;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${(props) => props.background});
        background-position: center;
        background-attachment: fixed;
        background-repeat: no-repeat;
        z-index: -1;
    }
`

const TitleStyled = styled(Root)`
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    color: white;
    font-family: 'Ubuntu';
    font-weight: 500;
    font-size: 5vmin;
    height: 40%;
    @media (max-width: ${devices.tablet}px) {
        height: 30%;
    }
    @media (max-width: ${devices.mobileM}px) {
        height: 20%;
    }
`

const NumbersSection = styled(Root)`
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    height: 60%;
    @media (max-width: ${devices.tablet}px) {
        height: 70%;
    }
    @media (max-width: ${devices.mobileM}px) {
        flex-direction: column;
        height: 80%;
        justify-content: flex-start;
    }
`

const NumberContainer = styled(Root)`
    padding: 0;
    align-items: center;
    justify-content: center;
    width: 25%;
    height: 60%;
    @media (max-width: ${devices.tablet}px) {
        width: 50%;
        height: 40%;
    }
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
        height: 22%;
        max-height: 22%;
    }
`

const NumberTitle = styled.div`
    position: absolute;
    top:15%;
    height: 50%;
    font-family: Arial;
    font-size: ${(props) => props.size};
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    background: -webkit-linear-gradient(291deg, #D5EE51 0%, #3DCCE9 100%);
    background-clip: border-box;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    @media (max-width: ${devices.mobileM}px) {
        top:10%;
        font-size: 8rem;
    }
`

const NumberSubtitle = styled.div`
    position: absolute;
    bottom:8%;
    color: white;
    font-size: 1rem;
    font-weight: 700;
    width: 50%;
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1.5rem;
        bottom:25%;
    }
`

const Divider = styled.div`
    position: absolute;
    bottom:0;
    width: 20%;
    height: .2vmin;
    background-color: white;
    @media (max-width: ${devices.mobileM}px) {
        bottom:16%;
    }
`

const Title = ({children}) => (
    <TitleStyled>
        {children}
    </TitleStyled>
)

const Number = ({ title, titleSize, subtitle }) => (
    <NumberContainer>
        <NumberTitle size={titleSize}>
            {title}
        </NumberTitle>
        <NumberSubtitle>
            {subtitle}
        </NumberSubtitle>
        <Divider />
    </NumberContainer>
)

function SomeNumbers(props) {
      return(
        <Root background={props.background}>
            <Title>
                Somos expertos en <br/> brindar resultados confiables
            </Title>
            <NumbersSection>
                {
                    props.data.map((number, index) => (
                        <Number key={index} title={number.title} titleSize={number.titleSize} subtitle={number.subtitle} />        
                    ))
                }
            </NumbersSection>
        </Root>
      )
  }
  
  export default SomeNumbers