import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { chatbot } from './chatbot.reducer';
import { alert } from './alert.reducer';
import { publication } from './publication.reducer'
import { banner } from './banner.reducer'
import { slider } from './slider.reducer'
import { professional } from './professional.reducer'
import { indication } from './indication.reducer'
import { coverage } from './coverage.reducer'
import { medicalService } from './medical_service.reducer'
import { recommendation } from './recommendation.reducer'
import { qualityPolicy } from './quality_policy.reducer'
import { page } from './page.reducer'
import { section } from './section.reducer'
import { turn } from './turn.reducer'

const rootReducer = combineReducers({
  authentication,
  registration,
  alert,
  chatbot,
  publication,
  banner,
  slider,
  professional,
  indication,
  coverage,
  medicalService,
  recommendation,
  qualityPolicy,
  page,
  section,
  turn
});

export default rootReducer;