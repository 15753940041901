import React from 'react'
import styled from 'styled-components'
import TitleSection from './TitlePage'
import { devices } from '../config'
import { connect } from 'react-redux';
import { alertActions } from '../redux/actions/alert.actions';
import { sectionActions } from '../redux/actions/section.actions';
import { pageActions } from '../redux/actions/page.actions';
import { turnActions } from '../redux/actions/turn.actions';
import TextField from '@material-ui/core/TextField';
import ModalComponent from './ModalComponent';
import ModalHeader from './ModalHeader';
import axios  from 'axios';
import { config } from "../config";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import TurnDisplay from './TurnDisplay';
import TurnDetail from './TurnDetail';
import { history } from '../redux/history';

var CryptoJS = require("crypto-js");

const Root = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  position: relative;
`
const Title = styled.section`
  height: 32vh;
  width: 100%;
  display: flex;
  flex: auto;
  align-items: stretch;
  justify-content: center;
  `
const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content:center;
min-height: 48vh;
padding: 2vh 0;
@media (max-width: ${devices.mobileM}px) {
  height: 100%; 
  justify-content:flex-start;
  margin-top: 20px;
}
`
const FormButton = styled.div`
  height: 2rem;
  width: 13rem;
  margin: 20px;
  border-radius: 24px;
  border: none;
  background: linear-gradient(96deg, #3DCCE9 0%, #87E8FC 100%);
  color: white;
  font: Bold 1rem Lato;
  letter-spacing: 1.35px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  ${(props) => props.enabled ? '' : 'opacity: 0.5'};
  ${(props) => props.enabled ? 'cursor: pointer' : 'cursor: default'};
  &:hover {
    ${(props) => props.enabled ? 'background: linear-gradient(270deg, #d5ee51 0%, #3dcce9 100%)' : ''};
    
  }
  @media (max-width: ${devices.tablet}px) {
      width: 100%;
      height: 3.5rem;
      font-size: 1.5rem;
  }
  @media (max-width: ${devices.mobileM}px) {
      width: 100%;
      height: 40px;
      font-size: 2.5rem;
  }
`

const FormContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 70%;
    height: 150px;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    
    @media (max-width: ${devices.mobileM}px) {
      width: 100%;
      flex-direction: column;
      padding: 0 3rem
    }
`

const TurnsContainer = styled.div`
    display: flex;
    width: 70%;
    flex-direction: column;
    box-sizing: border-box;
    align-items: start;
    justify-content: space-around;
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
    }
`
const TextContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    width: 400px;
    height: 60%;
    @media (max-width: ${devices.mobileM}px) {
      width: 100%;

    }
`
const StyledTextField = styled(TextField)`  
    width: 100%; 
    & label.MuiFormLabel-root {
        font-size: 1rem;
        line-height: 1.5rem;
        font-family: Lato;
        color: #999;
    }
    & .MuiInputLabel-asterisk {
        display: none;
    }
    & input.MuiInputBase-input {
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-family: Lato;
        color: #000;
        opacity: 0.92;
    }
    & .MuiInput-underline:after{
        border-bottom: 2px solid #3DCCE9;
    }
    @media (max-width: ${devices.mobileM}px) {
        & .MuiFormHelperText-root {
          font-size: 1.75rem;
        }
        & label.MuiFormLabel-root {
            font-size: 2.75rem;
            line-height: 3.5rem;
        }
        & input.MuiInputBase-input {
            font-size: 3rem;
            line-height: 3.75rem;
        }
    }
`
const Text = styled.div`
    
    width: 100%;
    max-width: 650px;
    color: #707070;
    text-align: left;
    font-family: 'Lato';
    align-self: start;
    margin-bottom: 10px;
    cursor: default;
    @media (max-width: ${devices.tablet}px) {
        font-size: 1rem;
        padding: 0 3rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 3.5rem;
        width: 100%;
        padding: 0 3rem;
    }
`
const TextTitle = styled(Text)`
  font-size:24px;
  color: #7A7A7A;
  font-weight: bold;
  font-family: 'Ubuntu';
`

function TurnConsultatiosPage({ match, error, isFetching, sections, getSections, alertMessage, pages, getPages }) {
  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (!sections) {
      getSections()
    }
    if (!pages) {
      getPages()
    }
  }, [sections, getSections, getPages, pages])

  const [lblDNI, setlblDNI] = React.useState("");
  const [consultedDNI, setConsultedDNI] = React.useState("");
  const [turnData, setTurnData] = React.useState(null);
  const [openDetail, setOpenDetail] = React.useState(false);
  const [openCancelation, setOpenCancelation] = React.useState(false);
  const [selectedTurn, setSelectedTurn] = React.useState(null);

  const handleClose = () => {
    setOpenDetail(false);
    setOpenCancelation(false);
  }; 

  const handleCancelTurn = (e) => {
    setOpenDetail(false);
    setOpenCancelation(false);
    history.push('/turnos/cancelarturno/'+e.idturno+'/'+e.claveCancelacion);
  };

  const lblDNIChange = (e) => {
    setlblDNI(e.target.value);
  }

  const showDetailPopup = (e) =>{
    setSelectedTurn(e);
    setOpenDetail(true);
  }

  const showCancelationPopup = (e) =>{
    
    setSelectedTurn(e);
    setOpenCancelation(true);
  }

  const handleVerify = (recaptchaToken) => {
  }

  const consultTurn = (e) => {
    

    axios
      .get("/api/clinica/turnConsultant/" + lblDNI )
      .then(response => {
        if(response.data){
          const responseData = response.data.cipherTurnData;
          var bytes = CryptoJS.AES.decrypt(responseData, config.cryptoKey);
          var decryptedTurnData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
          setTurnData(decryptedTurnData);
          setConsultedDNI(lblDNI);
        }
      })
      .catch(error => {
          alert('Hubo un error al intentar confirmar el turno, verifique los datos y reintente la operación')
      })
  }

  return (
    <Root>
      {
        (!sections) ? <h1>CARGANDO...</h1> :
        <React.Fragment>
            <Title>
              <TitleSection title={'CONSULTAR TURNOS AGENDADOS'} background={sections[6].img} />
            </Title>
            {
              <Container>
                <TurnsContainer>
                  <TextTitle>
                  Ingrese DNI del paciente:
                  </TextTitle>
                </TurnsContainer>
                <FormContainer>
                  <TextContainer>
                    <StyledTextField id="patient_DNI" name="patientDNI" type="text" label="DNI" value={lblDNI}  helperText='Ingrese solamente números' onChange={lblDNIChange} required />
                    <GoogleReCaptchaProvider reCaptchaKey={config.reCAPTCHAKey}>
                        <GoogleReCaptcha onVerify={handleVerify} />
                    </GoogleReCaptchaProvider>
                  </TextContainer>
                  <FormButton onClick={consultTurn} enabled={lblDNI !== ""}>CONSULTAR TURNOS</FormButton>
                </FormContainer>
                {
                (consultedDNI) &&
                <> 
                  <TurnDisplay turnData={turnData} handleActionDetail={showDetailPopup} handleActionCancelation={showCancelationPopup}></TurnDisplay>
                </>
                }
              </Container>
            }
            <ModalComponent open={openDetail} handleClose={handleClose} >
              <ModalHeader handleClose={handleClose}></ModalHeader>
              <TurnDetail dni={consultedDNI} turnData ={selectedTurn} showDetail={true} ></TurnDetail>
            </ModalComponent>
            <ModalComponent open={openCancelation} handleClose={handleClose} >
              <ModalHeader handleClose={handleClose}></ModalHeader>
              <TurnDetail dni={consultedDNI} turnData ={selectedTurn} showDetail={false} handleCancelTurn={handleCancelTurn} ></TurnDetail>
            </ModalComponent>
          </React.Fragment>
      }
    </Root>
  )
}

function mapState(state) {
  const { error, isFetching, sections } = state.section;
  const { turnError, turnIsFetching, turns } = state.turn;
  const { pages } = state.page;
  return { error, isFetching, sections, pages, turns, turnError, turnIsFetching };
}

const actionCreators = {
  getSections: sectionActions.getSections,
  getTurns: turnActions.getTurns,
  getPages: pageActions.getPages,
  alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(TurnConsultatiosPage);
