import React from 'react'
import styled from 'styled-components'
import TitleSection from './TitlePage'
import Selector from './Selector'
import IconDireccion from '../icons/inicio/direccion.svg'
import IconPractice from '../icons/inicio/icon_estudio.svg'
import IconSchedule from '../icons/inicio/icon_fecha.svg'
import FlechaAdelante from '../icons/inicio/icon_arrow.svg'
import { devices } from '../config'
import { connect } from 'react-redux';
import { alertActions } from '../redux/actions/alert.actions';
import { sectionActions } from '../redux/actions/section.actions';
import { pageActions } from '../redux/actions/page.actions';
import { turnActions } from '../redux/actions/turn.actions';
import ModalComponent from './ModalComponent';
import SucursalesSelector from './SucursalesSelector';
import PracticeSelector from './PracticeSelector';
import ScheduleSelector from './ScheduleSelector';
import ModalHeader from './ModalHeader';
import axios  from 'axios';
import { config } from "../config";
import StepperPatient from './StepperPatient';
import Moment from 'moment';

var CryptoJS = require("crypto-js");

const Root = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  position: relative;
`
const Title = styled.section`
  height: 40%;
  width: 100%;
  display: flex;
  flex: auto;
  align-items: stretch;
  justify-content: center;
  `
const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content:center;
height: 100%;
@media (max-width: ${devices.mobileM}px) {
    height: 100%;   
}
`
const FormButton = styled.div`
  height: 2rem;
  width: 13rem;
  margin: 20px;
  border-radius: 24px;
  border: none;
  background: linear-gradient(96deg, #3DCCE9 0%, #87E8FC 100%);
  color: white;
  font: Bold 1rem Lato;
  letter-spacing: 1.35px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  ${(props) => props.enabled ? '' : 'opacity: 0.5'};
  ${(props) => props.enabled ? 'cursor: pointer' : 'cursor: default'};
  &:hover {
    ${(props) => props.enabled ? 'background: linear-gradient(270deg, #d5ee51 0%, #3dcce9 100%)' : ''};
    
  }
  @media (max-width: ${devices.tablet}px) {
      width: 100%;
      height: 3.5rem;
      font-size: 1.5rem;
  }
  @media (max-width: ${devices.mobileM}px) {
      width: 100%;
      height: 40px;
      font-size: 2.5rem;
  }
`
const baseButton = styled.a`
  height: 2rem;
  margin: 0 0.25rem;
  border-radius: 24px;
  font: Bold 0.75rem Lato;
  letter-spacing: 1.35px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  &:hover {
    background: linear-gradient(270deg, #d5ee51 0%, #3dcce9 100%);
  }
  @media (max-width: ${devices.tablet}px) {
    width: 20%;
    height: 4rem;
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom : 2rem;
  }
  @media (max-width: ${devices.mobileM}px) {
    width: 40%;
    height: 5rem;
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom : 2rem;
  }
`

const SecButton = styled(baseButton)`
  top: 2rem;
  left: 60rem;
  width: 9rem;
  height: 2rem;
  border: 1px solid #3dcce9;
  opacity: 1;
  color: #3dcce9;
  &:hover {
    color: white;
    border: 0;
  }
`


const FormContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 40%;
    height: 100%;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2.5%;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-around;
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
    }
`
const ConfirmedTurnTittleContainer = styled.div`
    position: relative;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align:left;
    width: 100%;
    height: 100%;
    color: #3DCCE9;
    box-sizing: border-box;
    @media (max-width: ${devices.tablet}px) {
        height: auto;
    }
`
const ConfirmedTurnTittle = styled.div`
    position: relative;
    text-align: left;
    font-family: 'Ubuntu';
    font-weight: 900;
    font-size: 200%;
    width: 450px;
    margin: 30px 10% 20px 10%;
    @media (max-width: ${devices.tablet}px) {
      font-size: 3.5rem;
    }
    @media (max-width: ${devices.mobileM}px) {
      width: 100%;
      font-size: 4rem;
      padding: 0 3rem;
    }
`
const Text = styled.div`
    position: relative;
    width: 410px;
    min-height: 20%;
    color: #7A7A7A;
    text-align: left;
    font-family: 'Lato';
    font-weight: 300;
    font-size: 100%;
    margin: 0 10%;
    @media (max-width: ${devices.tablet}px) {
        font-size: 1rem;
        padding: 0 3rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1.5rem;
        width: 100%;
        padding: 0 3rem;
    }
`

function TurnosPage({ match, error, isFetching, sections, getSections, alertMessage, pages, getPages }) {
  React.useEffect(() => {
    window.scrollTo(0, 0)
    if (!sections) {
      getSections()
    }
    if (!pages) {
      getPages()
    }
  }, [sections, getSections, getPages, pages])

  const [openSuc, setOpenSuc] = React.useState(false);
  const [openPrac, setOpenPrec] = React.useState(false);
  const [openSche, setOpenSche] = React.useState(false);
  const [openStepperModal, setOpenStepperModal] = React.useState(false);
  const [selectSubsidiary, setSelectSubsidiary] = React.useState(null)
  const [selectPractice, setSelectPractice] = React.useState(null)
  const [selectSchedule, setSelectSchedule] = React.useState(null)
  const [labelPractices, setLabelPractices] = React.useState('Seleccione práctica')
  const [labelSchedule, setLabelSchedule] = React.useState('Seleccione fecha y hora')
  const [confirmedTurn, setconfirmedTurn] = React.useState(false);
  const [confirmedTurnWithEmail, setConfirmedTurnWithEmail] = React.useState(false);

  const selectedSubsidiary = (id, text) => {
    setSelectSubsidiary({ id, text });
    setSelectPractice(null);
    setSelectSchedule(null);
    setLabelPractices('Seleccione práctica');
    setLabelSchedule('Seleccione fecha y hora');
    handleClose();
  }

  const selectedPractice = (Practices) => {
    setSelectPractice(Practices);
    let listPractices = '';
    Practices.forEach(element => {
      listPractices = listPractices === '' ? element.value : listPractices + ", " + element.value;
    });
    if(listPractices.length >35){
      listPractices = listPractices.substr(0,35)+'...'
    }
    setSelectSchedule(null);
    setLabelSchedule('Seleccione fecha y hora');  
    setLabelPractices(listPractices)
    handleClose();
  }

  const selectedSchedule = (date, time, practices) => {
    setSelectSchedule({date,time,practices});
    setLabelSchedule (date.toLocaleDateString() +' - '+ time);
    handleClose();
  }
  const handleSucursalOpen = () => {
    setOpenSuc(true);
  };
  const handlePracticeOpen = (e) => {
    setOpenPrec(true);
  };
  const handleScheduleOpen = (e) => {
    setOpenSche(true);
  };
  const handleStepperOpen = (e) => {
    setOpenStepperModal(selectSchedule != null);
  };

  const handleClose = () => {
    setOpenSuc(false);
    setOpenPrec(false);
    setOpenSche(false);
  };

  const handleCloseStepperModal = () => {
    setOpenStepperModal(false);
  };

  const confirmTurn = (patientData) => {

    let data={};
    let turnos =[];
    let practices = Object.keys(selectSchedule.practices);
    let SiguienteTurno=0;
    let fechaHora= new Date(selectSchedule.date);
    fechaHora.setHours(selectSchedule.time.split(':')[0]);
    fechaHora.setMinutes(selectSchedule.time.split(':')[1]);
    practices.forEach(element => {
      var turno = {
        "fecha": Moment(fechaHora.setMinutes(fechaHora.getMinutes()+(practices.indexOf(element) + SiguienteTurno))).format('YYYY-MM-DDTHH:mm') + ':00-03:00',
        "dni": patientData.patientDNI,
        "nombre": patientData.patientName,
        "apellido": patientData.patientSurname,
        "fechaNacimiento": patientData.patientBirthDate,
        "telefono": patientData.patientTelephone,
        "idPractica": element,
        "idAgendaFk": (selectSchedule.practices[element])[0],
        "email" : patientData.patientEmail,
        "idOs" : patientData.patientIdOsFk,
      }
      if (patientData.patientId !== '0'){
        turno = {...turno, "idPaciente": patientData.patientId}
      }
      if(patientData.patientEmail)
      {
        setConfirmedTurnWithEmail(true);
      }
      turnos.push(turno);
      SiguienteTurno=15;
    });
    
    data={"turnos": turnos};

    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), config.cryptoKey).toString();

    axios
      .post("/api/clinica/confirmTurn", { data: ciphertext })
      .then(response => {
        if(response.data){
          if (response.data.status === 'SUCCESS'){
            setconfirmedTurn(true);
            setOpenStepperModal(false); 
          }
          else{
            setconfirmedTurn(false);
            alert('Hubo un error al intentar confirmar el turno, verifique los datos y reintente la operación');
          }
        }
      })
      .catch(error => {
          alert('Hubo un error al intentar confirmar el turno, verifique los datos y reintente la operación')
      })
    
    setOpenStepperModal(false);
  }

  return (
    <Root>
      {
        (!sections || !pages) ? <h1>CARGANDO...</h1> :
        <React.Fragment>
            <Title>
              <TitleSection title={'TURNOS'} background={sections[6].img} />
            </Title>
            {
              (!confirmedTurn) ? 
                <Container>
                  <FormContainer>
                    <Selector IconLeft={IconDireccion} Name={'subsidiary'} label={selectSubsidiary === null ? 'Seleccione sucursal' : selectSubsidiary.text} IconRight={FlechaAdelante} handleAction={handleSucursalOpen} enabled={true}></Selector>
                    <Selector IconLeft={IconPractice} Name={'practice'} label={labelPractices === '' ? 'Seleccione práctica' : labelPractices} IconRight={FlechaAdelante} values={selectPractice} handleAction={handlePracticeOpen} enabled={selectSubsidiary != null} ></Selector>
                    <Selector IconLeft={IconSchedule} Name={'schedule'} label={labelSchedule === '' ? 'Seleccione fecha y hora' : labelSchedule} IconRight={FlechaAdelante} handleAction={handleScheduleOpen} enabled={selectPractice != null}></Selector>
                    <FormButton onClick={handleStepperOpen} enabled={selectSchedule != null}>CONFIRMAR TURNO</FormButton>
                  </FormContainer>
                </Container>
                :
                <Container>
                  <ConfirmedTurnTittleContainer>
                    <ConfirmedTurnTittle>Su turno ha sido tomado exitosamente.</ConfirmedTurnTittle>
                    {
                      (confirmedTurnWithEmail)?
                      <Text>Recibirá un mail confirmatorio para recordar la fecha y el horario de su turno.</Text> 
                      : 
                      <Text></Text>
                    }
                    <SecButton target="_self" rel="noopener noreferrer" href="/">VOLVER AL INICIO</SecButton>
                  </ConfirmedTurnTittleContainer>
                </Container>
            }
            <ModalComponent open={openSuc} handleClose={handleClose} >
              <ModalHeader handleClose={handleClose}></ModalHeader>
              <SucursalesSelector handleAction={selectedSubsidiary} selected={selectSubsidiary !== null ? selectSubsidiary.id : ""}></SucursalesSelector>
            </ModalComponent>
            <ModalComponent open={openPrac && selectSubsidiary != null} handleClose={handleClose} >
              <ModalHeader handleClose={handleClose}></ModalHeader>
              <PracticeSelector idSubsidiary={selectSubsidiary} handleAction={selectedPractice} selected ={selectPractice != null ? selectPractice:[]}></PracticeSelector>
            </ModalComponent>
            <ModalComponent open={openSche && selectPractice != null } handleClose={handleClose} >
              <ModalHeader handleClose={handleClose}></ModalHeader>
              <ScheduleSelector practices={selectPractice} idSubsidiary={selectSubsidiary} handleAction={selectedSchedule} selected={selectSchedule}></ScheduleSelector>
            </ModalComponent>
            <ModalComponent open={openStepperModal} handleClose={handleCloseStepperModal} >
              <ModalHeader handleClose={handleCloseStepperModal}></ModalHeader>
              <StepperPatient handleAction={confirmTurn} practices={selectPractice}></StepperPatient>
            </ModalComponent>
          </React.Fragment>
      }
    </Root>
  )
}

function mapState(state) {
  const { error, isFetching, sections } = state.section;
  const { turnError, turnIsFetching, turns } = state.turn;
  const { pages } = state.page;
  return { error, isFetching, sections, pages, turns, turnError, turnIsFetching };
}

const actionCreators = {
  getSections: sectionActions.getSections,
  getTurns: turnActions.getTurns,
  getPages: pageActions.getPages,
  alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(TurnosPage);
