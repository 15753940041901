import React from 'react';
import { Router, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { history } from './redux/history';
import { alertActions } from './redux/actions/alert.actions';
import PrivateRoute from './components/PrivateRoute';
import PrivateRouteLogin from './components/PrivateRouteLogin';
import DashBoard from './components/dashboard/Dashboard';
import LoginPage from './components/LoginPage';
import { makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
/* import Chatbot from './components/Chatbot'; */
import { nameAccess } from './components/nameAccessConstants'


const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5),
  },
  bot: {
    bottom: 0,
    right : 0,
    position: 'fixed',
    width: '350px',
}
}));

function App(props) {

  const classes = useStyles();

  history.listen((location, action) => {
    // clear alert on location change
    props.clearAlerts();
  });

  


  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    props.clearAlerts();
  };

  return (
    <div className="App">       
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={props.open}
            autoHideDuration={6000}
            onClose={handleClose}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{props.message}</span>}
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                className={classes.close}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>,
            ]}
          />

        <Router history={history}>
          <PrivateRoute exact path="/admin" name_access={nameAccess.DASHBOARD} component={DashBoard} />
          <PrivateRoute path="/admin/banner" name_access={nameAccess.DASHBOARD} component={DashBoard} />
          <PrivateRoute path="/admin/publications" name_access={nameAccess.DASHBOARD} component={DashBoard} />
          <PrivateRoute path="/admin/slider" name_access={nameAccess.DASHBOARD} component={DashBoard} />
          <PrivateRoute path="/admin/professional" name_access={nameAccess.DASHBOARD} component={DashBoard} />
          <PrivateRoute path="/admin/indication" name_access={nameAccess.DASHBOARD} component={DashBoard} />
          <PrivateRoute path="/admin/coverage" name_access={nameAccess.DASHBOARD} component={DashBoard} />
          <PrivateRoute path="/admin/medicalservice" name_access={nameAccess.DASHBOARD} component={DashBoard} />
          <PrivateRoute path="/admin/recommendation" name_access={nameAccess.DASHBOARD} component={DashBoard} />
          <PrivateRoute path="/admin/qualitypolicy" name_access={nameAccess.DASHBOARD} component={DashBoard} />
          <PrivateRoute path="/admin/page" name_access={nameAccess.DASHBOARD} component={DashBoard} />
          <PrivateRoute path="/admin/section" name_access={nameAccess.DASHBOARD} component={DashBoard} />
          <PrivateRoute path="/admin/somenumbers" name_access={nameAccess.DASHBOARD} component={DashBoard} />
          <PrivateRoute path="/admin/contactform" name_access={nameAccess.DASHBOARD} component={DashBoard} />
          <PrivateRoute path="/admin/config" name_access={nameAccess.DASHBOARD} component={DashBoard} />
          <PrivateRoute path="/admin/register" name_access={nameAccess.ADMIN} component={DashBoard} />
          {/* <Route path="/admin/register" component={RegisterPage} /> */}

          <PrivateRouteLogin exact path="/admin/login" component={LoginPage} />
          {/* <Redirect from="/admin/*" to="/admin" /> */}
        </Router>
       {/*  <div className = {classes.bot}>
          <Chatbot />
        </div>  */}      
    </div>
  );
}

function mapState(state) {
  const { message, open } = state.alert;
  return { message, open };
}

const actionCreators = {
  clearAlerts: alertActions.clear
};

export default connect(mapState, actionCreators)(App);
