import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import CardService from './CardService';
import { devices } from '../config'
import useMediaQuery from '@material-ui/core/useMediaQuery';


const Root = styled(Grid)`
    flex: auto;
    height: 100%;
    justify-content: center;
    @media (max-width: ${devices.mobileM}px) {
        position: relative;
        justify-content: center;
        align-items: center;
        top: -7rem;
    }
`

const SlideContainer = styled(Grid)`
    flex: auto;
    height: 100%;
    position: relative;
    top: -10vh;
    align-items: center;
    z-index: 50;
    justify-content: center;
    @media (max-width: ${devices.mobileM}px) {
        top: 0;
        flex-wrap: wrap;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
`

function Services( { services } ) {
    const matches = useMediaQuery(`(max-width:${devices.mobileM}px)`);

    return(
          <Root container>
              <SlideContainer container item xs={9}>
                <CardService 
                    background= {matches ? 'linear-gradient(0deg, #17AFCE 0%, #3DCCE9 100%)' : 'linear-gradient(180deg, #17AFCE 0%, #3DCCE9 100%)'} 
                    borderRadius= {matches ? '10px 10px 0 0' : '10px 0 0 10px'} 
                    to= {services[0].link}
                    icon= {services[0].icon}
                    title= {services[0].title}
                    text= {services[0].text}
                 />
                <CardService 
                    background= {matches ? 'linear-gradient(0deg, #3DCCE9 0%, #53E2FF 100%)' : 'linear-gradient(180deg, #3DCCE9 0%, #53E2FF 100%)'}
                    borderRadius='0'
                    to= {services[1].link}
                    icon= {services[1].icon}
                    title= {services[1].title}
                    text= {services[1].text}
                />
                <CardService 
                    background={matches ? 'linear-gradient(0deg, #53E2FF 0%, #8AFFFF 100%)' : 'linear-gradient(180deg, #53E2FF 0%, #8AFFFF 100%)'}
                    borderRadius={matches ? '0 0 10px 10px' : '0 10px 10px 0'}
                    to= {services[2].link}
                    icon= {services[2].icon}
                    title= {services[2].title}
                    text= {services[2].text}
                    link= {services[2].link}
                />
              </SlideContainer>
          </Root>
    )
}

export default Services