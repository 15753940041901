import React from 'react'
import styled from 'styled-components'
import TitleSection from './TitlePage'
import TwoColumnSection from './TwoColumnSection'
import ObjetivesSection from './ObjetivesSections'

import MedicalServicesSection from './MedicalServicesSection'
import SliderAparatologia from './Sliders/SliderAparatologia'

import ProfessionalSection from './ProfessionalsSection'
import { nuestra_historia_data, politica_calidad_data, titleProfesionals, descriptionProfesionals } from '../dataEntry'
import { devices } from '../config'
import { connect } from 'react-redux';
import { alertActions } from '../redux/actions/alert.actions';
import { sectionActions } from '../redux/actions/section.actions';
import { pageActions } from '../redux/actions/page.actions';


const Section = styled.section`
  /* height: calc(100vh - 182px); */
  height: 450px;
  width: 100%;
  display: flex;
  flex: auto;
  align-items: stretch;
  justify-content: center;
  margin-top: 15vh;
  margin-bottom: 15vh;
  @media (max-width: ${devices.tablet}px) {
    height: 300px;
  }
  @media (max-width: ${devices.mobileM}px) {
    height: 400px;
    /* margin-top: 5vh;
    margin-bottom: 5vh; */
    margin-top: 30px;
    margin-bottom: 30px;
  }
`
const SectionObjetives = styled(Section)`
  @media (max-width: ${devices.mobileM}px) {
    height: 190vh;
  }
`

const MediumSection = styled(Section)`
  /* height: 70vh; */
  height: 450px;
  @media (max-width: ${devices.tablet}px) {
    /* height: 60vh; */
    height: 350px;
  }
  @media (max-width: ${devices.mobileM}px) {
    /* height: 60vh; */
    height: 360px;
  }
`
const HighSection = styled(Section)`
  /* height: 125vh; */
  height: 780px;
  @media (max-width: ${devices.tablet}px) {
    /* height: 90vh; */
    height: 450px;
  }
  @media (max-width: ${devices.mobileM}px) {
    /* height: 130vh; */
    height: 700px;
  }
  @media (min-width: ${devices.laptopL}px) {
    /* height: 130vh; */
    height: 1200px;
  }
`

const VeryHighSection = styled(Section)`
  /* height: 160vh; */
  /* height: 980px; */
  height: auto;
  @media (max-width: ${devices.tablet}px) {
    /* height: 140vh; */
    /* height: 900px; */
    height: auto;
  }
  @media (max-width: ${devices.mobileM}px) {
    /* height: 350vh; */
    /* height: 1800px; */
    height: auto;
  }
`

const Title = styled.section`
  height: 40vh;
  width: 100%;
  display: flex;
  flex: auto;
  align-items: stretch;
  justify-content: center;
`
function InstitucionalPage({ match, error, isFetching, sections, getSections, alertMessage, pages, getPages }) {
  
  const nuestra_historia_ref = React.useRef()
  const politicas_de_calidad_ref = React.useRef()
  const servicios_medicos_ref = React.useRef()
  const instalaciones_ref = React.useRef()
  const profesionales_ref = React.useRef()


  React.useEffect(() => {
    if(!sections){
      getSections()
    }
    if(!pages){
      getPages()
    }
      switch (match.params.section) {
        case 'nuestra_historia':
            window.scrollTo({top: nuestra_historia_ref.current.offsetTop, left: 0, behavior: 'smooth'})
          break;
        case 'politica_de_calidad':
            window.scrollTo({top: politicas_de_calidad_ref.current.offsetTop, left: 0, behavior: 'smooth'})
            break;
        case 'servicios_medicos':
            window.scrollTo({top: servicios_medicos_ref.current.offsetTop, left: 0, behavior: 'smooth'})
          break;
        case 'instalaciones':
            window.scrollTo({top: instalaciones_ref.current.offsetTop, left: 0, behavior: 'smooth'})
          break;
        case 'profesionales':
            window.scrollTo({top: profesionales_ref.current.offsetTop, left: 0, behavior: 'smooth'})
          break;
        default:
            window.scrollTo(0, 0)
          break;
      }
  }, [ match.params.section, sections, getSections, getPages, pages ])

  

    return(
          <React.Fragment>
            <Title ref={nuestra_historia_ref}>
              {
                (!sections) ? <h1>CARGANDO...</h1> : (!pages) ? <h1>CARGANDO...</h1> :
                <TitleSection title={pages[1].title} subtitle={sections[8].subtitle} background={sections[8].img}/>
              }
            </Title>
            <Section >
              <TwoColumnSection title={nuestra_historia_data.title} text={nuestra_historia_data.text} image={nuestra_historia_data.image} />
            </Section>
            <Title ref={politicas_de_calidad_ref}>
              {
                (!sections) ? <h1>CARGANDO...</h1> :
                <TitleSection subtitle={sections[9].subtitle} background={sections[9].img}/>
              }
            </Title>
            <Section style={{marginBottom: '2.5vh'}}>
              <TwoColumnSection title={politica_calidad_data.title} text={politica_calidad_data.text} image={politica_calidad_data.image} />
            </Section>
            <SectionObjetives style={{marginTop: '2.5vh'}}>
              <ObjetivesSection  />
            </SectionObjetives>
            <Title ref={servicios_medicos_ref}>
              {
                (!sections) ? <h1>CARGANDO...</h1> :
                <TitleSection subtitle={sections[11].subtitle} background={sections[11].img}/>
              }
            </Title>
            <HighSection>
              <MedicalServicesSection title={'Listado de estudios'}  link='/asistenciaalpaciente/indicaciones_para_estudios' />
            </HighSection>
            <Title ref={instalaciones_ref}>
              {
                (!sections) ? <h1>CARGANDO...</h1> :
                <TitleSection subtitle={sections[12].subtitle} background={sections[12].img}/>
              }
            </Title>
            <MediumSection style={{marginBottom: '7.5vh'}}>
              <SliderAparatologia interval={5000} />
            </MediumSection>
            <MediumSection style={{marginTop: '7.5vh'}}>
              <SliderAparatologia imageLeft={true}  interval={5000} />
            </MediumSection>
            <Title ref={profesionales_ref}>
              {
                (!sections) ? <h1>CARGANDO...</h1> :
                <TitleSection subtitle={sections[13].subtitle} background={sections[13].img}/>
              }
            </Title>
            <VeryHighSection >
              <ProfessionalSection title={titleProfesionals} description={descriptionProfesionals} />
            </VeryHighSection>
          </React.Fragment>
    )
}

function mapState(state) {
  const { error, isFetching, sections } = state.section;
  const { pages } = state.page;
  return { error, isFetching, sections, pages };
}

const actionCreators = {
  getSections: sectionActions.getSections,
  getPages: pageActions.getPages,
  alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(InstitucionalPage);

/* export default InstitucionalPage */