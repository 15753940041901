import React from 'react'
import styled from 'styled-components'
import TitleSection from './TitlePage'
import IndicacionesEstudiosSection from './IndicacionesEstudiosSection'
import CoberturasSection from './CoberturasSection'
import RecomendacionesSection from './RecomendacionesSection'
import PreguntasFrecuentesSection from './PreguntasFrecuentesSection'
import { coberturasData, recomendaciones } from '../dataEntry'
import { devices } from '../config'
import { connect } from 'react-redux';
import { alertActions } from '../redux/actions/alert.actions';
import { sectionActions } from '../redux/actions/section.actions';
import { pageActions } from '../redux/actions/page.actions';

const Section = styled.section`
  /* height: 80vh; */
  height: 520px;
  width: 100%;
  display: flex;
  flex: auto;
  align-items: stretch;
  justify-content: center;
  margin-top: 10vh;
  margin-bottom: 10vh;
`
const SectionIndications = styled(Section)`
  /* height: 120vh; */
  height: 750px;
  @media (max-width: ${devices.tablet}px) {
    /* height: 130vh; */
    height: 780px;
  }
  @media (max-width: ${devices.mobileM}px) {
    /* height: 160vh; */
    height: 900px;
  }
`

const SectionCoberturas = styled(Section)`
  /* height: 145vh; */
  height: 1100px;
  @media (max-width: ${devices.tablet}px) {
    /* height: 125vh; */
    height: 600px;
  }
  @media (max-width: ${devices.mobileM}px) {
    /* height: 270vh; */
    height: 1700px;
  }
`

const SectionRecomendaciones = styled(Section)`
  /* height: 185vh; */
  height: 1150px;
  @media (min-width: ${devices.laptopL}px) {
    /* height: 150vh; */
    height: 1300px;
  }
  @media (max-width: ${devices.tablet}px) {
    /* height: 150vh; */
    height: 700px;
  }
  @media (max-width: ${devices.mobileM}px) {
    /* height: 200vh; */
    height: 1250px;
  }
`

const SectionPreguntasFrecuentes = styled(Section)`
  height: auto;
  @media (max-width: ${devices.tablet}px) {
    height: auto;
  }
`

const Title = styled.section`
  height: 40vh;
  width: 100%;
  display: flex;
  flex: auto;
  align-items: stretch;
  justify-content: center;
`




function AsistenciaPacientePage({ match, error, isFetching, sections, getSections, alertMessage, pages, getPages }) {
  const indicaciones_para_estudios_ref = React.useRef()
  const coberturas_ref = React.useRef()
  const recomendaciones_ref = React.useRef()
  const preguntas_frecuentes_ref = React.useRef()

  React.useEffect(() => {
    switch (match.params.section) {
      case 'indicaciones_para_estudios':
          window.scrollTo({top: indicaciones_para_estudios_ref.current.offsetTop, left: 0, behavior: 'smooth'})
        break;
      case 'coberturas':
          window.scrollTo({top: coberturas_ref.current.offsetTop, left: 0, behavior: 'smooth'})
          break;
      case 'recomendaciones':
          window.scrollTo({top: recomendaciones_ref.current.offsetTop, left: 0, behavior: 'smooth'})
        break;
      case 'preguntas_frecuentes':
          window.scrollTo({top: preguntas_frecuentes_ref.current.offsetTop, left: 0, behavior: 'smooth'})
        break;
      default:
          window.scrollTo(0, 0)
        break;
    }
    if(!sections){
      getSections()
    }
    if(!pages){
      getPages()
    }
  }, [ match.params.section, sections, getSections, getPages, pages ])

    return(
          <React.Fragment>
            <Title ref={indicaciones_para_estudios_ref}>
              {
                (!sections) ? <h1>CARGANDO...</h1> : (!pages) ? <h1>CARGANDO...</h1> :
                <TitleSection title={pages[2].title} subtitle={sections[14].subtitle} background={sections[14].img}/>
              }
            </Title>
            <SectionIndications>
              <IndicacionesEstudiosSection title='Indicaciones para estudios' text='Para visualizar los detalles de preparación de un estudio específico clickear sobre el mismo.' />
            </SectionIndications>
            <Title ref={coberturas_ref}>
              {
                (!sections) ? <h1>CARGANDO...</h1> :
                <TitleSection  subtitle={sections[15].subtitle} background={sections[15].img}/>
              }
            </Title>
            <SectionCoberturas>
              <CoberturasSection title={coberturasData.title} text={coberturasData.text} notice={coberturasData.notice} />
            </SectionCoberturas>
            <Title ref={recomendaciones_ref}>
              {
                (!sections) ? <h1>CARGANDO...</h1> :
                <TitleSection subtitle={sections[16].subtitle} background={sections[16].img}/>
              }
            </Title>
            <SectionRecomendaciones>
              <RecomendacionesSection title={recomendaciones.title} text={recomendaciones.text} />
            </SectionRecomendaciones>
            <Title ref={preguntas_frecuentes_ref}>
              {
                (!sections) ? <h1>CARGANDO...</h1> :
                <TitleSection subtitle={sections[17].subtitle} background={sections[17].img}/>
              }
            </Title>
            <SectionPreguntasFrecuentes>
              <PreguntasFrecuentesSection />
            </SectionPreguntasFrecuentes>
          </React.Fragment>
    )
}

function mapState(state) {
  const { error, isFetching, sections } = state.section;
  const { pages } = state.page;
  return { error, isFetching, sections, pages };
}

const actionCreators = {
  getSections: sectionActions.getSections,
  getPages: pageActions.getPages,
  alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(AsistenciaPacientePage);

/* export default AsistenciaPacientePage */