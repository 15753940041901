import { professionalConstants } from '../constants';
import { alertActions } from './alert.actions';
import axios from 'axios';
import  { authHeader } from '../auth-header';

export const professionalActions = {
    createProfessional,
    updateProfessional,
    getProfessionals,
    deleteProfessional
};

function createProfessional(professional) {
    return dispatch => {
        axios
        .post( '/api/professionals' , professional)
        .then(response => {
            dispatch(alertActions.success('Profesional creado'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function updateProfessional(id, professional) {
    return dispatch => {
        axios
        .put( '/api/professionals/' + id, professional)
        .then(response => {
            dispatch(alertActions.success('Profesional actualizado'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}


function getProfessionals() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
      }
    return dispatch => {
        dispatch({ type: professionalConstants.FETCHING });
        axios.get('/api/professionals',config)
        .then(professionalsData => {
            const professionals = professionalsData.data.professionals
            dispatch({ type: professionalConstants.SUCCESS, professionals });
        })
        .catch(err => {
            dispatch({ type: professionalConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function deleteProfessional(id) {
    return dispatch => {
        axios
        .delete( '/api/professionals/' + id)
        .then(response => {
            dispatch(alertActions.success('Profesional eliminado'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}