import { qualityPolicyConstants } from '../constants';
import { alertActions } from './alert.actions';
import axios from 'axios';
import  { authHeader } from '../auth-header';

export const qualityPolicyActions = {
    createQualityPolicy,
    updateQualityPolicy,
    getQualityPolicies,
    deleteQualityPolicy
};

function createQualityPolicy(qualityPolicy) {
    return dispatch => {
        axios
        .post( '/api/qualitypolicies' , qualityPolicy)
        .then(response => {
            dispatch(alertActions.success('Política de calidad creada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function updateQualityPolicy(id, qualityPolicy) {
    return dispatch => {
        axios
        .put( '/api/qualitypolicies/' + id, qualityPolicy)
        .then(response => {
            dispatch(alertActions.success('Política de calidad actualizada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}


function getQualityPolicies() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
      }
    return dispatch => {
        dispatch({ type: qualityPolicyConstants.FETCHING });
        axios.get('/api/qualitypolicies',config)
        .then(qualityPoliciesData => {
            const qualityPolicies = qualityPoliciesData.data.qualityPolicies
            dispatch({ type: qualityPolicyConstants.SUCCESS, qualityPolicies });
        })
        .catch(err => {
            dispatch({ type: qualityPolicyConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function deleteQualityPolicy(id) {
    return dispatch => {
        axios
        .delete( '/api/qualitypolicies/' + id)
        .then(response => {
            dispatch(alertActions.success('Política de calidad eliminada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}