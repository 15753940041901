import { turnConstants } from '../constants';

let turns = JSON.parse(localStorage.getItem('turns'));
const initialState = turns ? 
  { 
      turnError: false,
      turnIsFetching: false,
      turns: turns
  } : 
  { 
    turnturnError: false,
    turnturnIsFetching: true,
  };

export function turn(state = initialState, action) {
  switch (action.type) {
    case turnConstants.FETCHING:
      return { 
            ...state,
            turnIsFetching: true
        };
    case turnConstants.SUBSIDIARIES:
      return { 
            ...state,
            turnIsFetching: false,
            subsidiaries: action.subsidiaries,
        };
    case turnConstants.SELECTEDSUBSIDIARY:
      return { 
            ...state,
            turnIsFetching: false,
            subsidiary: action.subsidiary,
        };
    case turnConstants.SUCCESS:
      return { 
            ...state,
            turnIsFetching: false,
            turns: action.turns
        };
    case turnConstants.ERROR:
      return { 
            ...state,
            turnError: true
        };
    default:
      return state
  }
}