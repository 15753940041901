import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Typography from '@material-ui/core/Typography';
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import { apiClinica, config, devices } from "../config";
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import PasswordHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Popper from '@material-ui/core/Popper';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import TurnProvious from './TurnProvious';


var CryptoJS = require("crypto-js");

const Root = styled.div`
    width: 100%;
    padding: 1rem;
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const StyledStepper = styled(Stepper)`
    & .MuiStepLabel-label.MuiStepLabel-active {
        color: #000;
        font-size: 1rem;
        font-weight: bold;
        font-family: lato;
    }
    & .MuiStepLabel-label.MuiStepLabel-completed{
        color: #000;
        font-size: 1rem;
        font-weight: bold;
        font-family: lato;
    }
    & .MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-active{
        color: #3dcce9;
    }
    & .MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-completed {
        color: #3dcce9;
    }
    & .MuiStepIcon-text {
        fill: #fff;
        font-size: 0.75rem;
        font-family: lato;
    }
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
        &.MuiStepper-root{
            padding: 3rem;
        }
        & .MuiStepLabel-label.MuiStepLabel-active {
            font-size: 2.5rem;
        }
        & .MuiStepIcon-text {
            font-size: 2.5rem;
        }
        & .MuiSvgIcon-root.MuiStepIcon-root.MuiStepIcon-active{
            font-size: 2.5rem;
        }
    }
`

const baseButton = styled.button`
    height: 2rem;
    margin: 0.25rem;
    border-radius: 24px;
    font: Bold 0.75rem Lato;
    letter-spacing: 1.35px;
    text-decoration: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    &:hover {
        background: linear-gradient(270deg, #d5ee51 0%, #3dcce9 100%);
    }
    &:focus {
        outline: none;
    }
    @media (max-width: ${devices.mobileM}px) {
        width: 40%;
        height: 5rem;
        font-size: 3rem;
        margin-top: 4rem;
        margin-bottom: 4rem;
  }
`

const PrimButton = styled(baseButton)`
  width: 11rem;
  border: none;
  background: linear-gradient(96deg, #3dcce9 0%, #87e8fc 100%);
  color: white;
`
const PrimButtonTurn = styled(PrimButton)`
@media (max-width: ${devices.mobileM}px) {
    width: 80%;
    margin: 2rem 0;
}
@media (max-width: ${devices.mobile}px) {
    width: 100%;
    margin: 2rem 0;
}
`

const SecButton = styled(baseButton)`
  top: 2rem;
  left: 60rem;
  width: 9rem;
  height: 2rem;
  border: 1px solid #3dcce9;
  opacity: 1;
  color: #3dcce9;
  background: white;
  &:hover {
    color: white;
    border: 0;
  }
  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    }
`

const TextContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    width: 100%;
    height: 60%;
    box-sizing: border-box;
`

const StyledAutocomplete = styled(Autocomplete)`
        width: 45%;
        @media (max-width: ${devices.mobileM}px) {
            width: 95%;
            & .MuiFormLabel-root.Mui-focused{
            color: #999;
            font-size: 14px;
            }
            & .MuiSvgIcon-root {
                font-size: 2.75rem;
            }
        }
`
const StyledPopper = styled(Popper)`
    @media (max-width: ${devices.mobileM}px) {
        div {
            font-size: 14px;
        }   
    }
`
const StyledTextField = styled(TextField)`  
    width: 45%; 
    & label.MuiFormLabel-root {
        font-size: 1rem;
        line-height: 1.5rem;
        font-family: Lato;
        color: #999;
    }
    & .MuiInputLabel-asterisk {
        display: none;
    }
    & input.MuiInputBase-input {
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-family: Lato;
        color: #000;
        opacity: 0.92;
    }
    & .MuiInput-underline:after{
        border-bottom: 2px solid #3DCCE9;
    }
    @media (max-width: ${devices.mobileM}px) {
        width: 95%;
        & label.MuiFormLabel-root {
            font-size: 2.75rem;
            line-height: 3.5rem;
        }
        & input.MuiInputBase-input {
            font-size: 3rem;
            line-height: 3.75rem;
        }
    }
`;

const StyledFormControl = styled(FormControl)`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 45%;
    box-sizing: border-box;
    & input.MuiInputBase-input {
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-family: Lato;
        color: #000;
        opacity: 0.92;
    }
    & label.MuiFormLabel-root {
        font-size: 1rem;
        line-height: 1.5rem;
        font-family: Lato;
        color: #999;
    }
    & .MuiInput-underline:after{
        border-bottom: 2px solid #3DCCE9;
    }
    @media (max-width: ${devices.mobileM}px) {
        width: 95%;
        & label.MuiFormLabel-root {
            font-size: 2.75rem;
            line-height: 3.5rem;
        }
        & input.MuiInputBase-input {
            font-size: 3rem;
            line-height: 3.75rem;
        }
        & .MuiSvgIcon-root {
            font-size: 2.75rem;
        }
    }
`;

const StepContentContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 90%;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
`

const StepContentFooter = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    height: 10%;
    margin-top: 2rem;
`

const ForgotPasswordText = styled.a`
    font-size: 0.75rem;
    padding-top: 0.75rem;
    font-family: Lato;
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1.5rem;
        padding-top: 1.5rem;
    }
`
const NoPassContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
    box-sizing: border-box;
    border: 1px solid #3dcce9;
    padding: 5px;
    border-radius: 5px;
    margin-top: 10px;
    background: #E7E7E7;
    @media (max-width: ${devices.mobileM}px) {
        font-size: 2.5rem;
    }
`

const useStyles = makeStyles((theme) => ({
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const useStylesSpinner = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
}));

export default function StepperPatient({ handleAction , practices}) {
    const classes = useStyles();
    const classesSpinner = useStylesSpinner();
    const [passwordInput, setPasswordInput] = React.useState({
        password: '',
        showPassword: false,
        errors: false
    });
    const [activeStep, setActiveStep] = React.useState(0);
    const [fetching, setFetching] = React.useState(false);
    const [stepReady, setStepReady] = React.useState({
        first: true,
        second: false,
        third: false
    });
    const [turnData, setTurnData] = React.useState(null);
    const PopperStyled = function (props) {
        return (<StyledPopper {...props} placement='bottom-start' />)
    };

    const [patientData, setPatientData] = React.useState({
        patientId: '',
        patientDNI: '',
        patientName: '',
        patientSurname: '',
        patientAdress: '',
        patientBirthDate: '',
        patientEmail: '',
        patientTelephone: '',
        patientIdOsFk: '',
        patientHealthInsurance: '',
        patientNumberHealthInsurance: '',
        passwordPACS:''
    });

    const [state, setState] = React.useState({
        errors: {
            patientDNI: false,
            patientName: false,
            patientSurname: false,
            patientAdress: false,
            patientBirthDate: false,
            patientEmail: false,
            patientTelephone: false,
            patientHealthInsurance: false,
            patientNumberHealthInsurance: false,
        }
    });

    const [arrayHealthInsurance, setArrayHealthInsurance] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');

    const getSteps = () => {
        return ['Ingrese su DNI', 'Ingrese su contraseña', 'Complete sus datos'];
    }

    const handleValue = () => {
        const result = arrayHealthInsurance.find(element => element.id === patientData.patientIdOsFk);
        return result === undefined ? null : result;
    }

    const handleVerify = (recaptchaToken) => {
    }
    
    const getStepContent = (step) => {
        switch (step) {
            case 0:
                return <TextContainer>
                    {(turnData) ?
                        <TurnProvious turnData={turnData}></TurnProvious>
                    :
                    <>
                        <StyledTextField id="patient_DNI" name="patientDNI" type="text" label="DNI" value={patientData.patientDNI} error={state.errors.patientDNI} helperText={state.errors.patientDNI && patientData.patientDNI !== '' ? 'Ingrese solamente números' : ''} onChange={handleChange} required />
                        <GoogleReCaptchaProvider reCaptchaKey={config.reCAPTCHAKey}>
                            <GoogleReCaptcha onVerify={handleVerify} />
                        </GoogleReCaptchaProvider>
                    </>
                    }       
                </TextContainer>
            case 1:
                return <StyledFormControl>
                    <InputLabel htmlFor="patient_password">Clave</InputLabel>
                    <Input
                        id="patient_password"
                        name="patientPassword"
                        type={passwordInput.showPassword ? 'text' : 'password'}
                        value={passwordInput.password}
                        error={passwordInput.errors}
                        onChange={handleChangePassword('password')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                >
                                    {passwordInput.showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    {passwordInput.errors && passwordInput.password !== '' ? <PasswordHelperText id="password_error">Contraseña inválida</PasswordHelperText> : ''}
                    <ForgotPasswordText href="https://estudios.centrodm.com.ar/Vistas/resetp.php" target="_blank">¿Olvidó su contraseña?</ForgotPasswordText>
                    <NoPassContainer>
                        <a>Si no conoce o no recuerda su clave puede</a>
                        <PrimButtonTurn variant="contained" color="primary" onClick={handleFastConfirm} className={classes.button}>Crear Turno Simplificado</PrimButtonTurn>
                        <a>El turno confirmado no podra ser cancelado y no se le enviara detalle del mismo por email</a>
                    </NoPassContainer>
                </StyledFormControl>
            case 2:
                return <TextContainer>
                    <StyledTextField id="patient_name" name="patientName" label="Nombre" value={patientData.patientName} error={state.errors.patientName} onChange={handleChange} required />
                    <StyledTextField id="patient_surname" name="patientSurname" label="Apellido" value={patientData.patientSurname} error={state.errors.patientSurname} onChange={handleChange} required />
                    <StyledTextField id="patient_adress" name="patientAdress" label="Dirección" value={patientData.patientAdress} error={state.errors.patientAdress} onChange={handleChange} required />
                    <StyledTextField id="patient_birthdate" name="patientBirthDate" label="Fecha de nacimiento" type="date" InputLabelProps={{ shrink: true }} value={patientData.patientBirthDate} error={state.errors.patientBirthDate} onChange={handleChange} required />
                    <StyledTextField id="patient_email" name="patientEmail" label="Correo electrónico" value={patientData.patientEmail} error={state.errors.patientEmail} helperText={state.errors.patientEmail && patientData.patientEmail !== '' ? 'Correo electrónico inválido' : ''} onChange={handleChange} required />
                    <StyledTextField id="patient_telephone" name="patientTelephone" type="text" label="Teléfono" value={patientData.patientTelephone} error={state.errors.patientTelephone} helperText={state.errors.patientTelephone && patientData.patientTelephone !== '' ? 'Ingrese solamente números' : ''} onChange={handleChange} required />
                    <StyledAutocomplete
                        value={handleValue()}
                        PopperComponent={PopperStyled}
                        onChange={(event, newValue) => {
                            setPatientData({
                                ...patientData,
                                patientIdOsFk: newValue ? newValue.id : '',
                                patientHealthInsurance: newValue ? newValue.os : '',
                            });
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        id="patient_healthinsurance"
                        options={arrayHealthInsurance}
                        getOptionLabel={(option) => option.os}
                        renderInput={(params) => <StyledTextField {...params} label="Obra social" error={state.errors.patientHealthInsurance} />}
                    />
                </TextContainer>;
            default:
                return 'Unknown step';
        }
    }

    const handleNext = async () => {
        if (!fetching) {
            setFetching(true);
            const { flag, jumpStep, message } = await checkForm();
            if (flag) {
                setFetching(false);
                    if (!jumpStep) {
                        setActiveStep((prevActiveStep) => prevActiveStep + 1);
                    }
                    else {
                        setActiveStep((prevActiveStep) => prevActiveStep + 2);
                    }
                }
                else {
                    setFetching(false);
                    {message && alert(message)};
                }
        }
    };

    const handleChangePassword = (prop) => (e) => {
        setPasswordInput({ ...passwordInput, [prop]: e.target.value, errors: false });
    };

    const handleClickShowPassword = () => {
        setPasswordInput({ ...passwordInput, showPassword: !passwordInput.showPassword });
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    }

    const dniIsValid = (dni) => {
        return (dni !== '') && (/^[0-9]*$/.test(dni))
    }

    const emailIsValid = (email) => {
        return (/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/).test(email)
    }

    const telephoneIsValid = (telephone) => {
        return /^[0-9]*$/.test(telephone) && telephone
    }

    const checkPassword = async (password) => {

        let data = {
            "idPaciente": patientData.patientId,
            "dni": patientData.patientDNI,
            "password": password
        };

        // Encrypt
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), config.cryptoKey).toString();
        const response = await axios.post("/api/clinica/checkpassword", { data: ciphertext })
        if (response.data.status === 'SUCCESS' || patientData.passwordPACS === password) {
            return true;
        }
        else if (response.data.status === 'ERROR') {
            return false;
        }
    }
    
    const checkPreviewsTurn = async (dni) => {
        const turn = [];
        await axios
        .get("/api/clinica/turnConsultant/" + dni )
        .then(response => {
            if(response.data){
                const responseData = response.data.cipherTurnData;
                var bytes = CryptoJS.AES.decrypt(responseData, config.cryptoKey);
                var decryptedTurnData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                if (decryptedTurnData){
                    decryptedTurnData.forEach(turno => {
                        practices.forEach(element => {
                            const aux = turno.idsPracticas.find(id => id === element.id);
                            if (aux){
                                turn.push(turno);
                            }
                        });
                    })
                }
            }
        })
        .catch(error => {
            alert('Hubo un error al intentar con sultar turnos previos')
        })
        if (turn.length > 0){
            setTurnData(turn[0]);
            return true;
        }
        return false;
    }

    const getHealthInsurance = () => {
        axios
            .get(apiClinica.baseURL + 'os', { headers: { 'Authorization': 'Bearer ' + apiClinica.token, 'Range': 'items 0-200' } })
            .then(response => {
                if (response.data.length > 0) {
                    if (response.data) {
                        const responseDataOS = response.data;
                        setArrayHealthInsurance(responseDataOS);
                    }
                }
            })
            .catch(error => {
                alert('Hubo un error')
            })
    }

    const checkForm = async () => {
        let flag = true;
        let jumpStep = false;
        let message = 'Faltan completar campos';

        switch (activeStep) {
            case 0:
                if (dniIsValid(patientData.patientDNI)) {
                    // Encrypt
                    var cipherDNI = CryptoJS.AES.encrypt(JSON.stringify(patientData.patientDNI), config.cryptoKey).toString();
                    var dataString = cipherDNI.replace(/\+/g, 'p1L2u3S').replace(/\//g, 's1L2a3S4h').replace(/=/g, 'e1Q2u3A4l');
                    await axios
                        .get("/api/clinica/paciente/" + dataString)
                        .then(async response => {
                            const responseData = response.data.cipherDataPatient;
                            var bytes = CryptoJS.AES.decrypt(responseData, config.cryptoKey);
                            var decryptedDataPatient = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
                            getHealthInsurance();
                            setPatientData(decryptedDataPatient);
                            if (decryptedDataPatient.patientId !== '0') {
                                const checkTurn = await checkPreviewsTurn(patientData.patientDNI)
                                if(!checkTurn){
                                    if (activeStep === 0) {
                                        setStepReady({
                                            ...stepReady,
                                            second: true
                                        })
                                    } else if (activeStep === 1) {
                                        setStepReady({
                                            ...stepReady,
                                            third: true
                                        })
                                    }
                                }
                                else{
                                    flag = false;
                                    message="";
                                }
                            }
                            else {
                                if (activeStep === 0) {
                                    setStepReady({
                                        ...stepReady,
                                        second: true,
                                        third: true
                                    })
                                    jumpStep = true;
                                }
                            }
                        })
                        .catch(error => {
                            alert('Request Error')
                        })
                }
                else {
                    flag = false;
                }

                break;
            case 1:
                const checkPass = await checkPassword(passwordInput.password)
                if (!checkPass) {
                    flag = false;
                    message= null;
                    setPasswordInput({
                        ...passwordInput,
                        errors: true
                    })
                }
                else {
                    flag = true;
                    setPasswordInput({
                        ...passwordInput,
                        errors: false
                    })
                    if (activeStep === 0) {
                        setStepReady({
                            ...stepReady,
                            second: true
                        })
                    } else if (activeStep === 1) {
                        setStepReady({
                            ...stepReady,
                            third: true
                        })
                    }
                }


                break;
            case 2:
                const { errors } = state;
                if (errors.patientName === false && errors.patientSurname === false && errors.patientAdress === false
                    && errors.patientEmail === false && errors.patientTelephone === false && errors.patientHealthInsurance === false
                    && errors.patientBirthDate === false && !checkErrors()
                ) {
                    flag = true;
                }
                else {
                    flag = false;
                }

                break;
            default:
                break;
        }
        return { flag, jumpStep, message };
    }

    const checkErrors = () => {
        let errores = {};
        const tags = ["patientName", "patientSurname", "patientAdress", "patientEmail", "patientTelephone", "patientHealthInsurance", "patientBirthDate"]
        tags.forEach(element => {
            if (patientData[element] === '' || patientData[element] === null) {
                errores = { ...errores, [element]: true };
            }
        });
        setState({
            ...state,
            errors: {
                ...state.errors,
                ...errores
            }
        });
        return Object.keys(errores).length > 0;
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "patientDNI") {
            setState({
                ...state,
                errors: {
                    ...state.errors,
                    patientDNI: !dniIsValid(value)
                }
            })
        } else if (name === "patientEmail") {
            setState({
                ...state,
                errors: {
                    ...state.errors,
                    patientEmail: !emailIsValid(value)
                }
            })
        } else if (name === "patientTelephone") {
            setState({
                ...state,
                errors: {
                    ...state.errors,
                    patientTelephone: !telephoneIsValid(value)
                }
            })
        } else {
            setState({
                ...state,
                errors: {
                    ...state.errors,
                    [name]: !value ? true : false
                }
            })
        }
        setPatientData({
            ...patientData,
            [name]: value
        });
    }

    const handleBack = async () => {
        if (!fetching) {
            if (activeStep === 1) {
                setStepReady({
                    ...stepReady,
                    second: false
                })
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
            } else if (activeStep === 2) {
                if (patientData.patientId === '0') {
                    setStepReady({
                        ...stepReady,
                        second: false,
                        third: false
                    })
                    setActiveStep((prevActiveStep) => prevActiveStep - 2);
                }
                else {
                    setStepReady({
                        ...stepReady,
                        third: false
                    })
                    setActiveStep((prevActiveStep) => prevActiveStep - 1);
                }
            }
        }
    };

    const handleFastConfirm = async () => {
        handleAction(patientData);
    }

    const handleConfirm = async () => {
        const { flag, message } = await checkForm();
        if (flag) {
            handleAction(patientData)
        }
        else {
            alert(message);
        }

    }

    const onStepReady = (stepNumber) => {
        if (stepNumber === 0 && !fetching) {
            return stepReady.first;
        } else if (stepNumber === 1 && !fetching) {
            return stepReady.second;
        } else if (stepNumber === 2 && !fetching) {
            return stepReady.third;
        }
    }

    const steps = getSteps();

    return (
        <Root>
            <StyledStepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })
                }
            </StyledStepper>
            <div>
                {activeStep === steps.length ? (
                    <div>
                        <Typography className={classes.instructions}>
                            All steps completed - you&apos;re finished
                        </Typography>
                    </div>
                ) : (
                        <div style={{ width: "100%", height: "100%" }}>
                            <StepContentContainer>
                                {
                                    onStepReady(activeStep) ? getStepContent(activeStep)
                                        :
                                        <div className={classesSpinner.root}>
                                            <CircularProgress />
                                        </div>
                                }
                            </StepContentContainer>
                            {!turnData && <StepContentFooter>
                                    <SecButton disabled={activeStep === 0} onClick={handleBack} className={classes.button}> ATRÁS </SecButton>
                                    {
                                        activeStep === steps.length - 1 ?
                                            <PrimButton variant="contained" color="primary" onClick={handleConfirm} className={classes.button}>
                                                CONFIRMAR
                                        </PrimButton>
                                            :
                                            <PrimButton variant="contained" color="primary" onClick={handleNext} className={classes.button}>
                                                SIGUIENTE
                                        </PrimButton>
                                    }
                                </StepContentFooter>
                            }
                        </div>
                    )}
            </div>
        </Root>
    );
}