import React from 'react'
import { connect } from 'react-redux';
import { alertActions } from '../../redux/actions/alert.actions';
import { professionalActions } from '../../redux/actions/professional.actions';
import axios from 'axios';
import styled from 'styled-components'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import { Input } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';

const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
`

const ModalContent = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 50%;
    height: 60%;
    align-items: center;
    justify-content: center;
    overflow: auto;
`

const ModalTitle = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 15%;
    color: white;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #3f51b5;
`

const ModalContentSup = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 50px;
    align-self: center;
`

const ModalContentSupLeft = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 65%;
    height: 100%;
`

const ModalContentSupRight = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 35%;
    height: 100%;
`

const Img = styled.img`
    position: relative;
    display: flex;
    width: 300px;
    height: 300px;
    align-self: center;
    margin-bottom: 10px;
`

const Field = styled(TextField)`
    width: 85%;
    margin-bottom: 50px!important;
`
const MediumField = styled(TextField)`
    width: 45%;
`

const OrderKeyContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin-bottom: 25px;
`

const UploadImage = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    margin-bottom: 50px;
`
function ModalCreateProfessional({ createProfessional, alertMessage, user, handleClose, open }) {

    const [ image, setImage ] = React.useState('')
    const [ prof, setProf ] = React.useState({ createdBy: user.id });
    const [ errors, setErrors ] = React.useState({
        order: false,
        firstname: false,
        lastname: false,
        img: false,
    })

    const handleCloseModal = () => {
        setErrors({
            order: false,
            firstname: false,
            lastname: false,
            img: false,
        })
        setImage('')
        setProf({})
        handleClose();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProf({
            ...prof,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
    }

    const onFileChange = (e) => {
        setImage(e.target.files[0])
    }

    const handleFileUpload = (e) => {
        if(image !== '' ){
            if(!prof.img){
                const formData = new FormData()
                formData.append('image', image)
                axios.post("/api/images/upload", formData, {
                }).then(res => {
                    const url_img = '/uploads/'+res.data.filename
                    setProf({
                        ...prof,
                        img: url_img
                    })
                    setErrors({
                        ...errors,
                        img: false
                    })    
                })
                .catch(error => {
                    alertMessage('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                })
            } else {
                // eslint-disable-next-line no-restricted-globals
                if(confirm('¿Desea subir esta nueva imagen y reemplazar la anterior?')){
                    const formData = new FormData()
                    formData.append('image', image)
                    axios.post("/api/images/upload", formData, {
                    }).then(res => {
                        const url_img = '/uploads/'+res.data.filename
                        setProf({
                            ...prof,
                            img: url_img
                        })
                        setErrors({
                            ...errors,
                            img: false
                        })    
                    })
                    .catch(error => {
                        alertMessage('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                    })  
                }
            }
        }else{
            alertMessage("Debe selecionar una imagen...")
        }
    }    

    const handleForm = async (e) => {
        e.preventDefault()
        if(prof.order && prof.firstname && prof.lastname && prof.img && prof.createdBy){
            await createProfessional(prof)
            handleCloseModal()            
        }else{
            setErrors({
                order: !(prof.order),
                firstname: !(prof.firstname),
                lastname: !(prof.lastname),
                img: !(prof.img),
            })
        }
    }


    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <ModalContent>
                    <ModalTitle>
                        CREAR PROFESIONAL
                        <Button variant="contained" onClick={handleForm}>GUARDAR</Button>
                    </ModalTitle>
                    <ModalContentSup>
                        <ModalContentSupLeft>
                            <Field id="firstname" name="firstname" label="Nombres" variant="outlined" size='small' error={errors.firstname} onChange={handleChange} />
                            <Field id="lastname" name="lastname" label="Apellidos" variant="outlined" size='small' error={errors.lastname} onChange={handleChange} />
                            <Field id="description" name="description" label="Descripción" variant="outlined" size='medium' multiline rows="2" onChange={handleChange} />
                            <OrderKeyContainer>
                                <MediumField id="academic_title" name="academic_title" label="Título académico" variant="outlined" size='small' onChange={handleChange} required />
                                <MediumField id="order" name="order" label="Orden" type="number" variant="outlined" size='small' error={errors.order} onChange={handleChange} required />
                            </OrderKeyContainer>
                        </ModalContentSupLeft>
                        <ModalContentSupRight>
                            <Img src={prof.img ? prof.img : "https://colegiolovaina.cl/wp-content/plugins/learnpress/assets/images/no-image.png"} alt="Imagen subida" />
                            <UploadImage>
                                <Input style={{fontSize: '0.75rem', width: '300px', color: errors.img ? 'red' : 'black'}} id="image" name="image" type="file" onChange={onFileChange} error={errors.img} required /><CloudUploadIcon onClick={handleFileUpload} />
                            </UploadImage>
                        </ModalContentSupRight>
                    </ModalContentSup>
                </ModalContent>
            </Fade>
        </ModalStyled>
    )
}

function mapState(state) {
    const { user } = state.authentication;
    return { user };
  }
  
const actionCreators = {
    createProfessional: professionalActions.createProfessional,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(ModalCreateProfessional);