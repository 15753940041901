import React from 'react'
import { connect } from 'react-redux';
import { alertActions } from '../../redux/actions/alert.actions';
import { qualityPolicyActions } from '../../redux/actions/quality_policy.actions';
import styled from 'styled-components'
import MaterialTable from 'material-table';
import { forwardRef } from 'react';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import RefreshIcon from '@material-ui/icons/Refresh';
import ModalCreateQualityPolicy from './ModalCreateQualityPolicy'
import ModalUpdateQualityPolicy from './ModalUpdateQualityPolicy'

const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
    Refresh: forwardRef((props, ref) => <RefreshIcon {...props} ref={ref} />)
  };

const Root = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
`

function QualityPolicyAdmin({ getQualityPolicies, deleteQualityPolicy, isFetching, qualityPolicies }) {

    
    React.useEffect(() => {
        if(!qualityPolicies){
            getQualityPolicies()
        }
    }, [getQualityPolicies, qualityPolicies])

    const tableRef = React.createRef()

    const [open, setOpen] = React.useState(false);

    const [ edit, setEdit ] = React.useState(false)

    const [ editData, setEditData ] = React.useState(null);

    const refresh = () => getQualityPolicies() && tableRef.current.onQueryChange()

    const handleOpen = (event) => {
        event.preventDefault()
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false)
        refresh()
    };

    const handleEdit = (e, rowData) => {
        e.preventDefault()
        setEditData(rowData)
        setEdit(true)
    }

    const handleCloseEdit = () => {
        setEdit(false)
        setEditData(null)
        refresh()
    }

    const handleDelete = (e, rowData) => {
        e.preventDefault()
        // eslint-disable-next-line no-restricted-globals
        if (confirm("¿Desea eliminar la política de calidad "+rowData.id+"?")) {
            deleteQualityPolicy(rowData.id)
        }
        refresh()
    }

    return(
      <Root>
          {
              isFetching ? <h1>CARGANDO...</h1> : 
              <div style={{width: '100%'}}>
                <MaterialTable
                    title="POLÍTICAS DE CALIDAD"
                    icons={tableIcons}
                    tableRef={tableRef}
                    options={{
                        pageSize: 10,
                        pageSizeOptions: [10],
                        actionsColumnIndex: -1
                        }}
                    columns={[
                        { title: 'ID', field: 'id' },
                        { title: 'Orden', field: 'order' },
                        { 
                            title: 'Texto', 
                            field: 'text', 
                            width: 800,
                            render: (data) => <div dangerouslySetInnerHTML={{ __html: data.text }}  />
                        },
                        { title: 'Creado por:', field: 'user.username' },
                        { title: 'Creado', field: 'created', type: 'date' },
                        { title: 'Modificado', field: 'updated', type: 'date' },
                    ]}
                    data={qualityPolicies}
                    actions={[
                        {
                          icon: tableIcons.Add,
                          tooltip: 'Crear política de calidad',
                          isFreeAction: true,
                          onClick: handleOpen
                        },
                        {
                            icon: tableIcons.Edit,
                            tooltip: 'Editar política de calidad',
                            onClick: handleEdit
                          },
                        {
                          icon: tableIcons.Delete,
                          tooltip: 'Borrar política de calidad',
                          onClick: handleDelete
                        },
                        {
                            icon: tableIcons.Refresh,
                            tooltip: 'Recargar políticas de calidad',
                            isFreeAction: true,
                            onClick: () => getQualityPolicies() && tableRef.current.onQueryChange(),
                          }
                      ]}
                />
              </div>
          }
          {
              open && <ModalCreateQualityPolicy handleClose={handleClose} open={open} />
          }
          {
              editData && <ModalUpdateQualityPolicy rowData={editData} handleClose={handleCloseEdit} open={edit}/>
          }
      </Root>
    )
}

function mapState(state) {
    const { error, isFetching, qualityPolicies } = state.qualityPolicy;
    const { user } = state.authentication;
    return { user, error, isFetching, qualityPolicies };
  }
  
const actionCreators = {
    getQualityPolicies: qualityPolicyActions.getQualityPolicies,
    deleteQualityPolicy: qualityPolicyActions.deleteQualityPolicy,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(QualityPolicyAdmin);