import React from 'react'
import styled from 'styled-components'
import TitleSection from './TitlePage'
import SliderEvents from './Sliders/SliderEvents'
import SliderNews from './Sliders/SliderNews'
import { eventos, noticias } from '../dataEntry'
import { devices } from '../config'
import { connect } from 'react-redux';
import { alertActions } from '../redux/actions/alert.actions';
import { sectionActions } from '../redux/actions/section.actions';
import { pageActions } from '../redux/actions/page.actions';

const Section = styled.section`
  /* height: 80vh; */
  height: 520px;
  display: flex;
  flex: auto;
  align-items: stretch;
  justify-content: center;
  position: relative;
  margin-top: 10vh;
  margin-bottom: 10vh;
`

const HighSection = styled(Section)`
  /* height: 90vh; */
  height: 600px;

  @media (max-width: ${devices.laptop}px) {
    /* height: 100vh; */
    height: 600px;
  }
  @media (max-width: ${devices.laptopS}px) {
    /* height: 100vh; */
    height: 550px;
  }
  @media (max-width: ${devices.tablet}px) {
    /* height: 100vh; */
    height: 450px;
  }
  @media (max-width: ${devices.mobileM}px) {
    /* height: 100vh; */
    height: 450px;
  }
  @media (min-width: ${devices.laptopL}px) {
    /* height: 150vh; */
    height: 700px;
  }
`

const Title = styled.section`
  height: 40vh;
  width: 100%;
  display: flex;
  flex: auto;
  align-items: stretch;
  justify-content: center;
`

function NovedadesPage({ match, error, isFetching, sections, getSections, alertMessage, pages, getPages }) {

  const agenda_ref = React.useRef()
  const noticias_ref = React.useRef()


  React.useEffect(() => {
    switch (match.params.section) {
      case 'agenda':
          window.scrollTo({top: agenda_ref.current.offsetTop, left: 0, behavior: 'smooth'})
        break;
      case 'noticias':
          window.scrollTo({top: noticias_ref.current.offsetTop, left: 0, behavior: 'smooth'})
          break;
      default:
          window.scrollTo(0, 0)
        break;
    }
    if(!sections){
      getSections()
    }
    if(!pages){
      getPages()
    }
  }, [ match.params.section, sections, getSections, getPages, pages ])

    return(
          <React.Fragment>
            <Title ref={agenda_ref}>
              {
                (!sections) ? <h1>CARGANDO...</h1> : (!pages) ? <h1>CARGANDO...</h1> :
                <TitleSection title={pages[3].title} subtitle={sections[2].subtitle} background={sections[2].img}/>
              }
            </Title>
            <HighSection>
              {
                (!sections) ? <h1>CARGANDO...</h1> :
                <SliderEvents title={sections[2].subtitle} events={eventos} interval={5000} />
              }
            </HighSection>
            <Title ref={noticias_ref}>
              {
                (!sections) ? <h1>CARGANDO...</h1> :
                <TitleSection subtitle={sections[4].subtitle} background={sections[4].img}/>
              }
            </Title>
            <HighSection>
              {
                (!sections) ? <h1>CARGANDO...</h1> :
                <SliderNews title={sections[4].subtitle} news={noticias} interval={5000} />
              }
            </HighSection>
          </React.Fragment>
    )
}

function mapState(state) {
  const { error, isFetching, sections } = state.section;
  const { pages } = state.page;
  return { error, isFetching, sections, pages };
}

const actionCreators = {
  getSections: sectionActions.getSections,
  getPages: pageActions.getPages,
  alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(NovedadesPage);

/* export default NovedadesPage */