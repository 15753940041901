import React, { useState } from 'react';
/* import { Link } from 'react-router-dom'; */
import { connect } from 'react-redux';
import { userActions } from '../redux/actions/user.actions';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
// import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Copyright from './Copyright';
import { alertActions } from '../redux/actions/alert.actions';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function RegisterPage(props) {
  const classes = useStyles();
  const [ state, setState ] = useState({
    user: {
        firstName: '',
        lastName: '',
        email: '',
        username: '',
        password: '',
        repassword: ''
    },
    errors:{
      firstName: false,
      lastName: false,
      email: false,
      username: false,
      password: false,
      repassword: false
    },
    showPassword:false,
    showrePassword:false,
    submitted: false
});

  const handleChange = (event) => {
    const { name, value } = event.target;
    const { user, errors } = state;
    setState({
        ...state,
        user: {
            ...user,
            [name]: value
        },
        errors:{ ...errors }
    });
}

  const handleSubmit = (event) => {
    event.preventDefault();

    const { user } = state;
    if (user.firstName && user.lastName && user.email && user.username && user.password && user.repassword) {
      setState({ 
        ...state,
        submitted: true 
      });
      if(user.password === user.repassword){
        if(user.password.length < 8){
          setState({ 
            ...state,
            errors:{
              password: true,
              repassword: true
            },
            submitted: false 
          });
          props.alertMessage('8 characters minimum password length')  
        } else {
          props.register(user);
        }
      } else {
        setState({ 
          ...state,
          errors:{
            password: true,
            repassword: true
          },
          submitted: false 
        });
        props.alertMessage('Passwords do not match')
      }
        
    } else {
      setState({ 
        ...state,
        errors:{
          firstName: !(user.firstName),
          lastName: !(user.lastName),
          email: !(user.email),
          username: !(user.username),
          password: !(user.password),
          repassword: !(user.repassword)
        },
        submitted: false 
      });

    }
}

const handleClickShowPassword = () => {
  setState({ ...state, showPassword: !state.showPassword });
};

const handleMouseDownPassword = event => {
  event.preventDefault();
};

const handleClickShowrePassword = () => {
  setState({ ...state, showrePassword: !state.showrePassword });
};

const handleMouseDownrePassword = event => {
  event.preventDefault();
};

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Crear usuario
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                error={state.errors.firstName}
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="Nombres"
                autoFocus
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={state.errors.lastName}
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Apellidos"
                name="lastName"
                autoComplete="lname"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={state.errors.username}
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Correo electrónico"
                name="email"
                autoComplete="email"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={state.errors.username}
                variant="outlined"
                required
                fullWidth
                id="username"
                label="Usuario"
                name="username"
                autoComplete="username"
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required variant="outlined" error={state.errors.password}>
                <InputLabel required htmlFor="password" error={state.errors.password}>Contraseña</InputLabel>
                <OutlinedInput
                  error={state.errors.password}
                  required
                  name="password"
                  type={state.showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="current-password"
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={120}
                />
                </FormControl>
              </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth required variant="outlined" error={state.errors.repassword}>
                <InputLabel required htmlFor="repassword" error={state.errors.repassword}>Repetir contraseña</InputLabel>
                <OutlinedInput
                  error={state.errors.repassword}
                  required
                  name="repassword"
                  type={state.showrePassword ? 'text' : 'password'}
                  id="repassword"
                  autoComplete="current-password"
                  onChange={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle repassword visibility"
                        onClick={handleClickShowrePassword}
                        onMouseDown={handleMouseDownrePassword}
                        edge="end"
                      >
                        {state.showrePassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                  labelWidth={185}
                />
                </FormControl>
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Crear usuario
          </Button>
          {/* <Grid container justify="flex-end">
            <Grid item>
              <Link to='/login'>
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid> */}
        </form>
      </div>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}


function mapState(state) {
  const { registering } = state.registration;
  return { registering };
}

const actionCreators = {
  register: userActions.register,
  alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(RegisterPage);