import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { ArrowForwardIos } from '@material-ui/icons'
import { devices } from '../config'

const months = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];

const Root = styled(Grid)`
    position: relative;
    height: 100%;
`

const CardLink = styled(Link)`
    text-decoration: none;
    width: 100%;
    height: 100%;
    &::after {
            content: '';
            position: absolute;
            border-radius: 5px;
            top: 2.5%;
            left: 0;
            width: 95%;
            height: 90%;
            z-index: -1;
            opacity: 0;
            background: linear-gradient(153deg, #3DCCE9 0%, #D5EE51 100%);
            transition-property: all;
            transition-duration: 200ms;
            transition-timing-function: linear;
        }
    ${Root}:hover & {
        &::after {
            z-index: 1;
            opacity: 0.85;
            top: 0;
            height: 100%;
            width: 100%;
        }
    }
`

const CardImage = styled.div`
    position: absolute;
    top: 2.5%;
    left: 2%;
    height: 55%;
    width: 95%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    background: url(${(props) => props.background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: -1;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
    ${Root}:hover & {
        top: 0;
        left: 0;
        height: 60%;
        width: 100%;
        z-index: -1;
    }
`

const CardTag = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5.5%;
    left: 6.5%;
    height: 6.5%;
    width: 30%;
    color: #3DCCE9;
    font-family: 'Gotham-Book';
    font-size: 2vmin;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000029;
    background: white;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
    ${Root}:hover & {
        top: 3%;
        left: 4.5%;
        visibility: visible;
        opacity: 1;
    }
`

const CardDate = styled(Grid)`
    position: absolute;
    top: 62.5%;
    left: 5%;
    height: 17.5%;
    color: white;
    border-radius: 5px;
    background: linear-gradient(153deg, #3DCCE9 0%, #D5EE51 100%);
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
    ${Root}:hover & {
        background: transparent !important;
    }
`

const DateEvent = styled(Grid)`
    font-family: 'Lato';
    font-weight: 900;
    font-size: 1.5rem;
    z-index: 10;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
    ${Root}:hover & {
        font-size: 1.8rem;
    }
    @media (max-width: ${devices.tablet}px) {
        font-size: 2rem;
        ${Root}:hover & {
            font-size: 2.1rem;
        }
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 3.5rem;
        ${Root}:hover & {
            font-size: 4.5rem;
        }
    }
`

const MonthEvent = styled(Grid)`
    font-family: 'Lato';
    font-weight: 900;
    font-size: 0.75rem;
    letter-spacing: 0.8px;
    z-index: 10;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
    ${Root}:hover & {
        font-size: 0.85rem;
    }
    @media (max-width: ${devices.tablet}px) {
        font-size: 1.3rem;
        ${Root}:hover & {
            font-size: 1.4rem;
        }
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 2rem;
        ${Root}:hover & {
            font-size: 2.5rem;
        }
    }
`

const CardDiv = styled.div`
    position: absolute;
    top: 66%;
    left: 25%;
    background-color: white;
    height: 18%;
    width: .5%;
    z-index: 10;
`

const CardTitle = styled.div`
    position: absolute;
    top: 62.5%;
    left: 30%;
    height: 22.5%;
    width: 52.5%;
    font-family: 'Lato';
    font-weight: 900;
    font-size: 1rem;
    color: #707070;
    text-align: left;
    z-index: 10;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
    ${Root}:hover & {
        padding-top: 5%;
        padding-left: 1.5%;
        color: white
    }
    @media (max-width: ${devices.tablet}px) {
        font-size: 1.25rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 2.5rem;
    }
`

const CardButton = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: absolute;
    top: 85%;
    left: 15%;
    height: 10%;
    width: 60%;
    background-color: white;
    border-radius: 24px;
    color: #3DCCE9;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: .1rem;
    padding: .5rem .8rem;
    z-index: 10;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
    ${Root}:hover & {
        top: 87.5%;
        left: 20%;
    }
    @media (max-width: ${devices.tablet}px) {
        font-size: 1rem;
        align-items: center;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 2.5rem;
    }
`

const IconButton = styled(ArrowForwardIos)`
    margin-top: auto;
    margin-bottom: auto;
    font-size: 1rem!important;
    @media (max-width: ${devices.tablet}px) {
        font-size: 1rem!important;
        margin: 0;
    }
`

function MiniCardEvent({event}){
    return (
        <Root container item  xs={12}>
            <CardLink to={event.link+event.id}>
                <CardImage background={event.img} />
                <CardTag> {event.tag} </CardTag>
                <CardDate container direction="column" justify="center" alignItems="center" item xs={2}>
                    <DateEvent item>{new Date(event.date).getDate()}</DateEvent>
                    <MonthEvent item>{months[new Date(event.date).getMonth()]}</MonthEvent>
                </CardDate>
                <CardDiv />
                <CardTitle item xs={8}> {event.title} </CardTitle>
                <CardButton> SABER MÁS  <IconButton  /></CardButton>
            </CardLink>
        </Root>
        
    )
}

export default MiniCardEvent