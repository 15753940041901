import React from "react";
import styled from "styled-components";
import NavBar from "./NavBar";
import SectionInfo from "./SectionInfo";
import IconFacebook from "../../icons/inicio/b_facebook.svg";
import IconInstagram from "../../icons/inicio/b_instagram.svg";
import IconMail from "../../icons/inicio/b_mail.svg";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { devices } from "../../config";
import NavBarMobile from "./NavBarMobile";
import ModalComponent from "../ModalComponent";
import ModalHeader from "../ModalHeader";

const Root = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex: auto;
  flex-direction: ${(props) => props.direction};
`;

const GridLogo = styled.div`
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
  width: 50%;
  padding-left: 5%;
  padding-right: 5%;
  align-items: center;
  a {
    width: 100%;
    height: 100%;
  }
  img {
    object-fit: contain;
    width: auto;
    height: 100%;
  }
  @media (min-width: ${devices.tablet}px) {
    width: 25%;
    padding-left: 2.5%;
    padding-right: 2.5%;
    align-items: flex-end;
  }
  @media (max-width: ${devices.mobile}px) {
    width: 70%;
  }
  @media (max-width: ${devices.mobileM}px) {
    width: 70%;
  }
`;

const GridMid = styled.div`
  height: 100%;
  width: 75%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const GridSup = styled.div`
  height: 60%;
  width: 93%;
  align-self: baseline;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  @media (max-width: ${devices.mobileS}px) {
    height: 70%;
  }
  @media (max-width: ${devices.mobileM}px) {
    height: 65%;
  }
`;

const GridInf = styled.div`
  height: 40%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "Lato";
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  @media (max-width: ${devices.mobileM}px) {
    height: 35%;
    justify-content: flex-end;
  }
  @media (max-width: ${devices.tablet}px) {
    font-size: 0.9rem;
  }
`;

const GridSocialIcons = styled.div`
  height: 40%;
  width: 30%;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "Lato";
  font-size: 0.75rem;
  font-weight: 400;
  letter-spacing: 0.1rem;
  @media (max-width: ${devices.mobile}px) {
    height: 35%;
    justify-content: flex-end;
    align-items: flex-end;
  }
  @media (max-width: ${devices.mobileM}px) {
    height: 35%;
    justify-content: flex-end;
    align-items: flex-end;
  }
`;

const GridButtons = styled.div`
  height: 100%;
  width: 17.5%;
  position: relative;
  display: contents;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const baseButton = styled.a`
  height: 2rem;
  margin: 0 0.25rem;
  border-radius: 24px;
  font: Bold 0.75rem Lato;
  letter-spacing: 1.35px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  &:hover {
    background: linear-gradient(270deg, #d5ee51 0%, #3dcce9 100%);
  }
  @media (max-width: ${devices.tablet}px) {
    width: 20%;
    height: 50%;
    font-size: 0.9rem;
  }
  @media (max-width: ${devices.mobileM}px) {
    width: 40%;
    height: 60%;
    font-size: 1.5rem;
    margin-left: 1.25rem;
    margin-right: 0px;
  }
`;

const ModalPrimButton = styled(baseButton)`
  width: 350px;
  background: linear-gradient(96deg, #3dcce9 0%, #87e8fc 100%);
  color: white;
  margin: 20px;
  @media (max-width: ${devices.tablet}px) {
    width: 70%;
    font-size: 1.5rem;
    height: 4rem;
  }
  @media (max-width: ${devices.mobileM}px) {
    font-size: 2.5rem;
    width: 95%;
    height: 7rem;
  }
`;
// Botón Solicitar Turno
const PrimButton = styled(baseButton)`
  width: 11rem;
  background: linear-gradient(96deg, #3dcce9 0%, #87e8fc 100%);
  color: white;
`;

// Botón Mis Estudios
const SecButton = styled(baseButton)`
  top: 2rem;
  left: 60rem;
  width: 9rem;
  height: 2rem;
  border: 1px solid #3dcce9;
  opacity: 1;
  color: #3dcce9;
  &:hover {
    color: white;
    border: 0;
  }
`;

const SocialIcon = styled.img`
  padding: 0;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 25px;
  width: 2rem;
  letter-spacing: 0.1rem;
  font-weight: 700;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: ${devices.mobile}px) {
    width: 4rem;
  }
  @media (max-width: ${devices.mobileM}px) {
    width: 4rem;
  }
`;

function HeaderSection(props) {
  const matches = useMediaQuery(`(max-width:${devices.tablet - 1}px)`);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  if (matches) {
    return (
      <Root direction="column">
        <GridSup>
          <GridLogo>
            <a href="/">
              {" "}
              <img
                src={props.logo}
                alt="Logo DeMartino"
                style={{ width: "auto", height: "100%" }}
              />
            </a>
          </GridLogo>
          <GridSocialIcons>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={props.socialIcons.facebook}
            >
              <SocialIcon src={IconFacebook} alt="Icon Facebook" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={props.socialIcons.instagram}
            >
              <SocialIcon src={IconInstagram} alt="Icon Instagram" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={props.socialIcons.mail}
            >
              <SocialIcon src={IconMail} alt="Icon Mail" />
            </a>
          </GridSocialIcons>
        </GridSup>
        <GridInf>
          <GridButtons>
            <SecButton
              target="_blank"
              rel="noopener noreferrer"
              href="https://estudios.centrodm.com.ar"
            >
              MIS ESTUDIOS
            </SecButton>
            <PrimButton onClick={handleOpen}>{props.buttonFab}</PrimButton>
          </GridButtons>
          <NavBarMobile items={props.menu} />
        </GridInf>
        <ModalComponent open={open} handleClose={handleClose}>
          <ModalHeader handleClose={handleClose}></ModalHeader>
          <ModalPrimButton
            target="_self"
            rel="noopener noreferrer"
            href="/turnos"
          >
            SOLICITAR NUEVO TURNO
          </ModalPrimButton>
          <ModalPrimButton
            target="_self"
            rel="noopener noreferrer"
            href="/TurnConsultatiosPage"
          >
            CONSULTAR TURNOS AGENDADOS
          </ModalPrimButton>
        </ModalComponent>
      </Root>
    );
  } else {
    return (
      <Root direction="row">
        <GridLogo>
          <a href="/">
            {" "}
            <img src={props.logo} alt="Logo DeMartino" />
          </a>
        </GridLogo>
        <GridMid>
          <GridSup>
            <SectionInfo data={props.data} />
            <GridButtons>
              <SecButton
                target="_blank"
                rel="noopener noreferrer"
                href="https://estudios.centrodm.com.ar"
              >
                MIS ESTUDIOS
              </SecButton>
              <PrimButton onClick={handleOpen}>{props.buttonFab}</PrimButton>
            </GridButtons>
          </GridSup>
          <GridInf>
            <NavBar items={props.menu} />
            <GridSocialIcons>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={props.socialIcons.facebook}
              >
                <SocialIcon src={IconFacebook} alt="Icon Facebook" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={props.socialIcons.instagram}
              >
                <SocialIcon src={IconInstagram} alt="Icon Instagram" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={props.socialIcons.mail}
              >
                <SocialIcon src={IconMail} alt="Icon Mail" />
              </a>
            </GridSocialIcons>
          </GridInf>
        </GridMid>

        <ModalComponent open={open} handleClose={handleClose}>
          <ModalHeader handleClose={handleClose}></ModalHeader>
          <ModalPrimButton
            target="_self"
            rel="noopener noreferrer"
            href="/turnos"
          >
            SOLICITAR NUEVO TURNO
          </ModalPrimButton>
          <ModalPrimButton
            target="_self"
            rel="noopener noreferrer"
            href="/TurnConsultatiosPage"
          >
            CONSULTAR TURNOS AGENDADOS
          </ModalPrimButton>
        </ModalComponent>
      </Root>
    );
  }
}

export default HeaderSection;
