import React from 'react'
import styled from 'styled-components'
import { devices } from '../config'
import { connect } from 'react-redux';
import { alertActions } from '../redux/actions/alert.actions';
import { professionalActions } from '../redux/actions/professional.actions';

const Root = styled.div`
    position: relative;
    display: flex;
    flex: auto;
    flex-direction: column;
    flex-flow: wrap;
    justify-content: flex-start;
    padding-left: 15%;
    padding-right: 5%;
    box-sizing: border-box;
    @media (max-width: ${devices.mobileM}px) {
        padding-left: 15%;
        padding-right: 15%;
    }
`

const TitleSection = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    /* height: 17.5%; */
    height: auto;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-family: 'Ubuntu';
    font-size: 2.5rem;
    font-weight: 700;
    box-sizing: border-box;
    color: #3A3A3A;
    margin-bottom: 1rem;
    @media (max-width: ${devices.mobileM}px) {
        /* height: 3%; */
        height: auto;
        font-size: 3.5rem;
    }
`

const Divider = styled.div`
    position: relative;
    display: flex;
    width: 5rem;
    height: 1%;
    left: -2rem;
    background: linear-gradient(93deg, #29C4FF 0%, #D2EB51 100%);
    margin-bottom: 1rem;
    @media (max-width: ${devices.mobileM}px) {
        /* height: 1rem; */
        height: auto;
    }
`

const TextSection = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    /* height: 15%; */
    height: auto;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Lato';
    font-size: 1rem;
    font-weight: 300;
    line-height: 2;
    text-align: left;
    color: #000000;
    margin-bottom: 1rem;
    @media (max-width: ${devices.tablet}px) {
        font-size: 1.25rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1.5rem;
        /* height: 3%; */
        height: auto;
    }
    
`

const ProfesionalsContainer = styled.div`
    position: relative;
    display: flex;
    flex: auto;
    /* height: 69%; */
    height: ${(props) => 120*(props.height/2)}px;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 5vw;
    padding-right: 5vw;
    box-sizing: border-box;
    @media (max-width: ${devices.mobileM}px) {
        /* height: 89%; */
        height: ${(props) => 160*(props.height)}px;
    }
    @media (min-width: ${devices.laptopL}px) {
        height: ${(props) => 180*(props.height/2)}px;
    }
`

const Professional = styled.div`
    position: relative;
    display: flex;
    width: 50%;
    min-height: 100px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
        flex-direction: column;
        min-height: 100px;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    @media (min-width: ${devices.laptopL}px) {
        margin-top: 30px;
        margin-bottom: 30px;
    }
`

const Image = styled.img`
    position: relative;
    display: flex;
    border-radius: 50%;
    width: 4.5rem;
    height: 4.5rem;
    @media (max-width: ${devices.tablet}px) {
        width: 8rem;
        height: 8rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        width: 12.5rem;
        height: 12.5rem;
    }
`

const DetailsContainer = styled.div`
    position: relative;
    display: flex;
    flex: auto;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 4vmin;
    @media (max-width: ${devices.mobileM}px) {
        padding-left: 0;
    }  
`

const Name = styled.div`
    position: relative;
    display: flex;
    color: #3DCCE9;
    text-align: left;
    font-family: 'Ubuntu';
    font-weight: 700;
    font-size: 3vmin;
    letter-spacing: 0.2vmin;
    padding-top: 1.5vmin;
    padding-bottom: 1.5vmin;
    @media (max-width: ${devices.tablet}px) {
        font-size: 1.2rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 2.5rem;
        text-align: center;
        justify-content: center;
    }
`

const Description = styled.div`
    position: relative;
    display: flex;
    color: #3A3A3A;
    text-align: left;
    font-family: 'Lato';
    font-weight: 500;
    font-size: 1.5vmin;
    letter-spacing: 0.1vmin;
    @media (max-width: ${devices.tablet}px) {
        font-size: .75rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1.5rem;
        text-align: center;
        justify-content: center;
    }
`

function ProfessionalsSection({ getProfessionals, error, isFetching, professionals, alertMessage, title, description }) {

    React.useEffect(() => {
        if(!professionals){
            getProfessionals()
        }
    }, [ getProfessionals, professionals ])

    return(
        <Root>
            <TitleSection dangerouslySetInnerHTML={{ __html: title }} />
            <Divider />
            <TextSection dangerouslySetInnerHTML={{ __html: description }} />
            {
                (!professionals) ? <h1 style={{color: 'black'}}>CARGANDO...</h1> :
                <ProfesionalsContainer height={professionals.length}>
                    {
                        professionals.map((professional, index) => (
                            <Professional key={index}>
                                <Image src={professional.img} alt={`${professional.firstname} ${professional.lastname}`} />
                                <DetailsContainer>
                                    <Name>
                                        {`${professional.academic_title} ${professional.firstname} ${professional.lastname}`}
                                    </Name>
                                    <Description>
                                        {professional.description}
                                    </Description>
                                </DetailsContainer>
                                
                            </Professional>
                        ))
                    }
                </ProfesionalsContainer>
            }
        </Root>
    )
}

function mapState(state) {
    const { error, isFetching, professionals } = state.professional;
    return { error, isFetching, professionals };
  }
  
const actionCreators = {
    getProfessionals: professionalActions.getProfessionals,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(ProfessionalsSection);

/* export default ProfessionalsSection */