import { chatbotConstants } from '../constants';

const initialState = { 
    openChatbot: false,
    isFetching: false,
    sessionid: '',
    context: '',
    messagelist: []
};

export function chatbot(state = initialState, action) {
    switch (action.type) {
      case chatbotConstants.SESSION_REQUEST:
        return {
            ...state,
            isFetching: true,
            messagelist: [ ...state.messagelist ]
        };
      case chatbotConstants.SESSION_SUCCESS:
        return {
            ...state,
            isFetching: false,
            sessionid: action.sessionid,
            messagelist: [ ...state.messagelist ]
        };
      case chatbotConstants.SESSION_FAILURE:
        return {
          ...state,
          isFetching: false,
          messagelist: [ 
            ...state.messagelist,
            action.error
          ]
        };
      case chatbotConstants.MESSAGE_REQUEST:
        return {
            ...state,
            isFetching: true,
            messagelist: [ ...state.messagelist ]
        };
      case chatbotConstants.MESSAGE_SUCCESS:
        return {
            ...state,
            isFetching: false,
            sessionid: action.sessionId,
            context: action.context,
            messagelist: [
              ...state.messagelist,
              action.msn
            ]
        };
      case chatbotConstants.MESSAGE_FAILURE:
        return {
          ...state,
          isFetching: false,
          messagelist: [ 
            ...state.messagelist,
            action.error
          ]
        };
      case chatbotConstants.ENDSESSION_REQUEST:
        return {
            ...state,
            isFetching: true,
            messagelist: [ ...state.messagelist ]
        };
      case chatbotConstants.ENDSESSION_SUCCESS:
        return {
            ...state,
            isFetching: false,
            sessionid: '',
            context: '',
            messagelist: []
        };
      case chatbotConstants.OPEN:
        return {
          ...state,
          openChatbot: !action.status,
          messagelist: [ ...state.messagelist ]
        };
      case chatbotConstants.ENDSESSION_FAILURE:
        return {
          ...state,
          isFetching: false,
          sessionid: '',
          context: '',
          messagelist: []
        };
      case chatbotConstants.LOGOUT:
        return {
          ...state,
          isFetching: false,
          messagelist: []
        };
      default:
        return state
    }
  }