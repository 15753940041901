import React from 'react'
import styled from 'styled-components'
import { devices } from '../config'
import { connect } from 'react-redux';
import { alertActions } from '../redux/actions/alert.actions';
import { recommendationActions } from '../redux/actions/recommendation.actions';

const Root = styled.div`
    position: relative;
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 15%;
    padding-right: 15%;
    box-sizing: border-box;
    @media (max-width: ${devices.tablet}px) {
        padding-left: 15%;
        padding-right: 15%;
    }
    @media (max-width: ${devices.mobileM}px) {
        padding-left: 15%;
        padding-right: 15%;
    }
`

const TitleSection = styled.div`
    position: relative;
    display: flex;
    width: 50%;
    height: 10%;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-family: 'Ubuntu';
    font-size: 225%;
    font-weight: 700;
    letter-spacing: 0.1vmin;
    box-sizing: border-box;
    color: #474747;
    @media (max-width: ${devices.tablet}px) {
        height: 7%;
    }
    @media (max-width: ${devices.mobileM}px) {
        height: 5%;
        width: 70%;
        font-size: 3rem;
    }
`
const Divider = styled.div`
    position: absolute;
    left: -2rem;
    bottom: 0;
    width: 5rem;
    height: 1vmin;
    background: linear-gradient(93deg, #29C4FF 0%, #D2EB51 100%);
`

const TextSection = styled.div`
    position: relative;
    display: flex;
    width: 50%;
    height: 10%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    font-family: 'Lato';
    font-size: 2.8vmin;
    font-weight: 300;
    text-align: left;
    color: #3A3A3A;
    @media (max-width: ${devices.tablet}px) {
        height: 10%;
        font-size: 1.5rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        height: 5%;
        width: 70%;
    }
`

const CategoryContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 80%;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    margin-top: 2rem;
    @media (max-width: ${devices.tablet}px) {
        height: 80%;
    }
    @media (max-width: ${devices.mobileM}px) {
        height: 90%;
    }
`

const Category = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;
    box-sizing: border-box;    
    margin-bottom: 3rem;
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
        min-height: 10%;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
`
const NameContainer = styled.div`
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    width: 100%;
    height: auto;
    color: #3DCCE9;
    box-sizing: border-box;
    @media (max-width: ${devices.tablet}px) {
        height: auto;
    }
`

const Icon = styled.img`
    position: relative;
    display: flex;
    height: 3.5rem;
    width: auto;
    @media (max-width: ${devices.mobileM}px) {
        height: 6rem;
    }
`

const Name = styled.div`
    position: relative;
    display: flex;
    text-align: left;
    font-family: 'Ubuntu';
    font-weight: 900;
    font-size: 150%;
    padding-left: 2.5%;
    @media (max-width: ${devices.mobileM}px) {
        font-size: 2.5rem;
    }
`

const Text = styled.div`
    position: relative;
    width: 85%;
    min-height: 35%;
    color: #7A7A7A;
    text-align: left;
    font-family: 'Lato';
    font-weight: 300;
    font-size: 85%;
    padding-top: 5%;
    box-sizing: border-box;
    line-height: 2;
    @media (max-width: ${devices.tablet}px) {
        font-size: 1rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1.5rem;
        width: 100%;
    }
`

function RecomendacionesSection({ recommendations, getRecommendations, title, text }) {

    React.useEffect(() => {
        if(!recommendations){
            getRecommendations()
        }
    }, [getRecommendations, recommendations])

    return(
        <Root>
            <TitleSection>
                {title}
                <Divider />
            </TitleSection>
            <TextSection>
                {text}
            </TextSection>
            {
                (!recommendations) ? <h1>CARGANDO...</h1> :
                <CategoryContainer>
                    {
                        recommendations.map((item, index) => (
                            <Category key={`Cat${index}`}>
                                <NameContainer>
                                    <Icon src={item.img} alt={`Recomendación ${index}`} />
                                    <Name dangerouslySetInnerHTML={{ __html: item.title }} />
                                </NameContainer>
                                <Text dangerouslySetInnerHTML={{ __html: item.description }} />
                            </Category>
                        ))
                    }
                </CategoryContainer>
            }
        </Root>
    )
}

function mapState(state) {
    const { error, isFetching, recommendations } = state.recommendation;
    return { error, isFetching, recommendations };
  }
  
  const actionCreators = {
    getRecommendations: recommendationActions.getRecommendations,
    alertMessage: alertActions.error
  }
  
  export default connect(mapState, actionCreators)(RecomendacionesSection);

/* export default RecomendacionesSection */