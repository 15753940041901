import React from 'react'
import { connect } from 'react-redux';
import { alertActions } from '../../redux/actions/alert.actions';
import { indicationActions } from '../../redux/actions/indication.actions';
import styled from 'styled-components'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import BasicEditor from '../../components/textEditors/basicEditor';

const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
`

const ModalContent = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 50%;
    height: 80%;
    align-items: center;
    justify-content: center;
    overflow: auto;
`

const ModalTitle = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 10%;
    color: white;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #3f51b5;
`

const ModalContentSup = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 30%;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 50px;
    align-self: center;
`

const ModalContentInf = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 60%;
    align-items: flex-start;
    justify-content: center;
    padding-left: 25px;
    padding-right: 25px;
`

const Field = styled(TextField)`
    width: 100%;
    margin-bottom: 25px!important;
`
const MediumField = styled(TextField)`
    width: 25%;
`

const OrderKeyContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin-bottom: 25px;
`

//Root Wrapper of the Editor 
const EditorWrapper = styled.div`
  min-width: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 95%;
  * {
    box-sizing: border-box;
  }
`
//DraftEditor Container 
const EditorContainer = styled.div`
  display: flex;
  min-height: 9em;
  height: 100%;
  border-radius: 0 0 3px 3px;
  background-color: #fff;
  padding: 10px;
  font-size: 16px;
  font-weight: 300;
  box-shadow: 0px 0px 3px 1px rgba(15, 15, 15, 0.17);
  overflow: auto;
`

function ModalCreateIndication({ createIndication, alertMessage, user, handleClose, open }) {

    const [ ind, setInd ] = React.useState({ text: '', active: true, createdBy: user.id });
    const [ errors, setErrors ] = React.useState({
        order: false,
        title: false,
        text: false,
    })

    const [ active, setActive ] = React.useState(true);

    const handleCloseModal = () => {
        setErrors({
            order: false,
            title: false,
            text: false,
        })
        setInd({ active: true })
        handleClose();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setInd({
            ...ind,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
    }

    const handleChangeActive = (event) => {
        setActive(event.target.checked);
        setInd({
            ...ind,
            active: event.target.checked
        })
        setErrors({
            ...errors,
            active: false
        }) 
      };


    const handleForm = (e) => {
        e.preventDefault()
        if(ind.order && ind.title && ind.text && ind.createdBy){
            createIndication(ind)
            handleCloseModal()            
        }else{
            setErrors({
                order: !(ind.order),
                title: !(ind.title),
                text: !(ind.text)
            })
        }
    }

    const handleEditor = (e, dataeditor) => {
        setInd({
            ...ind,
            text: dataeditor
        })
    }


    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <ModalContent>
                    <ModalTitle>
                        CREAR INDICACIÓN
                        <Button variant="contained" onClick={handleForm}>GUARDAR</Button>
                    </ModalTitle>
                    <ModalContentSup>
                        <Field id="title" name="title" label="Título de la indicación" variant="outlined" size='small' error={errors.title} onChange={handleChange} required />
                        <OrderKeyContainer>
                            <MediumField id="order" name="order" label="Orden" type="number" variant="outlined" size='small' error={errors.order} onChange={handleChange} required />
                            <FormControlLabel style={{ margin: '2px', width: '45%'}}
                                control={
                                    <Checkbox checked={active} onChange={handleChangeActive} inputProps={{ 'aria-label': 'primary checkbox' }} color="primary" />
                                }
                                label="Preparación"
                            />
                        </OrderKeyContainer>
                    </ModalContentSup>
                    <ModalContentInf>
                        {   
                            ind &&
                            <BasicEditor
                                name="text"
                                value={ind.text}
                                onChange={handleEditor}
                                maxContentHeight="100px"
                                minContentHeight="100px"
                                placeholder="Inserte aquí el contenido de la publicación"
                            />
                        }
                    </ModalContentInf>
                </ModalContent>
            </Fade>
        </ModalStyled>
    )
}

function mapState(state) {
    const { user } = state.authentication;
    return { user };
  }
  
const actionCreators = {
    createIndication: indicationActions.createIndication,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(ModalCreateIndication);