import { professionalConstants } from '../constants';

let professionals = JSON.parse(localStorage.getItem('professionals'));
const initialState = professionals ? 
  { 
      error: false,
      isFetching: false,
      professionals: professionals
  } : 
  { 
    error: false,
    isFetching: true,
  };

export function professional(state = initialState, action) {
  switch (action.type) {
    case professionalConstants.FETCHING:
      return { 
            ...state,
            isFetching: true
        };
    case professionalConstants.SUCCESS:
      return { 
            ...state,
            isFetching: false,
            professionals: action.professionals
        };
    case professionalConstants.ERROR:
      return { 
            ...state,
            error: true
        };
    default:
      return state
  }
}