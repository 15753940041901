import React from 'react'
import { connect } from 'react-redux';
import { alertActions } from '../../redux/actions/alert.actions';
import { sliderActions } from '../../redux/actions/slider.actions';
import axios from 'axios';
import styled from 'styled-components'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import { Input } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';

const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
`

const ModalContent = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 50%;
    height: 60%;
    align-items: center;
    justify-content: center;
    overflow: auto;
`

const ModalTitle = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 15%;
    color: white;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #3f51b5;
`

const ModalContentSup = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 50px;
    align-self: center;
`

const ModalContentSupLeft = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 65%;
    height: 100%;
    padding-top: 25px;
`

const ModalContentSupRight = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 35%;
    height: 100%;
`

const Img = styled.img`
    position: relative;
    display: flex;
    width: 300px;
    height: 300px;
    align-self: center;
    margin-bottom: 10px;
`

const Field = styled(TextField)`
    width: 85%;
    margin-bottom: 50px!important;
`

const MediumField = styled(TextField)`
    width: 45%;
`

const OrderKeyContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 85%;
    margin-bottom: 25px;
`

const UploadImage = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    margin-bottom: 50px;
`

function ModalUpdateSlider({ rowData, updateSlider, alertMessage, user, handleClose, open }) {

    const [ image, setImage ] = React.useState('')
    const [ sli, setSli ] = React.useState({ ...rowData, updatedBy: user.id });
    const [ errors, setErrors ] = React.useState({
        key: false,
        order: false,
        img: false,
    })
    const [sliderKey, setSliderKey] = React.useState(rowData.key);

    const handleCloseModal = () => {
        setErrors({
            key: false,
            order: false,
            img: false,
        })
        setImage('')
        setSli({})
        setSliderKey(null)
        handleClose();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSli({
            ...sli,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
    }

    const onFileChange = (e) => {
        setImage(e.target.files[0])
    }

    const handleFileUpload = (e) => {
        if(image !== '' ){
            if(!sli.img){
                const formData = new FormData()
                formData.append('image', image)
                axios.post("/api/images/upload", formData, {
                }).then(res => {
                    const url_img = '/uploads/'+res.data.filename
                    setSli({
                        ...sli,
                        img: url_img
                    })
                    setErrors({
                        ...errors,
                        img: false
                    })    
                })
                .catch(error => {
                    alertMessage('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                })
            } else {
                // eslint-disable-next-line no-restricted-globals
                if(confirm('¿Desea subir esta nueva imagen y reemplazar la anterior?')){
                    const formData = new FormData()
                    formData.append('image', image)
                    axios.post("/api/images/upload", formData, {
                    }).then(res => {
                        const url_img = '/uploads/'+res.data.filename
                        setSli({
                            ...sli,
                            img: url_img
                        })
                        setErrors({
                            ...errors,
                            img: false
                        })    
                    })
                    .catch(error => {
                        alertMessage('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                    })  
                }
            }
        }else{
            alertMessage("Debe selecionar una imagen...")
        }
    }    

    const handleChangeSliderKey = (event) => {
        setSliderKey(event.target.value);
        setSli({
            ...sli,
            key: event.target.value
        })
        setErrors({
            ...errors,
            key: false
        }) 
    };

    const handleForm = async (e) => {
        e.preventDefault()
        if(sli.key && sli.order && sli.img && sli.updatedBy){
            await updateSlider(rowData.id, sli)
            handleCloseModal()            
        }else{
            setErrors({
                key: !(sli.key),
                order: !(sli.order),
                img: !(sli.img),
            })
        }
    }


    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <ModalContent>
                    <ModalTitle>
                        EDITAR SLIDER
                        <Button variant="contained" onClick={handleForm}>GUARDAR</Button>
                    </ModalTitle>
                    <ModalContentSup>
                        <ModalContentSupLeft>
                            <Field id="title" name="title" label="Título del slider" variant="outlined" size='small' value={sli.title} onChange={handleChange} />
                            <Field id="text" name="text" label="Texto slider" variant="outlined" size='medium' multiline rows="4" value={sli.text} onChange={handleChange} />
                            <OrderKeyContainer>
                                <MediumField id="order" name="order" label="Orden" type="number" variant="outlined" size='small' error={errors.order} value={sli.order} onChange={handleChange} required />
                                <FormControl style={{ margin: '2px', width: '45%'}}>
                                    <InputLabel error={errors.key} id="key" required>Lugar del slider</InputLabel>
                                    <Select
                                        labelId="key"
                                        id="key-select"
                                        value={sliderKey}
                                        onChange={handleChangeSliderKey}
                                        error={errors.key}
                                    >
                                        <MenuItem value={'principal'}>SLIDER PRINCIPAL</MenuItem>
                                        <MenuItem value={'secondary'}>SLIDER SECUNDARIO</MenuItem>
                                    </Select>
                                </FormControl>
                            </OrderKeyContainer>
                        </ModalContentSupLeft>
                        <ModalContentSupRight>
                            <Img src={sli.img ? sli.img : "https://colegiolovaina.cl/wp-content/plugins/learnpress/assets/images/no-image.png"} alt="Imagen subida" />
                            <UploadImage>
                                <Input style={{fontSize: '0.75rem', width: '300px', color: errors.img ? 'red' : 'black'}} id="image" name="image" type="file" onChange={onFileChange} error={errors.img} required /><CloudUploadIcon onClick={handleFileUpload} />
                            </UploadImage>
                        </ModalContentSupRight>
                    </ModalContentSup>
                </ModalContent>
            </Fade>
        </ModalStyled>
    )
}

function mapState(state) {
    const { user } = state.authentication;
    return { user };
  }
  
const actionCreators = {
    updateSlider: sliderActions.updateSlider,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(ModalUpdateSlider);