import React from 'react'
import styled from 'styled-components'
import { devices} from '../config'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import axios from 'axios'
import { apiClinica } from "../config";
import Chip from '@material-ui/core/Chip';
import Popper from '@material-ui/core/Popper';



const Container = styled.div`
display: flex;
flex-direction: column;
height: 100%;
width: 100%;
padding: 2rem;

@media (max-width: ${devices.mobileM}px) {
    height:50vh;
    min-height: 350px;
    justify-content:space-around;
}
`
const Autocom = styled(Autocomplete)`
    width: 90%;
    display:contents;
    font-size: 14px !important;

    & .MuiInput-underline:after{
        border-bottom: 2px solid #3DCCE9;
    }
    & .MuiChip-root{
        background :#3DCCE9;
        color: white;
        font-size: 1rem;
    }
    & .MuiFormLabel-root.Mui-focused{
        color: #999;
        font-size: 1rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        & .MuiChip-root{
            background :#3DCCE9;
            color: white;
            font-size: 12px;
            span {
                line-height: 20px;
            }
        }
        .MuiAutocomplete-option[data-focus="true"] {
            background: 'blue';
        };
        .MuiAutocomplete-noOption[data-focus="true"] {
            background: 'blue'
            };
        .MuiAutocomplete-noOptions{
        font: 2rem Roboto !important;
        padding: 0px;
        margin: 0px;
        }
        width: 100%;
        label {font-size: 12px;}
        input {font-size: 12px;}
        button {
            span {height: 100%}
            svg {width: 2em; height: 2em;}
        }
        & .MuiFormLabel-root.Mui-focused{
            color: #999;
            font-size: 14px;
        }
    }
`
const Th2 = styled.h2`
    align-self: start;
    @media (max-width: ${devices.mobileM}px) {
      font-size: 3rem;
    }
`

const FormButton = styled.div`
    width: 10rem;
    height: 2rem;
    border-radius: 24px;
    margin: 20px;
    background: linear-gradient(96deg, #3DCCE9 0%, #87E8FC 100%);
    color: white;
    font-size: 1rem;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    cursor:pointer;
    &:hover {
        background: linear-gradient(270deg, #D5EE51 0%, #3DCCE9 100%);
    }
    @media (max-width: ${devices.tablet}px) {
        width: 30%;
        height: 40px;
        font-size: 3rem;
    }
    @media (max-width: ${devices.mobileM}px) {
      width: 100%;
      height: 40px;
      font-size: 3rem;
    }
`

const StyledPopper = styled(Popper)`
    @media (max-width: ${devices.mobileM}px) {
        div {
            font-size: 14px;
        }   
    }
`


function PracticeSelector(props) {

    const [searching, setSearching] = React.useState(false)
    const [practicas, setPracticas] = React.useState([]);
    const [value, setValue] = React.useState(props.selected);
    const [searchText, setSearchText] = React.useState('');
    const [lastSearchText, setLastSearchText] = React.useState('');
    const [noOptionsText, setNoOptionsText] = React.useState('Ingrese al menos 3 letras para buscar.');
    const autoRef = React.createRef()

    const PopperStyled = function (props) {
        return (<StyledPopper {...props} placement='bottom-start' />)
      }

    const handleChange = async (e) => {
        e.preventDefault()
        const { value } = e.target
        setSearchText(value)

    }
    const handleConfirm = (e) => {
        props.handleAction(value)
      };

    React.useEffect(() => {
            const tick = setInterval(() => {
                if((searchText.length > 2) && (lastSearchText !== searchText)){
                    setSearching(true);
                    axios
                    .get(apiClinica.baseURL+'practica?term='+searchText+'&idSucursal'+props.idSubsidiary.id, {headers:{'Authorization': 'Bearer ' + apiClinica.token}})
                    .then(response => {
                        if(response.data.length > 0){
                            setPracticas(response.data);
                            setSearching(false);
                            setNoOptionsText('Ingrese al menos 3 letras para buscar.');
                        }
                        else{
                            setSearching(false);
                            setNoOptionsText('No hay resultados para su busqueda.');
                        }
                    })
                    .catch(error => {
                        setSearching(false)
                        alert('Hubo un error');
                        
                    })
                    setLastSearchText(searchText)
                }
            }, 500)
            return () => clearInterval(tick)
        }, [ searchText, lastSearchText, props.idSubsidiary.id ])

    return(
        <React.Fragment>
            <Container>
                <Th2>Ingrese nombre de la práctica</Th2>
                <Autocom multiple filterSelectedOptions id="acPractices" ref={autoRef} PopperComponent={PopperStyled}
                    value={value}
                    loading={searching}
                    loadingText='Cargando...'
                    noOptionsText=  {noOptionsText}
                    onChange={(event, newValue) => {
                        setValue([
                          ...newValue,
                        ]);
                      }}
                    options={practicas} getOptionLabel={(option) => option.value}
                    renderInput={(params) => (
                    <TextField {...params }  variant="standard" label="Buscar Practica" placeholder="Agregar"
                    onChange={handleChange} value={searchText}/>
                    )}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => {
                            return (
                                <Chip variant="outlined" label={option.value} {...getTagProps({ index })} />
                              )
                        })
                      }
                />
                <FormButton  onClick={handleConfirm}>CONFIRMAR</FormButton>
            </Container>
        </React.Fragment>
    )
}

export default PracticeSelector;