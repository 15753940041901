export const config = {
    companyName: 'De Martino',
    /* urlBaseApi: 'http://centrodm.com.ar:3001/api', */
    urlBaseApi: 'http://localhost:3001/api',
    copyright: 'Mole Development',
    cryptoKey: 'Mol3Crypto2020',
    reCAPTCHAKey: '6LeibgEaAAAAAAACtW41h1XJwrbvyo62axEMy8Qn'
}

export const apiClinica = {
    //baseURL: 'https://liclinica.asivas.com.ar/api.php/',
    baseURL: 'https://liclinica.centrodm.com.ar/api.php/',
    token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE1OTkzMzcwMDksIm5iZiI6MTU5OTMzNzAwOSwiYXVkIjoidHVybm9zIiwiaXNzIjoiTGlDbGluaWNhIn0.4AB6lh8wysDKeJJ7O6ttauUlo5t2N6NpKy6JM-Ui3qs'
}

export const theme = {
    palette: {}
}

export const devices = {
    mobile: 360,
    mobileM: 767,
    tablet: 768,
    laptopS: 1280,
    laptop: 1360,
    laptopL: 1920
}

export const siteData = {
    url: 'https://centrodm.com.ar',
    // url: 'http://localhost:3000',
    port_backend: '3001',
    port_frontend: '3000'
}

export const isProduction = true
