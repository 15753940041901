/* General imports */
import React from 'react';
import styled from 'styled-components';
import { devices } from '../config';


/* Import components */
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import Backdrop from '@material-ui/core/Backdrop';

/* Styled Components*/
const ModalContent = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50%;
    max-width:675px;
    height: auto;
    background-color: white;
    color: #707070;
    box-sizing: border-box;
    border-radius: 5px 5px 5px 5px;
    overflow: auto;
    list-style: inside;
    font-size: 0.75rem;
    font-family: 'Lato';
    outline: none;
    .modalColor {
        color: #3DCCE9;
    }
    .modalTitle {
        color: #3DCCE9;
    }
    li {
        color: #3DCCE9;
    }

    li span {
        color: #707070;
    }
    @media (max-width: ${devices.tablet}px) {
        width: 60%;
        max-width:400px;
        font-size: 1.5rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
        font-size: 1.5rem;
    }
`
const ModalStyled = styled(Modal)`
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto; 
    @media (max-width: ${devices.mobileM}px) {
        ${Screen.height < 500 ?'align-items: flex-start;':'align-items: center;'};
    }
`

function ModalComponent(props) {

    const handleClose = () => {
        props.handleClose()
    }

    return(
        <React.Fragment>
            <ModalStyled
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{timeout: 500,}}>
                <Fade in={props.open}>
                    <ModalContent>
                        {props.children}
                    </ModalContent>
                </Fade>
            </ModalStyled>
        </React.Fragment>
    )
}

export default ModalComponent;