import React from 'react'
import styled from 'styled-components'
import ArrowForwardIcon from '../../icons/institucional/b_flecha_adelante.svg'
import { devices } from '../../config'
import { Swipeable } from 'react-touch'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { connect } from 'react-redux';
import { alertActions } from '../../redux/actions/alert.actions';
import { sliderActions } from '../../redux/actions/slider.actions';

const Root = styled.div`
    position: relative;
    display: flex;
    flex: auto;
    padding-left: 10%;
    padding-right: 10%;
    box-sizing: border-box;
    @media (max-width: ${devices.tablet}px) {
        padding-left: 2.5%;
        padding-right: 2.5%;
    }
    @media (max-width: ${devices.mobileM}px) {
        padding-left: 2%;
        padding-right: 2%;
        flex-direction: column;
        align-items: center;
    }
`

const LeftSlide = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 52.5%;
    padding-top: 2.5%;
    height: 95%;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: ${devices.mobileM}px) {
        height: 50%;
        width: 80%;
    }
`

const RightSlide = styled.div`
    position: relative;
    display: flex;
    height: 95%;
    width: 57.5%;
    align-items: center;
    justify-content: flex-start;
    background-image: url(${(props) => props.background || 'black'});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media (max-width: ${devices.mobileM}px) {
        height: 50%;
        width: 80%;
    }
`

const TitleSection = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 35%;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-family: 'Ubuntu';
    font-size: 2.5rem;
    font-weight: 700;
    padding-left: ${(props) => props.paddingLeft || '30%'};
    padding-right: ${(props) => props.paddingRight || '10%'};
    box-sizing: border-box;
    color: #3A3A3A;
    @media (max-width: ${devices.mobileM}px) {
        font-size: 3.5rem;
        padding-left: 10%;
        padding-right: 10%;
        text-align: center;
        flex-direction: column; 
        height: 40%;
    }
`

const Divider = styled.div`
    position: absolute;
    left: ${(props) => props.left || '25%'};
    bottom: 0;
    width: 5vw;
    height: 1vmin;
    background: linear-gradient(93deg, #29C4FF 0%, #D2EB51 100%);
    @media (max-width: ${devices.mobileM}px) {
        position: relative;
        display: flex;
        width: 10rem;
        left: 0;
        margin-top: 2rem;
    }
`

const TextSection = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 65%;
    padding-top: 5%;
    padding-left: ${(props) => props.paddingLeft || '30%'};
    padding-right: ${(props) => props.paddingRight || '10%'};
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: center;
    font-family: 'Lato';
    font-size: 2.5vmin;
    font-weight: 500;
    text-align: left;
    color: #3A3A3A;
    @media (max-width: ${devices.mobileM}px) {
        font-size: 2rem;
        line-height: 1.5;
        text-align: center;
        height: 60%;
        padding-left: 5%;
        padding-right: 5%;
    }
`

const ArrowForward = styled.img`
    position: absolute;
    right: 12.5%;
    bottom: 50%;
    height: 10%;
    width: auto;
    @media (max-width: ${devices.tablet}px) {
        display: none;
    }
    @media (max-width: ${devices.mobileM}px) {
        display: none;
    }
`

const ArrowBack = styled.img`
    position: absolute;
    left: 47.5%;
    bottom: 50%;
    height: 10%;
    width: auto;
    @media (max-width: ${devices.tablet}px) {
        display: none;
    }
    @media (max-width: ${devices.mobileM}px) {
        display: none;
    }
`

const DotContainer = styled.div`
    position: absolute;
    left: ${(props) => props.left || '30%'};
    bottom: 5%;
    display: flex;
    width: 60%;
    height: 7.5%;
    box-sizing: border-box;
    align-items: center;
    justify-content: ${(props) => props.justify};
    z-index: 50;
    @media (max-width: ${devices.mobileM}px) {
        left: 20%;
        justify-content: center;
    }
`

const Dot = styled.button`
    width: 2vmin;
    height: 2vmin;
    margin: 1vmin;
    border-radius: 50%;
    border: solid 0.3vmin;
    border-color: #3DCCE9;
    background-color: transparent;
    color: transparent;
    position: relative;
    display: flex;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
    &.active {
        background-color: #3DCCE9;
    }
`

function SliderAparatologia({imageLeft, principal, secondary, getKeySliders, interval}) {
    const [ activeIndex, setActiveIndex ] = React.useState(0)

    const matches = useMediaQuery(`(max-width:${devices.mobileM}px)`);

    const [ slides, setSlides ] = React.useState(null)

    React.useEffect(() => {
        if(!principal && !imageLeft){
            getKeySliders('principal')
        } else if (!secondary && imageLeft){
            getKeySliders('secondary')
        } else {
            if(!imageLeft){
                setSlides(principal)
            } else {
                setSlides(secondary)
            }
            const tick = setInterval(() => {
                if(activeIndex < slides.length -1){
                    setActiveIndex(activeIndex + 1)
                } else {
                    setActiveIndex(0)
                }
            }, interval)
    
            return () => clearInterval(tick)
        }
        
    }, [ activeIndex, slides, interval, imageLeft, principal, secondary, getKeySliders ])

    const handleForward = (e) => {
        e.preventDefault()
        if(activeIndex !== (slides.length - 1)){
            setActiveIndex(activeIndex + 1)
        } else {
            setActiveIndex(0)
        }
    }

    const handleTouchBack = () => {
        if(activeIndex < slides.length -1){
            setActiveIndex(activeIndex + 1)
        } else {
            setActiveIndex(0)
        }
    }

    const handleTouchForward = () => {
        if(activeIndex > 0){
            setActiveIndex(activeIndex - 1)
        } else {
            setActiveIndex(slides.length -1)
        }
    }

    if(imageLeft && !matches){
        return(
            <Swipeable onSwipeLeft={handleTouchBack} onSwipeRight={handleTouchForward} >
                {
                    (!slides) ? <h1 style={{color: 'black'}}>CARGANDO...</h1> :
                    <Root container justify="center">
                        <RightSlide background={slides[activeIndex].img} />
                        <LeftSlide>
                            <TitleSection paddingLeft={'10%'} paddingRight={'30%'}>
                                { slides[activeIndex].title }
                                <Divider left={'5%'} />
                            </TitleSection>
                            <TextSection dangerouslySetInnerHTML={{ __html: slides[activeIndex].text }} paddingLeft={'10%'} paddingRight={'30%'}/>
                            <DotContainer justify={'flex-start'} left={'10%'}>
                                {
                                    slides.map((slide, index) => (
                                        <Dot 

                                            key={index}
                                            className={
                                                index === activeIndex
                                                ? 'active'
                                                : 'inactive'
                                            }
                                        />
                                    ))
                                }
                            </DotContainer>
                        </LeftSlide>
                        <ArrowBack src={ArrowForwardIcon} alt='Icono siguiente slide' onClick={handleForward} />
                    </Root> 
                }
            </Swipeable>
        )
    } else {
        return(
            <Swipeable onSwipeLeft={handleTouchBack} onSwipeRight={handleTouchForward} >
                {
                    (!slides) ? <h1 style={{color: 'black'}}>CARGANDO...</h1> :
                    <Root>
                        <LeftSlide>
                            <TitleSection>
                                { slides[activeIndex].title }
                                <Divider />
                            </TitleSection>
                            <TextSection dangerouslySetInnerHTML={{ __html: slides[activeIndex].text }} />
                            <DotContainer justify={'flex-end'}>
                                {
                                    slides.map((slide, index) => (
                                        <Dot 

                                            key={index}
                                            className={
                                                index === activeIndex
                                                ? 'active'
                                                : 'inactive'
                                            }
                                        />
                                    ))
                                }
                            </DotContainer>
                        </LeftSlide>
                        <RightSlide background={slides[activeIndex].img} />
                        <ArrowForward src={ArrowForwardIcon} alt='Icono siguiente slide' onClick={handleForward} />
                    </Root> 
                }
            </Swipeable> 
        )
    }

    
}

function mapState(state) {
    const { error, isFetching, principal, secondary } = state.slider;
    return { error, isFetching, principal, secondary };
}
  
const actionCreators = {
    getKeySliders: sliderActions.getKeySliders,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(SliderAparatologia);

/* export default SliderAparatologia */