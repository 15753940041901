import React from 'react'
import styled from 'styled-components'
import NavItem from './NavItem'

const Nav = styled.nav`
    display: flex;
    flex: auto;
    align-items: stretch;
    height: 100%;
`

const Menu = styled.ul`
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
    display: flex;
    flex: auto;
    align-items: flex-end;
`

function NavBar({ items }) {
    return (
        <Nav>
            <Menu>
            {
                items.map((item, index) => (
                    <NavItem key={index} to={item.link} name={item.name} menuItems={item.menuItems} />
                ))
            }
            </Menu>
            
        </Nav>
    )

}

export default NavBar;