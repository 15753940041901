import React from 'react'
import styled from 'styled-components'
import { devices } from '../config'

const Root = styled.div`
    position: relative;
    width:100%;
    height:40px;
    box-sizing: border-box;
    ${(props) => props.selected?'':'border: 1px solid #BEBEBE'};
    border-radius: 25px;
    opacity: 1;
    transition: all .2s ease;
    background-color:${(props) => props.selected?'#3DCCE9':'white'};
    ${(props) => props.enabled ? '' : 'opacity: 0.5'};
    ${(props) => props.enabled ? 'cursor : pointer;' : 'cursor: default'};
    &:hover {
        ${(props) => props.enabled ? 'background: linear-gradient(270deg, #D5EE51 0%, #3DCCE9 100%)' : ''};
        ${(props) => props.enabled ? 'border: 0' : ''};
        ${(props) => props.enabled ? 'img {filter: brightness(100);}' : ''};
        ${(props) => props.enabled ? 'span {color: white;}' : ''};
    }
`
const Icon = styled.img`
    width: 1.2rem;
    height: 1.2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    transition: all .2s ease;
    ${(props) => props.selected?'filter: brightness(100)':''};
    @media (max-width: ${devices.mobileM}px) {
        width: 3rem;
        height: 3rem;
    }
`
const IconRight = styled(Icon)`
    transform: rotate(-90deg);
    transition: all .2s ease;
    width: auto;
    height: auto;
    ${(props) => props.selected?'filter: brightness(100)':''};
`

const Text = styled.span`
    font-size: 1rem;
    font-family: 'Lato';
    font-weight: 700;
    letter-spacing: 0.1rem;
    color: ${(props) => props.selected?'white':'#3A3A3A'};
    text-align: left;
    width:95%;
    transition: all .2s ease;
    @media (max-width: ${devices.tablet}px) {
      font-size: 1.5rem; 
     }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 2.5rem;
    }

`
const Container = styled.div`
    position: relative;
    display: flex;
    flex: auto;
    flex-direction: inherit;
    align-items: center;
    height: 100%;
    @media (max-width: ${devices.mobileM}px) {
        flex-direction: inherit;
    }
`
function Selector(props) {
    const handleAction = ()=> {
        props.handleAction(props.name,props.label) 
    }

    return(
      <Root selected={props.selected} enabled={props.enabled} >
            <Container onClick={handleAction}>
                <Icon src={props.IconLeft} selected={props.selected} /> 
                <Text selected={props.selected} >{props.label} </Text>
                {
                    props.IconRight && <IconRight src={props.IconRight} selected={props.selected}  />
                }
            </Container>     
      </Root>
    )
}

export default Selector;