import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import styled from "styled-components";
import Global from "./styles/global";
import Logo from "./images/inicio/dm_logo_30_anios_h.webp";
import LogoFooter from "./images/LogoFooter.webp";
import HeaderSection from "./components/HeaderSection";
import FooterSection from "./components/FooterSection";
import HomePage from "./components/HomePage";
import InstitucionalPage from "./components/InstitucionalPage";
import AsistenciaPacientePage from "./components/AsistenciaPacientePage";
import NovedadesPage from "./components/NovedadesPage";
import ContactoPage from "./components/ContactoPage";
import NewsPage from "./components/NewsPage";
import EventPage from "./components/EventPage";
import TurnosPage from "./components/TurnosPage";
import TurnConsultatiosPage from "./components/TurnConsultatiosPage";
import CancelTurnosPage from "./components/CancelTurnosPage";
import { menu, gralData, subsidiaries, turnos, socialIcons } from "./dataEntry";
import { devices } from "./config";
import AppAdmin from "./AppAdmin";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { createBrowserHistory } from "history";
import ReactGA from "react-ga";

ReactGA.initialize("UA-20522108-1");

const history = createBrowserHistory();

history.listen((location) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const RootApp = styled.div`
  text-align: center;
`;

const heightHeader = {
  mobile: 120,
  mobileM: 140,
  tablet: 75,
  laptopS: 125,
  laptop: 115,
  laptopL: 180,
};

/* const heightHeader = {
  mobile: 120,
  mobileM: 140,
  tablet: 65,
  laptopS: 115,
  laptop: 115,
  laptopL: 210
} */

const Header = styled.header`
  background: #FFFFFF 0% 0% no-repeat ;
  box-shadow: 0px 3px 8px #5454541F;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0px;
  z-index: 100;
  height: ${heightHeader.mobile}px;
  /* width: ${devices.mobile}px; */
  width: 100%;

  @media (min-width: ${devices.mobileM}px) {
        height: ${heightHeader.mobile}px;
        /* width: ${devices.mobile}px; */
  }

  @media (min-width: ${devices.tablet}px) {
        height: ${heightHeader.tablet}px;
        /* width: ${devices.tablet}px; */
  }

  @media (min-width: ${devices.laptopS}px) {
        height: ${heightHeader.laptopS}px;
        /* width: ${devices.laptopS}px; */
  }

  @media (min-width: ${devices.laptop}px) {
        height: ${heightHeader.laptop}px;
        /* width: ${devices.laptop}px; */
  }

  @media (min-width: ${devices.laptopL}px) {
        height: ${heightHeader.laptopL}px;
        /* width: ${devices.laptopL}px; */
  }
`;

const Body = styled.div`
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 1;
  top: ${heightHeader.mobile}px;
  /* width: ${devices.mobile}px; */
  width: 100%;

  @media (min-width: ${devices.mobileM}px) {
    top: ${heightHeader.mobileM}px;
    /* width: ${devices.mobileM}px; */
  }

  @media (min-width: ${devices.tablet}px) {
    top: ${heightHeader.tablet}px;
    /* width: ${devices.tablet}px; */
  }
  
  @media (min-width: ${devices.laptopS}px) {
    top: ${heightHeader.laptopS}px;
    /* width: ${devices.laptopS}px; */
  }

  @media (min-width: ${devices.laptop}px) {
    top: ${heightHeader.laptop}px;
    /* width: ${devices.laptop}px; */
  }

  @media (min-width: ${devices.laptopL}px) {
    top: ${heightHeader.laptopL}px;
    /* width: ${devices.laptopL}px; */
  }
`;

const Footer = styled.footer`
  position: relative;
  height: 30vmin;
  /* width: ${devices.mobile}px; */
  top: ${heightHeader.mobile}px;
  width: 100%;
  
  @media (min-width: ${devices.mobileM}px) {
    /* width: ${devices.mobileM}px; */
    top: ${heightHeader.mobileM}px;
  }
  
  @media (min-width: ${devices.tablet}px) {
    height: 27.5vmin;
    top: ${heightHeader.tablet}px;
    /* width: ${devices.tablet}px; */
  }
  
  @media (min-width: ${devices.laptopS}px) {
    height: 40vmin;
    top: ${heightHeader.laptopS}px;
    /* width: ${devices.laptopS}px; */
  }

  @media (min-width: ${devices.laptop}px) {
    height: 40vmin;
    top: ${heightHeader.laptop}px;
    /* width: ${devices.laptop}px; */
  }

  @media (min-width: ${devices.laptopL}px) {
    height: 40vmin;
    top: ${heightHeader.laptopL}px;
    /* width: ${devices.laptopL}px; */
  }
`;

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Global />
        <Switch>
          <Route path="/admin" render={() => <AppAdmin />} />
          <Route
            path="/"
            render={() => (
              <RootApp>
                <Header>
                  <HeaderSection
                    logo={Logo}
                    data={gralData}
                    buttonFab="TURNOS ONLINE"
                    buttonFabExt="MIS ESTUDIOS"
                    optionsButtonFab={turnos}
                    menu={menu}
                    mobile={devices.mobile}
                    socialIcons={socialIcons}
                  />
                </Header>
                <Body>
                  <Switch>
                    <Route path="/" exact component={HomePage} />
                    <Route
                      path="/institucional/:section?"
                      exact
                      component={InstitucionalPage}
                    />
                    <Route
                      path="/asistenciaalpaciente/:section?"
                      exact
                      component={AsistenciaPacientePage}
                    />
                    <Route
                      path="/novedades/:section?"
                      exact
                      component={NovedadesPage}
                    />
                    <Route
                      path="/contacto/:section?"
                      exact
                      component={ContactoPage}
                    />
                    <Route path="/noticia/:id" exact component={NewsPage} />
                    <Route path="/evento/:id" exact component={EventPage} />
                    <Route path="/turnos" exact component={TurnosPage} />
                    <Route
                      path="/turnos/cancelarturno/:idTurno/:claveCancelacion"
                      component={CancelTurnosPage}
                    />
                    <Route
                      path="/TurnConsultatiosPage"
                      exact
                      component={TurnConsultatiosPage}
                    />
                  </Switch>
                </Body>
                <Footer>
                  <FooterSection
                    logo={LogoFooter}
                    subsidiaries={subsidiaries}
                  />
                </Footer>
              </RootApp>
            )}
          />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
