export const chatbotConstants = {
    MESSAGE_REQUEST: 'MESSAGE_REQUEST',
    MESSAGE_SUCCESS: 'MESSAGE_SUCCESS',
    MESSAGE_FAILURE: 'MESSAGE_FAILURE',
    SESSION_REQUEST: 'SESSION_REQUEST',
    SESSION_SUCCESS: 'SESSION_SUCCESS',
    SESSION_FAILURE: 'SESSION_FAILURE',
    ENDSESSION_REQUEST: 'ENDSESSION_REQUEST',
    ENDSESSION_SUCCESS: 'ENDSESSION_SUCCESS',
    ENDSESSION_FAILURE: 'ENDSESSION_FAILURE',
    LOGOUT: 'LOGOUT',
    OPEN: 'OPEN'
};
