import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions } from '../redux/actions/user.actions';

// This component allows rendering the components if there is user login, but redirects it to the login page

function PrivateRoute({ component: Component, ...rest }) {
    return (    
    <Route {...rest} render={props => (
        auth(rest)
            ? <Component {...props} /> 
            : <Redirect to={{ pathname: '/admin/login', state: { from: props.location } }} />
    )} />
)

}

function auth(rest){
    if(rest.loggedIn) {
        if(JSON.parse(atob(rest.user.token.split('.')[1])).exp < Math.floor(Date.now() / 1000)){
            rest.logout(false)
            return false
        } else {
            let access = rest.user.rol.scopes.split(" ")
            if(access.includes(rest.name_access)){
                return true
            } else {
                return false
            }
        }
    } else {
        return false
    }
    
}

function mapState(state) {
    const { loggedIn, user } = state.authentication;
    return { loggedIn, user };
}

const actionCreators = {
    logout: userActions.logout
  };
  
export default connect(mapState, actionCreators)(PrivateRoute)