import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import { ArrowForwardIos } from '@material-ui/icons'
import { devices } from '../config'

const months = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];

const Root = styled(Grid)`
    position: relative;
    height: 100%;
`

const CardLink = styled(Link)`
    text-decoration: none;
    width: 100%;
    height: 100%;
`

const CardImage = styled.div`
    position: absolute;
    top: 2.5%;
    left: 2%;
    height: 50%;
    width: 95%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    background: url(${(props) => props.background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 10;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
    ${Root}:hover & {
        top: 0;
        left: 0;
        height: 35%;
        width: 100%;
        z-index: 10;
    }
`

const CardVideo = styled.div`
    position: absolute;
    top: 2.5%;
    left: 2%;
    height: 50%;
    width: 95%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    background: url(${(props) => props.background});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    z-index: 10;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
    ${Root}:hover & {
        top: 0;
        left: 0;
        height: 35%;
        width: 100%;
        z-index: 10;
    }
`

const CardTag = styled(Grid)`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 5.5%;
    left: 6.5%;
    height: 6.5%;
    width: 30%;
    color: #3DCCE9;
    font-family: 'Gotham-Book';
    font-size: 2vmin;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000029;
    background: white;
    z-index: 10;
    visibility: hidden;
    opacity: 0;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
    ${Root}:hover & {
        top: 3%;
        left: 4.5%;
        visibility: visible;
        opacity: 1;
    }
`

const CardContent = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    position: absolute;
    top: 52.5%;
    height: 27.5%;
    padding-left: 5%;
    padding-right: 5%;
    box-sizing: border-box;
    width: 100%;
    z-index: 10;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
    ${Root}:hover & {
        top: 32%;
        height: 45%;
    }
`

const CardDate = styled.div`
    display: flex;
    align-items: center;
    position: relative;
    font-family: 'Gotham-Book';
    font-weight: 700;
    font-size: 1.3vmin;
    letter-spacing: 0.2vmin;
    color: #C4C4C4;
    text-align: left;
    z-index: 10;
    margin-top: 10%;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1.5rem;
        margin-top: 2.5%;
    }
`

const CardTitle = styled.div`
    display: flex;
    position: relative;
    margin-top: 3.5%;
    font-family: 'Lato';
    font-weight: 900;
    font-size: 1rem;
    color: #707070;
    text-align: left;
    z-index: 10;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
    ${Root}:hover & {
        font-size: 2.5vmin;
        color: #3DCCE9;
        margin-top: 3%;
    }
    @media (max-width: ${devices.tablet}px) {
        font-size: 1.25rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 2.5rem;
        ${Root}:hover & {
            font-size: 2.5rem;
            color: #3DCCE9;
            margin-top: 2.5%;
        }
    }
`

const CardText = styled.div`
    display: flex;
    position: relative;
    max-height: 0;
    font-family: 'Lato';
    font-weight: 700;
    font-size: 0.75rem;
    color: #A7A2A2;
    text-align: left;
    z-index: 10;
    opacity: 0;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
    ${Root}:hover & {
        max-height: 30%;
        margin-top: 2.5%;
        opacity: 1;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1.5rem;
        ${Root}:hover & {
            max-height: 30%;
            margin-top: 2.5%;
            opacity: 1;
        }
    }
`


const CardButton = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    margin-top: 2%;
    height: 15%;
    color: #3DCCE9;
    font-family: 'Lato';
    font-weight: 400;
    font-size: 1.5vmin;
    letter-spacing: .1rem;
    z-index: 10;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
    ${Root}:hover & {
        color: white;
        background: linear-gradient(153deg, #3DCCE9 0%, #D5EE51 100%);
        border-radius: 24px;
        padding: .4vmin 3vmin;
        position: absolute;
        bottom: 2.5%;
        left: 25%;
        margin-left: auto;
        margin-right: auto;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 2rem;
    }
`

const IconButton = styled(ArrowForwardIos)`
    height: 1.5vmin !important;
    width: 1.5vmin !important;
    margin-top: auto;
    margin-bottom: auto;
`

function MiniCardNews({event}){
    return (
        <Root container item  xs={12}>
            <CardLink to={event.link+event.id}>
                {
                    !event.img ? 
                    <CardVideo>
                        <iframe
                            width="100%"
                            height="100%"
                            src={`https://www.youtube.com/embed/${event.video}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title={event.title}
                            />
                    </CardVideo>
                    :
                    <CardImage background={event.img} />
                }                
                <CardTag> {event.tag} </CardTag>
                <CardContent>
                    <CardDate > {new Date(event.date).getDate()} DE {months[new Date(event.date).getMonth()]} DE {new Date(event.date).getFullYear()} </CardDate>
                    <CardTitle> {event.title} </CardTitle>
                    <CardText> {event.resumen}</CardText>
                    <CardButton> LEER MÁS  <IconButton  /></CardButton>
                </CardContent>
            </CardLink>
        </Root>
        
    )
}

export default MiniCardNews