import React from 'react'
import styled from 'styled-components'
import { devices } from '../config'
import { connect } from 'react-redux';
import { alertActions } from '../redux/actions/alert.actions';
import { qualityPolicyActions } from '../redux/actions/quality_policy.actions';

const Root = styled.div`
    position: relative;
    display: flex;
    flex: auto;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-left: 15%;
    margin-right: 15%;
    padding-left: 5%;
    padding-right: 5%;
    box-sizing: border-box;
    background: linear-gradient(253deg, #FFFEFE 0%, #F9F9F9 100%);
`

const Objetive = styled.div`
    position: relative;
    display: flex;
    width: 50%;
    min-height: 25%;
    box-sizing: border-box;
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
        min-height: 10%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }    
`

const Icon = styled.img`
    position: absolute;
    top: 20%;
    left: 5%;
    width: 10%;
    height: auto;
    @media (max-width: ${devices.mobileM}px) {
        position: relative;
        display: flex;
        top: 0;
        left: 0;
        width: 15%;
    }   
`

const Text = styled.div`
    position: absolute;
    top: 15%;
    left: 22.5%;
    width: 75%;
    max-height: 85%;
    color: #7A7A7A;
    text-align: left;
    font-family: 'Lato';
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 1.5;
    @media (max-width: ${devices.tablet}px) {
        font-size: 1.25rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        position: relative;
        display: flex;
        font-size: 2rem;
        text-align: center;
        top: 0;
        left: 0;
        margin-top: 1rem;
    }   
`

const Divider = styled.div`
    position: absolute;
    right: 10%;
    bottom: 0;
    width: 8vw;
    height: 1vmin;
    background: linear-gradient(93deg, #29C4FF 0%, #D2EB51 100%);
`

function ObjetivesSection({ qualityPolicies, getQualityPolicies }) {

    React.useEffect(() => {
        if(!qualityPolicies){
            getQualityPolicies()
        }
    }, [getQualityPolicies, qualityPolicies])

    return(
        <Root>
            {
                (!qualityPolicies) ? <h1>CARGANDO...</h1> :
                qualityPolicies.map((objetive, index) => (
                    <Objetive key={`Objetivo ${index}`}>
                        <Icon src={objetive.img} alt={`Política ${index}`} />
                        <Text dangerouslySetInnerHTML={{ __html: objetive.text }} />
                    </Objetive>
                ))
            }
            <Divider />
        </Root>
    )
}


function mapState(state) {
    const { error, isFetching, qualityPolicies } = state.qualityPolicy;
    return { error, isFetching, qualityPolicies };
  }
  
  const actionCreators = {
    getQualityPolicies: qualityPolicyActions.getQualityPolicies,
    alertMessage: alertActions.error
  }
  
  export default connect(mapState, actionCreators)(ObjetivesSection);

/* export default ObjetivesSection */