import { coverageConstants } from '../constants';
import { alertActions } from './alert.actions';
import axios from 'axios';
import  { authHeader } from '../auth-header';

export const coverageActions = {
    createCoverage,
    updateCoverage,
    getCoverages,
    deleteCoverage,
    getAllCoverages
};

function createCoverage(coverage) {
    return dispatch => {
        axios
        .post( '/api/coverages' , coverage)
        .then(response => {
            dispatch(alertActions.success('Cobertura creada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function updateCoverage(id, coverage) {
    return dispatch => {
        axios
        .put( '/api/coverages/' + id, coverage)
        .then(response => {
            dispatch(alertActions.success('Cobertura actualizada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}


function getCoverages() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
      }
    return dispatch => {
        dispatch({ type: coverageConstants.FETCHING });
        axios.get('/api/coverages',config)
        .then(coveragesData => {
            const coverages = coveragesData.data.coverages
            dispatch({ type: coverageConstants.SUCCESS, coverages });
        })
        .catch(err => {
            dispatch({ type: coverageConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function getAllCoverages() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
      }
    return dispatch => {
        dispatch({ type: coverageConstants.FETCHING_ADMIN });
        axios.get('/api/coverages/all',config)
        .then(coveragesData => {
            const coverages = coveragesData.data.coverages
            dispatch({ type: coverageConstants.SUCCESS_ADMIN, coverages });
        })
        .catch(err => {
            dispatch({ type: coverageConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function deleteCoverage(id) {
    return dispatch => {
        axios
        .delete( '/api/coverages/' + id)
        .then(response => {
            dispatch(alertActions.success('Cobertura eliminada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}