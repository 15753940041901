import React from 'react';
import 'date-fns';
import styled from 'styled-components'
import { devices } from '../config'
import DateFnsUtils from '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import axios from 'axios';
import Moment from 'moment'; 
import { es } from "date-fns/locale";

import { apiClinica } from "../config";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

const FormButton = styled.div`
    width: 10rem;
    height: 2rem;
    border-radius: 24px;
    margin: 20px;
    background: linear-gradient(96deg, #3DCCE9 0%, #87E8FC 100%);
    color: white;
    font-size: 1rem;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    cursor:pointer;
    ${(props) => props.enabled ? '' : 'opacity: 0.5'};
    ${(props) => props.enabled ? 'cursor: pointer' : 'cursor: default'};
    &:hover {
    ${(props) => props.enabled ? 'background: linear-gradient(270deg, #D5EE51 0%, #3DCCE9 100%)' : ''};
    }
    @media (max-width: ${devices.tablet}px) {
        width: 30%;
        height: 2rem;
        font-size: 1.5rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
        height: 40px;
        font-size: 3rem;
    }
`

const Root = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 95%;
    min-height:400px;
    max-height: 470px;
    flex-direction: column;
    @media (max-width: ${devices.mobileM}px) {
        max-height: 470px;
    }
`

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 80%;
    @media (max-width: ${devices.mobileM}px) {
        flex-direction: column;
    }
`

const ContainerLeft = styled.div`
    display: flex;
    width: 60%;
    height: 100%;
    box-sizing: border-box;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    h2 {margin-bottom: 10px; margin-top: 10px;}
    .MuiTypography-body2 {
        font-size: 16px;
    }
    .MuiTypography-caption {
    font-size: 10px;
    }
    .MuiTypography-body1 {
    font-size: 16px;
    }
    .MuiPickersDay-daySelected {
        background-color:#3DCCE9;
    }
    .MuiPickersDay-daySelected:hover {
        background: linear-gradient(270deg, #D5EE51 0%, #3DCCE9 100%);
    }
    .MuiPickersBasePicker-pickerView {
        min-height: 250px;  
        justify-content: flex-start;
    }   
    .MuiPickersCalendar-transitionContainer {
        min-height: 180px;
    }
    @media (max-width: ${devices.mobileM}px) {
        height: 50vh;
        max-height:270px;
        width:100%;
        h2 {margin-bottom: 0px;}
    }
`
const ContainerRight = styled.div`
    display: flex;
    width: 40%;
    height: 100%;
    box-sizing: border-box; 
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    h2 {padding-bottom: 10px; padding-top: 10px;}
    @media (max-width: ${devices.mobileM}px) {
        & > h2 {align-self: center; font-size:1.5em}
        width:100%;
        height:70px;
        align-items: flex-start;
        padding: 0 64px;
        justify-content: start;
    }
`

const ContainerFooter = styled.div`
    display: flex;
    width: 100%;
    height: 20%;
    align-items: center;
    justify-content: center;
`

const TimeContainer = styled.div`
display: flex;
flex-wrap: wrap;
overflow: auto;
font-size: 14px;
width: 100%;
min-height: 100px;
max-height: 230px;
padding: 0 20px;
margin: 20px 0;
align-items: flex-start;
justify-content: space-around;
@media (max-width: ${devices.mobileM}px) {
    flex-wrap:nowrap;
    overflow-x: auto;
    min-height: 40px;
    max-height: none;
    justify-content: flex-start;
    padding: 0px;
    margin: 5px 0;
}
`
const Columnstime = styled.div`
    width: calc(100%/${(props) => props.columns?props.columns:2 });
    padding:5px;
    @media (max-width: ${devices.mobileM}px) {
        width: auto;
    }
    @media (max-width: ${devices.tablet}px) {
        width: 33%;
    }  
`
const TimeItem = styled.div`
border: 0.5px solid #BEBEBE;
border-radius: 5px;
padding:3px;
display: flex;
align-items: center;
justify-content: center;
background-color:${(props) => props.selected?'#3DCCE9':'white'};
color: ${(props) => props.selected?'white':'black'};
font-size: 12px;
user-select:none;
cursor:pointer;
transition:all .2s ease;
&:hover {
        color: white;
        background: linear-gradient(270deg, #D5EE51 0%, #3DCCE9 100%);
    }
@media (max-width: ${devices.mobileM}px) {
    height: 95%;
}
`

const dateToString = (inputDate) => {
    const date = new Date(inputDate)
    const day = (date.getDate() < 10) ? '0'+date.getDate() : date.getDate();
    const month = (date.getMonth()+1 < 10) ? '0'+(date.getMonth()+1) : date.getMonth()+1;
    return date.getFullYear() +'-' + month + '-' + day
}

function ScheduleSelector(props) {
    const [selectedDate, setSelectedDate] = React.useState(props.selected? props.selected.date : new Date() );
    const [selectedTime, setSelectedTime] = React.useState({index:-1,value: props.selected? props.selected.time : "", practices:""});
    const [schedulResponse, setSchedulResponse] = React.useState({});

    
    const onSetSelectedTime = (e) =>{
        const {index, value}= e.target.dataset;
        setSelectedTime({'index':index,'value':value,'practices':(schedulResponse[dateToString(selectedDate)])[value]});
    }

    const handleConfirm = (e) => {
        if (selectedTime.index !== -1){
            props.handleAction(selectedDate, selectedTime.value,selectedTime.practices)
        }
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        setSelectedTime({'index':-1, 'value':'','practices':''})
    };

    React.useEffect( () => {
        (async () => {
            if(Object.entries(schedulResponse).length === 0 || !Object.keys(schedulResponse).includes(Moment(selectedDate).format('YYYY-MM-DD'))){
                let practicesrequest = "";
                props.practices.forEach(element => {
                    practicesrequest = practicesrequest === "" ? element.id: practicesrequest + ","+ element.id;
                }); 
                await axios     
                .get(apiClinica.baseURL+'slot?idPractica='+practicesrequest+'&fecha='+Moment(selectedDate).format('YYYY-MM-DD')+'&idSucursal='+props.idSubsidiary.id, {headers:{'Authorization': 'Bearer ' + apiClinica.token}})
                .then(response => {
                    const todas = response.data.todas;
                    if(todas && todas.toString().length > 0){
                        setSchedulResponse(todas);
                        if (!Object.keys(todas).includes(Moment(selectedDate).format('YYYY-MM-DD'))){
                            setSelectedDate(new Date(Object.keys(response.data.todas)[0].replace('-', '/')));
                        }
                    }
                })
                .catch(error => {
                    alert('Hubo un error')
                })
            }
        })();
    }, [schedulResponse, props.practices, selectedDate, props.idSubsidiary.id])

    return(
        <Root>
            <Container>
                <ContainerLeft>
                    <h2>Fechas disponibles</h2>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                        <Grid container justify="space-around">
                            <KeyboardDatePicker disableToolbar variant="static" format="yyyy-MM-dd" margin="normal" 
                                id="date-picker" disablePast="true" label="Seleccione fecha"  value={selectedDate} onChange={handleDateChange}
                                InputAdornmentProps={{ position: "start" }} KeyboardButtonProps={{'aria-label': 'change date',}}/> 
                        </Grid>
                    </MuiPickersUtilsProvider>
                </ContainerLeft>
                <ContainerRight>
                    <h2>Horarios disponibles</h2>
                    <TimeContainer >
                        {   
                            Object.entries(schedulResponse).length === 0 || !Object.keys(schedulResponse).includes(dateToString(selectedDate))  ? <h2>Sin horarios disponibles</h2> :
                            
                            Object.keys(schedulResponse[dateToString(selectedDate)]).map((time, index) => 
                                <Columnstime key={`columntime${index}`} columns={4}>
                                    <TimeItem selected={index === Object.keys(schedulResponse[dateToString(selectedDate)]).indexOf(selectedTime.value)} data-index={index} data-value={time} onClick={onSetSelectedTime} >{time}</TimeItem>
                                </Columnstime>
                            )
                        }
                    </TimeContainer>
                </ContainerRight>
            </Container>
            <ContainerFooter>
                <FormButton enabled={selectedTime.index !== -1} onClick={handleConfirm}>CONFIRMAR</FormButton>
            </ContainerFooter>
        </Root>
    )
}

export default ScheduleSelector;