import { medicalServiceConstants } from '../constants';

let medicalServices = JSON.parse(localStorage.getItem('medicalServices'));
const initialState = medicalServices ? 
  { 
      error: false,
      isFetching: false,
      isFetchingAdmin: false,
      medicalServices: medicalServices
  } : 
  { 
    error: false,
    isFetching: true,
  };

export function medicalService(state = initialState, action) {
  switch (action.type) {
    case medicalServiceConstants.FETCHING:
      return { 
            ...state,
            isFetching: true
        };
    case medicalServiceConstants.FETCHING_ADMIN:
      return { 
            ...state,
            isFetchingAdmin: true
        };
    case medicalServiceConstants.SUCCESS_ADMIN:
      return { 
            ...state,
            isFetchingAdmin: false,
            medicalServicesAdmin: action.medicalServices
        };
    case medicalServiceConstants.SUCCESS:
      return { 
            ...state,
            isFetching: false,
            medicalServices: action.medicalServices
        };
    case medicalServiceConstants.ERROR:
      return { 
            ...state,
            error: true
        };
    default:
      return state
  }
}