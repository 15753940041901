import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import LogoFooter from '../images/LogoFooter.webp'
import CloseImg from '../icons/inicio/icon_cerrarWhite.svg'

const Root= styled(Grid)`
    position: relative;
    background: transparent;
    opacity: 1;
    height:100%;
    width:100%;
    color: white;
    padding: 1rem;
    font-family: Lato;
    letter-spacing: 0.96px;
    font-weight: 400;
    font-size:14px;
    display: flex;
    flex: auto;
    align-items: flex-start;
    box-sizing: border-box;
    background: linear-gradient(270deg, rgb(213, 238, 81) 0%, rgb(61, 204, 233) 100%);
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-position: center;
        background-attachment: fixed;
        background-repeat: no-repeat;
        z-index: -1;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, #D5EE51 0%, #3DCCE9 100%);
        z-index: -1;
        opacity: 0.8;
    }
`

const CloseButton = styled.img`
  position: absolute;
  display: flex;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  cursor: pointer;
`

function ModalHeader(props) {
    return(
      <Root>
        <img src={LogoFooter} style={{width: '35%', height: 'auto'}} alt="Logo DeMartino"  />
        <CloseButton src={CloseImg} alt='Cerrar' onClick={props.handleClose} />
      </Root>
    )
}

export default ModalHeader;