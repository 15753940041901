import React, { lazy } from 'react'
import styled from 'styled-components'
import Carousel from './Carousel/Carousel'
import CarouselAparatologia from './Carousel/CarouselAparatologia'
import Services from './Services'
import SliderEvents from './Sliders/SliderEvents'
import SliderNews from './Sliders/SliderNews'
import SomeNumbers from './SomeNumbers'
import ContactUs from './ContactUs'
/* import MapsSection from './MapsSection' */
import BackgroundSomeNumbers from '../images/inicio/background.webp'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import IconClose from '../icons/solicitarturno/close.svg'
import IconArrow from '../icons/solicitarturno/flecha.svg'
import IconSolicitarTurno from '../icons/inicio/icon_solicitar_turno.svg'

import { avisoImportanteEnabled, avisoImportante, services, someNumbers, dataContact, turnos } from '../dataEntry'
import { devices } from '../config'

const MapsSection = lazy(() => import('./MapsSection'))

const Section = styled.section`
  /* height: 80vh; */
  height: 521px;
  width: 100%;
  display: flex;
  flex: auto;
  align-items: stretch;
  justify-content: center;
  position: relative;
  margin-top: 10vh;
  margin-bottom: 10vh;
  @media (max-width: ${devices.mobile}px) {
    /* height: 60vh; */
    height: 390px;
    margin-top: 8vh;
    margin-bottom: 8vh;
  }
`

const FirstSection = styled(Section)`
  /* height: 92vh; */
  height: 650px;
  margin: 0;
  @media (max-width: ${devices.laptop}px) {
    height: 550px;
    margin: 0;
  }
  @media (max-width: ${devices.tablet}px) {
    height: 600px;
    margin: 0;
  }
  @media (max-width: ${devices.mobileM}px) {
    /* height: 60vh; */
    height: 390px;
    margin: 0;
  }
  @media (min-width: ${devices.laptopL}px) {
    /* height: 150vh; */
    height: 800px;
  }
`

const SmallSection = styled(Section)`
  /* height: 65vh; */
  height: 480px;

  @media (max-width: ${devices.laptop}px) {
    height: 480px;
  }

  @media (max-width: ${devices.laptopS}px) {
    height: 400px;
  }
  @media (max-width: ${devices.tablet}px) {
    /* height: 55vh; */
    height: 250px;
  }
  @media (max-width: ${devices.mobileM}px) {
    /* height: 100vh; */
    height: 652px;
  }
  @media (min-width: ${devices.laptopL}px) {
    /* height: 150vh; */
    height: 580px;
  }
`

const CarouselSection = styled(Section)`
  @media (max-width: ${devices.mobileM}px) {
    /* height: 55vh; */
    height: 358px;
  }
  @media (min-width: ${devices.laptopL}px) {
    height: 750px;
  }
`

const SomenumbersSection = styled(Section)`
  /* height: 65vh; */
  height: 423px;
  @media (max-width: ${devices.tablet}px) {
    /* height: 80vh; */
    height: 400px;
  }
  @media (max-width: ${devices.mobileM}px) {
    /* height: 120vh; */
    height: 782px; 
  }
  @media (min-width: ${devices.laptopL}px) {
    height: 700px;
  }
`

const HighSection = styled(Section)`
  /* height: 90vh; */
  height: 600px;

  @media (max-width: ${devices.laptop}px) {
    height: 600px;
  }

  @media (max-width: ${devices.laptopS}px) {
    height: 550px;
  }

  @media (max-width: ${devices.tablet}px) {
    height: 430px;
  }
  @media (max-width: ${devices.mobileM}px) {
    /* height: 70vh; */
    height: 456px;
  }
  @media (min-width: ${devices.laptopL}px) {
    height: 700px;
  }
`

const ContactUsSection = styled(Section)`
  /* height: 120vh; */
  height: 782px;

  @media (max-width: ${devices.laptop}px) {
    height: 700px;
  }

  @media (max-width: ${devices.laptopS}px) {
    height: 550px;
  }

  @media (max-width: ${devices.tablet}px) {
    height: 450px;
  }
  @media (max-width: ${devices.mobileM}px) {
    /* height: 140vh; */
    height: 912px;
  }
  @media (min-width: ${devices.laptopL}px) {
    height: 900px;
  }
`

const MapSection = styled(Section)`
  /* height: 85vh; */
  height: 554px;
  @media (max-width: ${devices.tablet}px) {
    /* height: 100vh; */
    height: 550px;
  }
  @media (max-width: ${devices.mobileM}px) {
    /* height: 100vh; */
    height: 550px;
  }
`

const AvisoSection = styled(Section)`
  height: auto;
  margin: 0;
`

const Aviso = styled.img`
  position: relative;
  display: flex;
  width: 100%;
  height: auto;
`

const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 60%;
    height: 75%;

    @media (min-width: ${devices.tablet}px) {
        width: 35%;
        height: 75%;
    }

    @media (min-width: ${devices.laptop}px) {
        width: 35%;
        height: 80%;
    }

    @media (min-width: ${devices.laptopL}px) {
        width: 35%;
        height: 80%;
    }

    
    
`

const ModalMenu = styled.ul`
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 80%;
    overflow: hidden;
    color: black;
    background-color: white;
    border-radius: 0px 0px 10px 10px;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
`

const ModalMenuTitle = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: linear-gradient(270deg, #D5EE51 0%, #3DCCE9 100%);
    width: 100%;
    height: 20%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px 10px 0px 0px;
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 0.85rem;
    letter-spacing: 5px;
    color: #FFFFFF;
    box-sizing: border-box;
    padding-left: 1.5rem;
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1.25rem;
        letter-spacing: 0.3rem;
        font-weight: 500;
    }
`
const Divider = styled.div`
    background-color: #e2e2e2;
    height: 1px;
    width: 90%;
    position: absolute;
    display: flex;
    bottom: 0;
`

const ModalMenuItem = styled.li`
    color: black;
    display: flex;
    flex: auto;
    justify-content: center;
    align-items: center;
    position: relative;
    font-size: 0.5rem;
    background-color: white;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
    &:last-child {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1rem;
    }
`

const ModalMenuItemText = styled.div`
    position: relative;
    display:flex;
    height: 100%;
    width: 60%;
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 1rem;
    letter-spacing: 1px;
    color: #3A3A3A;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    justify-content: center;
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1.5rem;
    }
`

const Link = styled.a`
    color: #3A3A3A;
    line-height: 2;
    text-decoration: none;
    font-family: 'Lato';
    font-weight: 400;
    display: flex;
    flex: auto;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding-top: 1vh;
    padding-bottom: 1vh;
    padding-left: 1vw;
    padding-right: 1vw;
`

const IconOption = styled.img`
    width: 3rem;
    height: auto;
    margin-right: 1rem;
    @media (max-width: ${devices.mobileM}px) {
        width: 5rem;
    }
`

const IconNext = styled.img`
    width: 2rem;
    height: auto;
    @media (max-width: ${devices.mobileM}px) {
        display: none;
        width: 0;
    }
`

const IconButton = styled.img`
    width: 1.5rem;
    height: auto;
    margin-right: 1rem;
    @media (max-width: ${devices.mobileM}px) {
        width: 2rem;
    }
`

function HomePage(props) {

  const matches = useMediaQuery(`(max-width:${(devices.tablet - 1)}px)`);

  const [open, setOpen] = React.useState(false);

  

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

    return(
      <React.Fragment>
        {
          avisoImportanteEnabled &&
          <AvisoSection>
            <a href={avisoImportante.link}><Aviso  src={matches ? avisoImportante.srcMobile : avisoImportante.src} alt={avisoImportante.title}/></a>
          </AvisoSection>
        }
        <FirstSection>
          <Carousel interval={10000}/>
        </FirstSection>
        <SmallSection style={{marginTop: 0, marginBottom: 0}}>
          <Services services={services} solicitarTurno={handleOpen} />
        </SmallSection>
        <HighSection style={{marginTop: 0}}>
          <SliderEvents title='AGENDA' interval={5000} />
        </HighSection>
        <CarouselSection>
          <CarouselAparatologia interval={5000} />
        </CarouselSection>
        <HighSection>
          <SliderNews title='NOTICIAS' interval={5000} />
        </HighSection>
        <SomenumbersSection>
            <SomeNumbers data={someNumbers} background={BackgroundSomeNumbers} />
        </SomenumbersSection>
        <ContactUsSection>
          <ContactUs dataContact={dataContact} />
        </ContactUsSection>
        <MapSection style={{marginBottom: 0}}>
          <React.Suspense fallback={<h2>Cargando...</h2>}>
            <MapsSection />
          </React.Suspense>
        </MapSection>
        <ModalStyled
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
              timeout: 500,
          }}
          >
              <Fade in={open}>
                  <ModalContent>
                      <ModalMenuTitle>
                          <IconButton src={IconSolicitarTurno} alt='Icon Solicitar Turno'/>
                            SOLICITAR TURNO
                          <IconButton onClick={handleClose} style={{position: 'absolute', top: '25%', right: '2.5%'}} src={IconClose} alt='Close Icon'/>
                      </ModalMenuTitle>
                      <ModalMenu>
                          {
                              turnos.map((item, index) => (
                                  <ModalMenuItem key={index} >
                                      <Link target="_blank" href={item.link}>
                                          <IconOption src={item.icon} alt={item.text}/>
                                          <ModalMenuItemText>
                                              <span>{item.text}</span>
                                              <span style={{fontWeight: 300, color: '#3A3A3A', fontSize: '.75rem'}}>{item.description}</span>
                                          </ModalMenuItemText>
                                          <IconNext src={IconArrow} alt='Icon Ir'/>
                                      </Link>
                                      <Divider />
                                  </ModalMenuItem>
                              ))
                          }
                      </ModalMenu>
                  </ModalContent>
              </Fade>
          </ModalStyled>
      </React.Fragment>
    )
}

export default HomePage