import React, { lazy } from 'react'
import styled from 'styled-components'
import TitleSection from './TitlePage'
/* import MapsSection from './MapsSection' */
import ContactUs from './ContactUs'
import PreguntasFrecuentesSection from './PreguntasFrecuentesSection'
import { dataContact } from '../dataEntry'
import { devices } from '../config'
import { connect } from 'react-redux';
import { alertActions } from '../redux/actions/alert.actions';
import { sectionActions } from '../redux/actions/section.actions';
import { pageActions } from '../redux/actions/page.actions';

const MapsSection = lazy(() => import('./MapsSection'))


const Section = styled.section`
  /* height: 80vh; */
  height: 520px;
  display: flex;
  flex: auto;
  align-items: stretch;
  justify-content: center;
  position: relative;
  margin-top: 10vh;
  margin-bottom: 10vh;
  @media (max-width: ${devices.tablet}px) {
    margin-top: 8vh;
    margin-bottom: 8vh;
  }
`

const SectionPreguntasFrecuentes = styled(Section)`
  height: auto;
`

const ContactUsSection = styled(Section)`
  /* height: 120vh; */
  height: 720px;

  @media (max-width: ${devices.laptop}px) {
    /* height: 140vh; */
    height: 700px;
  }
  
  @media (max-width: ${devices.laptopS}px) {
    /* height: 140vh; */
    height: 550px;
  }

  @media (max-width: ${devices.tablet}px) {
    /* height: 140vh; */
    height: 450px;
  }
  @media (max-width: ${devices.mobileM}px) {
    /* height: 140vh; */
    height: 900px;
  }
  @media (min-width: ${devices.laptopL}px) {
    /* height: 150vh; */
    height: 900px;
  }
`

const MapSection = styled(Section)`
  /* height: 85vh; */
  height: 550px;
  @media (max-width: ${devices.tablet}px) {
    /* height: 100vh; */
    height: 550px;
  }
  @media (max-width: ${devices.mobileM}px) {
    /* height: 100vh; */
    height: 550px;
  }
`

const Title = styled.section`
  height: 40vh;
  width: 100%;
  display: flex;
  flex: auto;
  align-items: stretch;
  justify-content: center;
`

function ContactoPage({ error, isFetching, sections, getSections, alertMessage, pages, getPages }) {
  React.useEffect(() => {
    window.scrollTo(0, 0)
    if(!sections){
      getSections()
    }
    if(!pages){
      getPages()
    }
  }, [sections, getSections, getPages, pages])

    return(
      <React.Fragment>
        {
          (!sections) ? <h1>CARGANDO...</h1> : (!pages) ? <h1>CARGANDO...</h1> :
          <React.Fragment>
            <Title>
              <TitleSection title={pages[4].title} background={sections[6].img}/>
            </Title>
            <ContactUsSection style={{marginTop: '10vh'}}>
              <ContactUs dataContact={dataContact} />
            </ContactUsSection>
            <MapSection>
              <React.Suspense fallback={<h2>Cargando...</h2>}>
                <MapsSection/>
              </React.Suspense>
            </MapSection>
            <SectionPreguntasFrecuentes>
                <PreguntasFrecuentesSection />
            </SectionPreguntasFrecuentes>
          </React.Fragment>
        }
        
      </React.Fragment>
    )
}

function mapState(state) {
  const { error, isFetching, sections } = state.section;
  const { pages } = state.page;
  return { error, isFetching, sections, pages };
}

const actionCreators = {
  getSections: sectionActions.getSections,
  getPages: pageActions.getPages,
  alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(ContactoPage);

/* export default ContactoPage */