import React from 'react'
import styled from 'styled-components'
import IndicationIcon from '../icons/asistenciaalpaciente/info_paciente_icon1.svg'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { devices } from '../config'
import { connect } from 'react-redux';
import { alertActions } from '../redux/actions/alert.actions';
import { indicationActions } from '../redux/actions/indication.actions';

const Root = styled.div`
    position: relative;
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-left: 15%;
    padding-right: 15%;
    box-sizing: border-box;
`

const TitleSection = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 15%;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-family: 'Ubuntu';
    font-size: 5vmin;
    font-weight: 700;
    box-sizing: border-box;
    color: #3A3A3A;
    @media (max-width: ${devices.mobileM}px) {
        height: 10%;
    }
`

const Divider = styled.div`
    position: absolute;
    left: -2rem;
    bottom: 0;
    width: 5rem;
    height: 1vmin;
    background: linear-gradient(93deg, #29C4FF 0%, #D2EB51 100%);
`

const TextSection = styled.div`
    position: relative;
    display: flex;
    width: 40%;
    height: 15%;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-start;
    font-family: 'Lato';
    font-size: 1rem;
    font-weight: 300;
    line-height: 2;
    text-align: left;
    color: #3A3A3A;
    @media (max-width: ${devices.tablet}px) {
        font-size: 1.25rem;
        width: 50%;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1.5rem;
        height: 10%;
        width: 100%;
    }
    
`

const IndicationsContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 70%;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    padding-top: 3vmin;
    @media (max-width: ${devices.mobileM}px) {
        height: 80%;
        padding-left: 0;
        padding-right: 0;
    }
`

const Indication = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    width: 30%;
    min-height: 10%;
    margin-top: 1rem;
    box-sizing: border-box;
    font-family: 'Lato';
    font-size: 2vmin;
    font-weight: 400;
    letter-spacing: 0.1vmin;
    color: ${(props) => props.active ? '#707070' : '#C4C4C4' } ;
    user-select: none;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    &:hover {
        ${ (props) => props.active ? 'color: #3DCCE9' : '' };
    }
    @media (max-width: ${devices.tablet}px) {
        font-size: 1.25rem;
        width: 50%;
        min-height: 8%;
    }
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
        font-size: 1.5rem;
        min-height: 0;
        margin-top: 2rem;
        margin-bottom: 2rem;
        ${ (props) => props.active ? 'color: #3DCCE9' : '' };
    }
`

const Footer = styled.div`
    position: relative;
    display: flex;
    width: 90%;
    height: 5%;
    justify-content: flex-start;
    align-items: center;
    box-sizing: border-box;
    font-family: 'Ubuntu';
    font-size: 2vmin;
    font-weight: 300;
    color: #C4C4C4;
    border-top: solid grey 0.1vmin;
`

const Dot = styled.div`
    position: relative;
    display: flex;
    width: 2vmin;
    height: 2vmin;
    background-color: #E5D7D7;
    border: solid #E5D7D7 0.1vmin;
    border-radius: 50%;
    margin-right: 2vmin;
`

const IconIndication = styled.img`
    position: relative;
    display: flex;
    height: 1rem;
    width: auto;
    margin-left: 1rem;
    opacity: 0;
    visibility: hidden;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    ${Indication}:hover &{
        opacity: 1;
        visibility: visible;
    }
    @media (max-width: ${devices.mobileM}px) {
        opacity: 1;
        visibility: visible;
        height: 1.5rem;
        width: auto;
    }
`

const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
`

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: auto;
    background-color: white;
    color: #707070;
    box-sizing: border-box;
    border-radius: 5px 5px 5px 5px;
    overflow: auto;
    list-style: inside;
    padding: 3rem;
    font-size: 0.75rem;
    font-family: 'Lato';
    .modalColor {
        color: #3DCCE9;
    }
    .modalTitle {
        color: #3DCCE9;
    }
    li {
        color: #3DCCE9;
    }

    li span {
        color: #707070;
    }
    @media (max-width: ${devices.tablet}px) {
        width: 60%;
        font-size: 1rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        width: 80%;
        font-size: 1.5rem;
    }
`

function IndicacionesEstudiosSection({ indications, getIndications, title, text }) {
    const [open, setOpen] = React.useState(false);
    const [modalContent, setModalContent] = React.useState('Sin preparación')

    React.useEffect(() => {
        if(!indications){
            getIndications()
        }
      }, [indications, getIndications])

    const handleOpen = (e) => {
        e.preventDefault()
        if( e.target.dataset.active === "true"){
            setModalContent(e.target.dataset.indicationtext)
            setOpen(true);
        }
    };
  
    const handleClose = () => {
        setOpen(false);
    };

    return(
        <Root>
            <TitleSection>
                {title}
                <Divider />
            </TitleSection>
            <TextSection>
                {text}
            </TextSection>
            {
                (!indications) ? <h1 style={{ color: 'black' }}>CARGANDO...</h1> : 
                <IndicationsContainer>
                    {
                        indications.map((indication, index) => (
                            <Indication key={`Indicación ${index}`} active={indication.active} onClick={handleOpen} data-active={indication.active} data-indicationtext={indication.text} >
                                {indication.title}
                                {indication.active && <IconIndication src={IndicationIcon} alt='Icon Indication' />}
                            </Indication>
                        ))
                    }
                </IndicationsContainer>
            }
            <Footer>
                <Dot /> Sin preparación
            </Footer>
            <ModalStyled
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
                >
                    <Fade in={open}>
                        <ModalContent dangerouslySetInnerHTML={{ __html: modalContent }} />
                    </Fade>
            </ModalStyled>
        </Root>
    )
}

function mapState(state) {
    const { error, isFetching, indications } = state.indication;
    return { error, isFetching, indications };
  }
  
  const actionCreators = {
    getIndications: indicationActions.getIndications,
    alertMessage: alertActions.error
  }
  
  export default connect(mapState, actionCreators)(IndicacionesEstudiosSection);

/* export default IndicacionesEstudiosSection */