import React from 'react'
import styled from 'styled-components'
import { devices } from '../config'
import IconDireccion from '../icons/inicio/direccion.svg'
import IconPractice from '../icons/inicio/icon_estudio.svg'
import IconSchedule from '../icons/inicio/icon_fecha.svg'

const Root = styled.div`
    position: relative;
    width:100%;
    height:60px;
    opacity: 1;
    border: solid 1px;
    padding: 10px;
    border-radius: 25px;
    color: grey;
    margin: 10px;
    cursor: default;
    @media (max-width: ${devices.mobileM}px) {
        height: auto;
        margin: 5px auto;
        width:95%;
    }
`
const Container = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    @media (max-width: ${devices.mobileM}px) {
        flex-direction: column;
    }
`
const FieldContainer = styled.div`
    display: flex;  
    
    width: ${(props) => props.width ? props.width :'20%'};
    justify-content: flex-start;
    align-items: center;
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
        margin:10px 0 ;
        padding: 0 10px;
    }
`
const ButtonContainer = styled.div`
    display: flex;  
    width: 24%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
        margin:10px 0 ;
    }
`
const FormButton = styled.div`
    display:flex;
    width: 45%;
    height: 2rem;
    border-radius: 25px;
    border: solid 2px;
    border-color: #3dcce9;
    color: #3dcce9;
    font: Bold 0.85rem Lato;
    letter-spacing: 1.35px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media (max-width: ${devices.tablet}px) {
        width: 100%;
        height: 3.5rem;
        font-size: 1.5rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        width: 110px;
        height: 30px;
        font-size: 2.5rem;
    }
`

const ButtonRed = styled(FormButton)`
border-color: red;
color: red;
`

const Icon = styled.img`
    width: 1.2rem;
    height: 1.2rem;
    margin-right:5px;
    @media (max-width: ${devices.mobileM}px) {
        width: 3rem;
        height: 3rem;
    }
`
const Text = styled.span`
    font-size: 18px;
    font-family: 'Lato';
    font-weight: 700;
    color: #000;
    opacity: 0.92;
    text-align: left;
    @media (max-width: ${devices.tablet}px) {
      font-size: 1.5rem; 
     }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 2.5rem;
    }

`

function TurnCard(props) {
    
    const ActionDetail = ()=> {
        props.actionDetail(props.turnData) 
    }

    const ActionCancelation = ()=> {
        props.actionCancelation(props.turnData) 
    }

    return(
      <Root>
            <Container>
                <FieldContainer width="25%">
                    <Icon src={IconPractice}/> 
                    <Text>{props.turnData.agenda} </Text>
                </FieldContainer>
                <FieldContainer>
                    <Icon src={IconDireccion}/> 
                    <Text>{props.turnData.nombreSucursal} </Text>
                </FieldContainer>
                <FieldContainer>
                    <Icon src={IconSchedule}/> 
                    <Text>{props.turnData.fecha} </Text>
                </FieldContainer>
                {(props.actionDetail || props.actionCancelation) &&
                <ButtonContainer>
                    {(props.actionDetail) && <FormButton onClick={ActionDetail} turnData={props.turnData}>DETALLE</FormButton> }
                    {(props.actionCancelation) && <ButtonRed onClick={ActionCancelation} turnData={props.turnData}>CANCELAR</ButtonRed>}
                </ButtonContainer>}
            </Container>     
      </Root>
    )
}

export default TurnCard;