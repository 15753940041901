import React from 'react'
import styled from 'styled-components'
import { Grid } from '@material-ui/core'
import { devices } from '../config'

const months = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
`

const Image = styled.div`
    width: 90%;
    height: 400px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    background-image: url(${(props) => props.background || 'white'});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 3rem;
    @media (max-width: ${devices.tablet}px) {
        height: 300px;
    }
    @media (max-width: ${devices.mobileM}px) {
        height: 200px;
    }
`

const Tag = styled.div`
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    height: 1.5rem;
    color: #3DCCE9;
    font-family: 'Lato';
    font-size: 1rem;
    background: white;
`

const Header = styled.div`
    position: relative;
    display: flex;
    width: 90%;
    margin-bottom: 1.5rem;
`

const Title = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: left;
    font-size: 2rem;
    font-family: 'Lato';
    font-weight: 700;
    letter-spacing: 0;
    color: #3A3A3A;
`

const ResumenText = styled.div`
    position: relative;
    display: flex;
    width: 90%;
    text-align: left;
    letter-spacing: 0;
    font-size: 1rem;
    font-family: 'Lato';
    font-weight: 700;
    letter-spacing: 0.05rem;
    line-height: 1.5;
    color: #3A3A3A;
    margin-bottom: 1.5rem;
`

const BodyText = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    text-align: left;
    letter-spacing: 0;
    font-size: 0.8rem;
    font-family: 'Lato';
    font-weight: 400;
    letter-spacing: 0.05rem;
    line-height: 2;
    color: #000000;
    p {
        margin-bottom: 1.5rem;
    }
`

const CardDate = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 7rem;
    width: 5rem;
    color: white;
    border-radius: 5px;
    background: linear-gradient(153deg, #3DCCE9 0%, #D5EE51 100%);
    margin-right: 2rem;
`

const DateEvent = styled(Grid)`
    font-family: 'Lato';
    font-weight: 900;
    font-size: 2rem;
    z-index: 10;
    @media (max-width: ${devices.tablet}px) {
        font-size: 2rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 2.5rem;
    }
`

const MonthEvent = styled(Grid)`
    font-family: 'Lato';
    font-weight: 900;
    font-size: 1.25rem;
    letter-spacing: 0.8px;
    z-index: 10;
    @media (max-width: ${devices.tablet}px) {
        font-size: 1.25rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1.5rem;
    }
`


function Event({ event }) {

    return(
      <Root>
            <Image background={event.img} />
            <Header>
                <CardDate >
                    <DateEvent item>{new Date(event.date).getDate()}</DateEvent>
                    <MonthEvent item>{months[new Date(event.date).getMonth()]}</MonthEvent>
                </CardDate>
                <Title>
                    <Tag>
                        {event.tag}
                    </Tag>
                    {event.title}
                </Title>
            </Header>
            <ResumenText>
                {event.copete}
            </ResumenText>
            <BodyText dangerouslySetInnerHTML={{ __html: event.text }} />
      </Root>
    )
}

export default Event