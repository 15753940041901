import React from 'react'
import styled from 'styled-components'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { devices } from '../config'
import { connect } from 'react-redux';
import { alertActions } from '../redux/actions/alert.actions';
import { coverageActions } from '../redux/actions/coverage.actions';



const Root = styled.div`
    position: relative;
    display: flex;
    flex: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 15%;
    padding-right: 15%;
    box-sizing: border-box;
    @media (max-width: ${devices.tablet}px) {
        padding-left: 15%;
        padding-right: 15%;
    }
`

const TitleSection = styled.div`
    position: relative;
    display: flex;
    width: 40%;
    height: 15%;
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    font-family: 'Montserrat';
    font-size: 180%;
    font-weight: 700;
    letter-spacing: 0.3vmin;
    box-sizing: border-box;
    color: #3A3A3A;
    @media (max-width: ${devices.tablet}px) {
        height: 12.5%;
    }
    @media (max-width: ${devices.mobileM}px) {
        height: 7%;
        width: 80%;
        font-size: 2.75rem;
        text-align: center;
        align-self: center;
        align-items: center;
        flex-direction: column;
    }
`
const Divider = styled.div`
    position: absolute;
    left: -2rem;
    bottom: 0;
    width: 5rem;
    height: 1vmin;
    background: linear-gradient(93deg, #29C4FF 0%, #D2EB51 100%);
    @media (max-width: ${devices.mobileM}px) {
        position: relative;
        width: 10rem;
        left: 0;
        margin-top: 2rem;

    }
`

const TextSection = styled.div`
    position: relative;
    display: flex;
    width: 40%;
    height: 15%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    font-family: 'Lato';
    font-size: 2.8vmin;
    font-weight: 300;
    text-align: left;
    color: #3A3A3A;
    @media (max-width: ${devices.tablet}px) {
        font-size: 1.5rem;
        height: 12.5%;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 2rem;
        line-height: 1.5;
        text-align: center;
        height: 7%;
        width: 80%;
        align-self: center;
    }
`

const Notice = styled.div`
    position: absolute;
    top: 12.5%;
    right: 10%;
    width: 30%;
    height: 10%;
    background: linear-gradient(180deg, #87E8FC 0%, #3DCCE9 100%);
    color: white;
    font-family: 'Lato';
    font-size: 2vmin;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 5vmin;
    padding-right: 5vmin;
    box-sizing: border-box;
    svg {
        font-size: 5rem;
    }
    @media (max-width: ${devices.tablet}px) {
        top: 10%;
        font-size: 1.25rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        position: relative;
        align-self: center;
        padding-left: 3rem;
        padding-right: 3rem;
        width: 80%;
        height: 15%;
        top: 0;
        right: 0;
        margin-top: 3rem;
        margin-bottom: 3rem;
        line-height: 1.75;
        font-size: 2rem;
        text-align: center;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        svg {
            font-size: 7rem;
        }
    }
`

const CategoryContainer = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 65%;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    @media (max-width: ${devices.tablet}px) {
        padding-left: 0;
        padding-right: 0;
        height: 70%;
    }
    @media (max-width: ${devices.mobileM}px) {
        padding-left: 2.5%;
        padding-right: 2.5%;
        height: 90%;
    }
`

const Category = styled.ul`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 30%;
    height: max-content;
    color: #3DCCE9;
    font-family: 'Montserrat';
    font-size: 3vmin;
    font-weight: 500;
    box-sizing: border-box;
    margin: 0;
    margin-top: 1rem;
    @media (max-width: ${devices.tablet}px) {
        font-size: 1.5rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 3rem;
        width: 100%;
    }
`

const Item = styled.li`
    position: relative;
    display: flex;
    width: 100%;
    color: #474747;
    text-transform: none;
    font-family: 'Lato';
    font-size: 2vmin;
    font-weight: 300;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    @media (max-width: ${devices.tablet}px) {
        font-size: 1rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1.75rem;
    }
`

const Line = styled.div`
    position: relative;
    display: flex;
    width: 20vw;
    height: 0.2vh;
    opacity: 0.5;
    margin-left: 1vw;
    background-color: #5454541F;
`

function CoberturasSection({ coverages, getCoverages, title, text, notice }) {

    React.useEffect(() => {
        if(!coverages){
            getCoverages()
        }
      }, [coverages, getCoverages])

    return(
        <Root>
            <TitleSection>
                {title}
                <Divider />
            </TitleSection>
            <TextSection>
                {text}
            </TextSection>
            <Notice>
               <ErrorOutlineIcon /> {notice}
            </Notice>
            {
                (!coverages) ? <h1 style={{ color: 'black' }}>CARGANDO...</h1> : 
                <CategoryContainer>
                    {
                        coverages.map((item, index) => (
                            <Category key={`Letter${index}`}>
                                <div style={{display: 'flex', alignItems: 'center'}}>{item.letter}  <Line /></div> 
                                {
                                    item.coverages.map((dat, index) => ( 
                                            <Item key={`Item${index}`}>
                                                {dat}
                                            </Item>
                                    ))
                                }
                            </Category>
                        ))
                    }
                </CategoryContainer>
            }
        </Root>
    )
}

function mapState(state) {
    const { error, isFetching, coverages } = state.coverage;
    return { error, isFetching, coverages };
  }
  
  const actionCreators = {
    getCoverages: coverageActions.getCoverages,
    alertMessage: alertActions.error
  }
  
  export default connect(mapState, actionCreators)(CoberturasSection);