import React, { Component } from 'react'

export default class resumen extends Component {
 
  render(){
    return(
      <React.Fragment>
        SELECCIONE DE LA BARRA LATERAL IZQUIERDA EL MENÚ CORRESPONDIENTE
      </React.Fragment>
    )
  }
}
