import { turnConstants } from '../constants';
import { alertActions } from './alert.actions';
import axios from 'axios';
import  { authHeader } from '../auth-header';

export const turnActions = {
    getSucursales,
    getPracticas,
    getSchedule,
    createTurn,
    updateTurn,
    getTurns,
    deleteTurn
};

function createTurn(turn) {
    return dispatch => {
        axios
        .post( '/api/turns' , turn)
        .then(response => {
            dispatch(alertActions.success('Página creada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function updateTurn(id, turn) {
    return dispatch => {
        axios
        .put( '/api/turns/' + id, turn)
        .then(response => {
            dispatch(alertActions.success('Página actualizada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}


function getTurns() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
      }
    return dispatch => {
        dispatch({ type: turnConstants.FETCHING });
        axios.get('/api/turns',config)
        .then(turnsData => {
            const turns = turnsData.data.turns
            dispatch({ type: turnConstants.SUCCESS, turns });
        })
        .catch(err => {
            dispatch({ type: turnConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}
function getSucursales() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
      }
    return dispatch => {
        dispatch({ type: turnConstants.FETCHING });
        axios.get('/api/sucursales',config)
        .then(turnsData => {
            const sucursales = turnsData.data.sucursales
            const id = turnsData.data.id
            dispatch({ type: turnConstants.SUCURSALES, sucursales, id });
        })
        .catch(err => {
            dispatch({ type: turnConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function getPracticas() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
      }
    return dispatch => {
        dispatch({ type: turnConstants.FETCHING });
        axios.get('/api/sucursales',config)
        .then(turnsData => {
            const sucursales = turnsData.data.sucursales
            const id = turnsData.data.id
            dispatch({ type: turnConstants.SUCURSALES, sucursales, id });
        })
        .catch(err => {
            dispatch({ type: turnConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function getSchedule() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
      }
    return dispatch => {
        dispatch({ type: turnConstants.FETCHING });
        axios.get('/api/sucursales',config)
        .then(turnsData => {
            const sucursales = turnsData.data.sucursales
            const id = turnsData.data.id
            dispatch({ type: turnConstants.SUCURSALES, sucursales, id });
        })
        .catch(err => {
            dispatch({ type: turnConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function deleteTurn(id) {
    return dispatch => {
        axios
        .delete( '/api/turns/' + id)
        .then(response => {
            dispatch(alertActions.success('Página eliminada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}