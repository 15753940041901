import { indicationConstants } from '../constants';
import { alertActions } from './alert.actions';
import axios from 'axios';
import  { authHeader } from '../auth-header';

export const indicationActions = {
    createIndication,
    updateIndication,
    getIndications,
    deleteIndication
};

function createIndication(indication) {
    return dispatch => {
        axios
        .post( '/api/indications' , indication)
        .then(response => {
            dispatch(alertActions.success('Indicación creada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function updateIndication(id, indication) {
    return dispatch => {
        axios
        .put( '/api/indications/' + id, indication)
        .then(response => {
            dispatch(alertActions.success('Indicación actualizada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}


function getIndications() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
      }
    return dispatch => {
        dispatch({ type: indicationConstants.FETCHING });
        axios.get('/api/indications',config)
        .then(indicationsData => {
            const indications = indicationsData.data.indications
            dispatch({ type: indicationConstants.SUCCESS, indications });
        })
        .catch(err => {
            dispatch({ type: indicationConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function deleteIndication(id) {
    return dispatch => {
        axios
        .delete( '/api/indications/' + id)
        .then(response => {
            dispatch(alertActions.success('Indicación eliminada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}