import React from 'react'
import { connect } from 'react-redux';
import { alertActions } from '../../redux/actions/alert.actions';
import { medicalServiceActions } from '../../redux/actions/medical_service.actions';
import styled from 'styled-components'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
`

const ModalContent = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 30%;
    height: 40%;
    align-items: center;
    justify-content: center;
    overflow: auto;
`

const ModalTitle = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 20%;
    color: white;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #3f51b5;
`

const ModalContentSup = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 80%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 50px;
    align-self: center;
`


const Field = styled(TextField)`
    width: 100%;
    margin-bottom: 25px!important;
`

const MediumField = styled(Field)`
    width: 25%;
`

function ModalCreateMedicalService({ createMedicalService, alertMessage, user, handleClose, open }) {

    const [ ser, setSer ] = React.useState({ createdBy: user.id });
    const [ errors, setErrors ] = React.useState({
        order: false,
        service: false,
    })

    const handleCloseModal = () => {
        setErrors({
            order: false,
            service: false,
        })
        setSer({})
        handleClose();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSer({
            ...ser,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
    }

    const handleForm = async (e) => {
        e.preventDefault()
        if(ser.order && ser.service && ser.createdBy){
            await createMedicalService(ser)
            handleCloseModal()            
        }else{
            setErrors({
                order: !(ser.order),
                service: !(ser.service),
            })
        }
    }


    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <ModalContent>
                    <ModalTitle>
                        CREAR SERVICIO MÉDICO
                        <Button variant="contained" onClick={handleForm}>GUARDAR</Button>
                    </ModalTitle>
                    <ModalContentSup>
                        <MediumField id="order" name="order" label="Orden" type='number' variant="outlined" size='small' error={errors.order} onChange={handleChange} required />
                        <Field id="service" name="service" label="Servicio" variant="outlined" size='small' error={errors.service} onChange={handleChange} required />
                        <Field id="study" name="study" label="Estudio" variant="outlined" size='small' onChange={handleChange} />
                    </ModalContentSup>
                </ModalContent>
            </Fade>
        </ModalStyled>
    )
}

function mapState(state) {
    const { user } = state.authentication;
    return { user };
  }
  
const actionCreators = {
    createMedicalService: medicalServiceActions.createMedicalService,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(ModalCreateMedicalService);