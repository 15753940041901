import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { PersonAdd, LibraryBooks, ViewCarousel, ChromeReaderMode, People, ListAlt, AddToPhotos, LocalHospital, Receipt, AccountBalance, Pages, WebAsset } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { nameAccess } from '../nameAccessConstants'
import styled from 'styled-components'

const TextLink = styled(ListItemText)`
  .MuiTypography-body1 {
    font-size: 0.75rem;
  }
`

export const mainListItems = [
  { 
    key:1,
    name_access: nameAccess.ADMIN, 
    itemmenu: (
      <NavLink exact to="/admin/register" style={{ textDecoration: 'none', color: 'black' }} activeStyle={{ color: 'blue' }}>
        <ListItem button>
          <ListItemIcon>
            <PersonAdd />
          </ListItemIcon>
          <TextLink primary="REGISTRAR"  />
        </ListItem>
      </NavLink>
    )
  },
  { 
    key:2,
    name_access: nameAccess.DASHBOARD, 
    itemmenu: (
      <NavLink exact to="/admin/publications" style={{ textDecoration: 'none', color: 'black' }} activeStyle={{ color: 'blue' }}>
        <ListItem button>
          <ListItemIcon>
            <LibraryBooks />
          </ListItemIcon>
          <TextLink primary="PUBLICACIONES" />
        </ListItem>
      </NavLink>
    )
  },
  { 
    key:3,
    name_access: nameAccess.DASHBOARD, 
    itemmenu: (
      <NavLink exact to="/admin/banner" style={{ textDecoration: 'none', color: 'black' }} activeStyle={{ color: 'blue' }}>
        <ListItem button>
          <ListItemIcon>
            <ViewCarousel />
          </ListItemIcon>
          <TextLink primary="BANNERS" />
        </ListItem>
      </NavLink>
    )
  },
  { 
    key:4,
    name_access: nameAccess.DASHBOARD, 
    itemmenu: (
      <NavLink exact to="/admin/slider" style={{ textDecoration: 'none', color: 'black' }} activeStyle={{ color: 'blue' }}>
        <ListItem button>
          <ListItemIcon>
            <ChromeReaderMode />
          </ListItemIcon>
          <TextLink primary="SLIDERS" />
        </ListItem>
      </NavLink>
    )
  },
  { 
    key:5,
    name_access: nameAccess.DASHBOARD, 
    itemmenu: (
      <NavLink exact to="/admin/professional" style={{ textDecoration: 'none', color: 'black' }} activeStyle={{ color: 'blue' }}>
        <ListItem button>
          <ListItemIcon>
            <People />
          </ListItemIcon>
          <TextLink primary="PROFESIONALES" />
        </ListItem>
      </NavLink>
    )
  },
  { 
    key:6,
    name_access: nameAccess.DASHBOARD, 
    itemmenu: (
      <NavLink exact to="/admin/indication" style={{ textDecoration: 'none', color: 'black' }} activeStyle={{ color: 'blue' }}>
        <ListItem button>
          <ListItemIcon>
            <ListAlt />
          </ListItemIcon>
          <TextLink primary="INDICACIONES" />
        </ListItem>
      </NavLink>
    )
  },
 { 
    key:7,
    name_access: nameAccess.DASHBOARD, 
    itemmenu: (
      <NavLink exact to="/admin/coverage" style={{ textDecoration: 'none', color: 'black' }} activeStyle={{ color: 'blue' }}>
        <ListItem button>
          <ListItemIcon>
            <AddToPhotos />
          </ListItemIcon>
          <TextLink primary="COBERTURAS" />
        </ListItem>
      </NavLink>
    )
  },
  { 
    key:8,
    name_access: nameAccess.DASHBOARD, 
    itemmenu: (
      <NavLink exact to="/admin/medicalservice" style={{ textDecoration: 'none', color: 'black' }} activeStyle={{ color: 'blue' }}>
        <ListItem button>
          <ListItemIcon>
            <LocalHospital />
          </ListItemIcon>
          <TextLink primary="SERV. MÉDICOS" />
        </ListItem>
      </NavLink>
    )
  },
  { 
    key:9,
    name_access: nameAccess.DASHBOARD, 
    itemmenu: (
      <NavLink exact to="/admin/recommendation" style={{ textDecoration: 'none', color: 'black' }} activeStyle={{ color: 'blue' }}>
        <ListItem button>
          <ListItemIcon>
            <Receipt />
          </ListItemIcon>
          <TextLink primary="RECOMENDACIONES" />
        </ListItem>
      </NavLink>
    )
  },
  { 
    key:10,
    name_access: nameAccess.DASHBOARD, 
    itemmenu: (
      <NavLink exact to="/admin/qualitypolicy" style={{ textDecoration: 'none', color: 'black' }} activeStyle={{ color: 'blue' }}>
        <ListItem button>
          <ListItemIcon>
            <AccountBalance />
          </ListItemIcon>
          <TextLink primary="POLÍTICAS" />
        </ListItem>
      </NavLink>
    )
  },
  { 
    key:11,
    name_access: nameAccess.ADMIN, 
    itemmenu: (
      <NavLink exact to="/admin/page" style={{ textDecoration: 'none', color: 'black' }} activeStyle={{ color: 'blue' }}>
        <ListItem button>
          <ListItemIcon>
            <Pages />
          </ListItemIcon>
          <TextLink primary="PÁGINAS" />
        </ListItem>
      </NavLink>
    )
  },
  { 
    key:12,
    name_access: nameAccess.ADMIN, 
    itemmenu: (
      <NavLink exact to="/admin/section" style={{ textDecoration: 'none', color: 'black' }} activeStyle={{ color: 'blue' }}>
        <ListItem button>
          <ListItemIcon>
            <WebAsset />
          </ListItemIcon>
          <TextLink primary="SECCIONES" />
        </ListItem>
      </NavLink>
    )
  }
]
