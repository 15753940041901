import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import CardNews from './MiniCardNews'

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 90%;
`

const Title = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
    font-size: 1.5rem;
    font-family: 'Lato';
    font-weight: 700;
    letter-spacing: 0.1rem;
    color: #3DCCE9;
    margin-bottom: 1.5rem;
`
const Container = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 450px;
`

const CardContainer = styled(Grid)`
    position: relative;
    flex: auto;
`

const Card = styled(CardNews)`
    width: 100%!important;
    max-width: 100%!important;
    flex-basis: 100%!important;
`

function SideBarNews({ id, items }) {

    return(
      <Root>
          <Title>
              NOTAS RECIENTES
          </Title>
          {
              items.slice(0,3).map((item, index) => (
                <Container key={index}>
                    <CardContainer container>
                        <Card event={item} />    
                    </CardContainer>
                </Container>
              ))
          }
      </Root>
    )
}

export default SideBarNews