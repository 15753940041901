import { createGlobalStyle } from "styled-components";
import { devices } from '../config'

export const Global = createGlobalStyle`

    

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  :root {
        height: auto;
            /* width: ${devices.mobile}px; */
            font-size: 6px;
            width: 100%;

        @media (min-width: ${devices.mobileM}px) {
            /* width: ${devices.mobileM}px; */
            font-size: 6px;
        }

        @media (min-width: ${devices.tablet}px) {
            /* width: ${devices.tablet}px; */
            font-size: 8px;
        }

        @media (min-width: 1024px) {
            /* width: ${devices.tablet}px; */
            font-size: 12px;
        }

        @media (min-width: ${devices.laptopS}px) {
            /* width: ${devices.laptopS}px; */
            font-size: 14px;
        }

        @media (min-width: ${devices.laptop}px) {
            /* width: ${devices.laptop}px; */
            font-size: 16px;
        }

        @media (min-width: ${(devices.laptopL)}px) {
            /* width: ${devices.laptopL}px; */
            font-size: 20px;
        }
    }
`;

export default Global;
