import { coverageConstants } from '../constants';

let coverages = JSON.parse(localStorage.getItem('coverages'));
const initialState = coverages ? 
  { 
      error: false,
      isFetching: false,
      isFetchingAdmin: false,
      coverages: coverages
  } : 
  { 
    error: false,
    isFetching: true,
  };

export function coverage(state = initialState, action) {
  switch (action.type) {
    case coverageConstants.FETCHING:
      return { 
            ...state,
            isFetching: true
        };
    case coverageConstants.FETCHING_ADMIN:
      return { 
            ...state,
            isFetchingAdmin: true
        };
    case coverageConstants.SUCCESS_ADMIN:
      return { 
            ...state,
            isFetchingAdmin: false,
            coveragesAdmin: action.coverages
        };
    case coverageConstants.SUCCESS:
      return { 
            ...state,
            isFetching: false,
            coverages: action.coverages
        };
    case coverageConstants.ERROR:
      return { 
            ...state,
            error: true
        };
    default:
      return state
  }
}