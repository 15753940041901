import { indicationConstants } from '../constants';

let indications = JSON.parse(localStorage.getItem('indications'));
const initialState = indications ? 
  { 
      error: false,
      isFetching: false,
      indications: indications
  } : 
  { 
    error: false,
    isFetching: true,
  };

export function indication(state = initialState, action) {
  switch (action.type) {
    case indicationConstants.FETCHING:
      return { 
            ...state,
            isFetching: true
        };
    case indicationConstants.SUCCESS:
      return { 
            ...state,
            isFetching: false,
            indications: action.indications
        };
    case indicationConstants.ERROR:
      return { 
            ...state,
            error: true
        };
    default:
      return state
  }
}