import { sliderConstants } from '../constants';

let sliders = JSON.parse(localStorage.getItem('sliders'));
const initialState = sliders ? 
  { 
      error: false,
      isFetching: false,
      sliders: sliders
  } : 
  { 
    error: false,
    isFetching: true,
  };

export function slider(state = initialState, action) {
  switch (action.type) {
    case sliderConstants.FETCHING:
      return { 
            ...state,
            isFetching: true
        };
    case sliderConstants.SUCCESS:
      return { 
            ...state,
            isFetching: false,
            sliders: action.sliders
        };
    case sliderConstants.PRINCIPAL_SUCCESS:
      return { 
            ...state,
            isFetching: false,
            principal: action.principal
        };
    case sliderConstants.SECONDARY_SUCCESS:
      return { 
            ...state,
            isFetching: false,
            secondary: action.secondary
        };
    case sliderConstants.ERROR:
      return { 
            ...state,
            error: true
        };
    default:
      return state
  }
}