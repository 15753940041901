import React from 'react'
import styled from 'styled-components'
import NavItemMobile from './NavItemMobile'
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import { devices } from '../../config'



const Nav = styled.nav`
    position: relative;
    display: flex;
    height: 100%;
    width: 15%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #3A3A3A;
`

const Menu = styled.ul`
    position: absolute;
    right: 0;
    top: 0;
    width: ${devices.mobile}px;
    opacity: ${(props) => props.open ? 1 : 0};
    height: 100vh;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
    display: ${(props) => props.open ? 'flex' : 'none'};;
    flex: auto;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    overflow: scroll;
    background: #F8F8F8;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
`

const FirstItem = styled.li`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50px;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    font-size: 2rem;
    font-weight: 500;
    line-height: 4;
    color: #707070;
    font-family: 'Lato';
`

function NavBarMobile({ items }) {
    const [open, setOpen] = React.useState(false)

    const handleMenu = () => {
        setOpen(!open);
      };

    return (
        <Nav>
            <MenuIcon style={{fontSize: '5rem'}} onClick={handleMenu} />
            <Menu open={open}>
                <FirstItem>
                    <CloseIcon style={{fontSize: '6rem', marginRight: '1.5rem'}} onClick={handleMenu} />
                </FirstItem>
                {
                    items.map((item, index) => (
                        (index > 0) &&
                        <NavItemMobile closeAction={handleMenu} key={index} to={item.link} name={item.name} icon={item.icon} menuItems={item.menuItems} />
                    ))
                }
            </Menu>
        </Nav>
    )

}

export default NavBarMobile;