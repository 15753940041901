import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

const Menu = styled.li`
    display: flex;
    flex: auto;
    justify-content: center;
    align-items: stretch;
    position: relative;
    height: 100%;
    &:hover {
        background: linear-gradient(270deg, #D5EE51 0%, #3DCCE9 100%);
        & > a {
            color: white;
            padding-bottom: 1.5vh;
            padding-left: 0.8vw;
            padding-right: 1.2vw;
        }
        & > a.active {
            color: white;
            padding-bottom: 0.5vh;
            padding-left: 0.8vw;
            padding-right: 1.2vw;
        }
        ul {
            max-height: 50vh;
            box-shadow: 1px 1px 10px 0px #888888;
        }
    }
    & > a {
        color: #3A3A3A;
        line-height: 2;
        text-decoration: none;
        font-family: 'Lato';
        font-weight: 400;
        display: flex;
        flex: auto;
        align-items: flex-end;
        justify-content: center;
        padding-bottom: 2vh;
        padding-left: 1vw;
        padding-right: 1vw;
        transition-property: all;
        transition-duration: 200ms;
        transition-timing-function: linear;
    }
    & a.active {
        padding-bottom: 1vh;
        color: #3DCCE9;
        border-bottom: solid 1vh;
        border-image: linear-gradient(270deg, #D5EE51 0%, #3DCCE9 100%);
        border-image-slice: 1;
        font-family: 'Lato';
        font-weight: 900;
        transition-property: all;
        transition-duration: 200ms;
        transition-timing-function: linear;
    }
`

const SubMenu = styled.ul`
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
    position: absolute;
    display: block;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    color: black;
    background-color: white;
    top: 100%;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
`
const SubMenuItem = styled.li`
    color: black;
    display: flex;
    flex: auto;
    justify-content: center;
    align-items: stretch;
    position: relative;
    font-size: 0.5rem;
    background-color: white;
    border-bottom:  solid #F8F8F8 0.1px;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
    &:last-child {
        border-bottom: 0;
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
    }
    &:hover {
        background: rgba(0, 0, 0, 0.1);
    }
    a {
        color: #3A3A3A;
        line-height: 5;
        text-decoration: none;
        font-family: 'Lato';
        font-weight: 400;
        display: flex;
        flex: auto;
        align-items: flex-end;
        justify-content: flex-start;
        padding-left: 1rem;
    }
`

function NavItem({ to, name, menuItems }) {
    return (
        <Menu>
            <NavLink to={to} exact > {name} </NavLink>
            {menuItems && 
                <SubMenu> 
                        {
                            menuItems.map((item, index) => (
                                <SubMenuItem key={index} >
                                    <NavLink to={item.link} exact > {item.name} </NavLink>
                                </SubMenuItem>
                            ))
                        }
                </SubMenu>
            }
        </Menu>
    )
}

export default NavItem;