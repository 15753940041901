import React from 'react'
import styled from 'styled-components'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { devices } from '../config'
import { connect } from 'react-redux';
import { alertActions } from '../redux/actions/alert.actions';
import { medicalServiceActions } from '../redux/actions/medical_service.actions';


const Root = styled.div`
    position: relative;
    display: flex;
    flex: auto;
    flex-direction: column;
    flex-flow: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 15%;
    padding-right: 15%;
    box-sizing: border-box;
`

const TitleSection = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 15%;
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    font-family: 'Ubuntu';
    font-size: 2.5rem;
    font-weight: 700;
    box-sizing: border-box;
    color: #3A3A3A;
    margin-bottom: 1rem;
    @media (max-width: ${devices.tablet}px) {
        font-size: 3rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        height: 10%;
    }
`
const Divider = styled.div`
    position: absolute;
    left: -2rem;
    bottom: 0;
    width: 5rem;
    height: 1vmin;
    background: linear-gradient(93deg, #29C4FF 0%, #D2EB51 100%);
`

const ServiceContainer = styled.div`
    position: relative;
    display: flex;
    height: 75%;
    width: 100%;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 1rem;
    box-sizing: border-box;
    @media (max-width: ${devices.tablet}px) {
        height: 70%;
    }
    @media (max-width: ${devices.mobileM}px) {
        height: 80%;
    }
    @media (min-width: ${devices.laptopL}px) {
        height: 70%;
    }
`

const Service = styled.ul`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 45%;
    height: max-content;
    letter-spacing: 1.2px;
    color: #3DCCE9;
    text-transform: uppercase;
    font-family: 'Ubuntu';
    font-size: 2.5vmin;
    font-weight: 500;
    box-sizing: border-box;
    margin: 0;
    padding-bottom: 1.5%;
    padding-top: 1.5%;
    margin-bottom: 0.5rem;
    border-bottom: ghostwhite solid;
    border-bottom-width: unset;
    &:last-child {
        border: none;
    }
    @media (max-width: ${devices.tablet}px) {
        font-size: 1rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
        font-size: 2rem;
    }
`

const Study = styled.li`
    position: relative;
    display: flex;
    width: 100%;
    color: #474747;
    text-transform: none;
    font-family: 'Ubuntu';
    font-size: 2vmin;
    font-weight: 400;
    line-height: 2;
    padding-left: 4rem;
    padding-bottom: 0.2rem;
    padding-top: 0.2rem;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    @media (max-width: ${devices.tablet}px) {
        font-size: 0.75rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1.5rem;
    }
`

const Link = styled.a`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    text-decoration: none;
    color: #3DCCE9;
    font-family: 'Ubuntu';
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.2rem;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    &:hover {
        font-weight: 900;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1.5rem;
    }
`

function MedicalServicesSection({ medicalServices, getMedicalServices, title, link }) {

    React.useEffect(() => {
        if(!medicalServices){
            getMedicalServices()
        }
    }, [getMedicalServices, medicalServices])

    return(
        <Root>
            <TitleSection>
                {title}
                <Divider />
            </TitleSection>
            {
                (!medicalServices) ? <h1>CARGANDO...</h1> : 
                <ServiceContainer>
                    {
                        medicalServices.map((service, index) => (
                            <Service key={`Service${index}`}>
                                <p style={{ marginBottom: '1rem'}}>{service.service}</p> 
                                {
                                    service.studies.map((study, index) => ( 
                                            <Study key={`Study${index}`}>
                                                {study}
                                            </Study>
                                    ))
                                }
                            </Service>
                        ))
                    }
                </ServiceContainer>
            }
            <Link href={link}>INDICACIONES PARA ESTUDIOS <ArrowForwardIosIcon style={{ display: 'flex', fontSize: '1rem', marginLeft: '0.5rem' }} /></Link>
        </Root>
    )
}

function mapState(state) {
    const { error, isFetching, medicalServices } = state.medicalService;
    return { error, isFetching, medicalServices };
  }
  
  const actionCreators = {
    getMedicalServices: medicalServiceActions.getMedicalServices,
    alertMessage: alertActions.error
  }
  
  export default connect(mapState, actionCreators)(MedicalServicesSection);

/* export default MedicalServicesSection */