import React from 'react'
import styled from 'styled-components'
import { devices } from '../config'

const Root = styled.div`
    position: relative;
    display: flex;
    flex:auto;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    text-align:left;
    opacity: 0.8;
    color: white;
    font-family: Ubuntu;
    background-image: url(${(props) => props.background || 'white'});
    background-position: center;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-size: cover;
`

const Title = styled.div`
    position: absolute;
    top: 0;
    display: flex;
    height: 70%;
    width: 100%;
    font-size: 37px;
    letter-spacing: 14px;
    align-items: center;
    font-weight: 700;
    padding-left: 15%;
    box-sizing: border-box;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, #054F75 0%, #09C3E1 100%);
        opacity: 0.85;
        z-index: -1;
    }
    @media (max-width: ${devices.tablet}px) {
        padding-left: 15%;
        font-size: 4rem;
        letter-spacing: 0.5rem;
    }
    @media (max-width: ${devices.mobile}px) {
        padding-left: 15%;
        font-size: 4rem;
        letter-spacing: 0.5rem;
    }
`

const SubTitle = styled.div`
    position: absolute;
    top: 70%;
    display: flex;
    height: 30%;
    width: 100%;
    font-size:25px;
    letter-spacing: 10px;
    align-items: center;
    font-weight: 400;
    padding-left: 15%;
    box-sizing: border-box;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(93deg, #00ACCF 0%, #87E8FC 100%);
        opacity: 0.85;
        z-index: -1;
    }
    @media (max-width: ${devices.tablet}px) {
        padding-left: 15%;
        font-size: 3rem;
        letter-spacing: 0.3rem;
    }
    @media (max-width: ${devices.mobile}px) {
        padding-left: 15%;
        font-size: 2rem;
        letter-spacing: 0.3rem;
    }
`

function TitlePage(props) {
    return(
            <Root background={props.background}>
                {props.title && <Title> {props.title} </Title>}
                <SubTitle> {props.subtitle} </SubTitle>
            </Root>
    )
}
export default TitlePage