import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import FooterBackGround from '../images/FooterBackGround.webp'
import { devices } from '../config'

const Root= styled(Grid)`
    background: transparent;
    opacity: 1;
    height:100%;
    color: white;
    padding-top: 2rem;
    font-family: Lato;
    letter-spacing: 0.96px;
    font-weight: 400;
    font-size:14px;
    display: flex;
    flex: auto;
    align-items: flex-start;
    box-sizing: border-box;
    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${FooterBackGround});
        background-position: center;
        background-attachment: fixed;
        background-repeat: no-repeat;
        z-index: -1;
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, #D5EE51 0%, #3DCCE9 100%);
        z-index: -1;
        opacity: 0.8;
    }
    @media (max-width: ${devices.mobileM}px) {
        justify-content: center;
        padding-top: 4rem;
    }
`
const HighGrid = styled(Grid)`
    height: 100%;
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
    }
`

const Locations = styled(HighGrid)`
    text-align: left;
    padding-top: 2.5rem;
    @media (max-width: ${devices.mobileM}px) {
        display: none!important;
    }
`
const DataLocation = styled(Grid)`
    display: flex;
    flex: auto;
    font-size: .85rem;
    padding-bottom: 0.3rem;
    @media (max-width: ${devices.mobileM}px) {
        display: none;
    }
`

const TitleLocation = styled(DataLocation)`
    font-weight: 700;
    font-size: 1rem;
    padding-bottom: 0.75rem;
    @media (max-width: ${devices.mobileM}px) {
        display: none;
    }
`

function FooterSection(props) {
    return(
        <Root container alignItems="center">
            <HighGrid item xs={8} sm={5} md={5} lg={5}>
                    <a href='/'> <img src={props.logo} style={{width: '65%', height: 'auto'}} alt="Logo DeMartino"  /></a>
            </HighGrid>
            <Locations container item xs={7} justify="flex-start" alignItems="flex-start">
                {props.subsidiaries.map((prop, index)=>(
                    <Grid key={index} container item xs={6} sm={5} md={4} lg={4} >
                        {prop.city && <TitleLocation item xs={12}>{prop.city}</TitleLocation>}
                        {prop.address && <DataLocation item xs={12}>{prop.address}</DataLocation>}
                        {prop.address2 && <DataLocation item xs={12}>{prop.address2}</DataLocation>}
                        {prop.phones && <DataLocation item xs={12}>{prop.phones}</DataLocation>}
                        {prop.rotativeLines&& <DataLocation item xs={12}>{prop.rotativeLines}</DataLocation>}
                        {prop.fax && <DataLocation item xs={12}>{prop.fax}</DataLocation>}
                        {prop.email && <DataLocation item xs={12}>{prop.email}</DataLocation>}
                    </Grid>
                ))}
            </Locations>
        </Root>
    )
}

export default FooterSection