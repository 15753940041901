import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import IconDireccion from '../../icons/inicio/direccion.svg'
import IconTelefono from '../../icons/inicio/telefono.svg'
import IconWhatsapp from '../../icons/inicio/whatsapp.svg'
import IconHorario from '../../icons/inicio/horario.svg'
import { devices } from "../../config";

const Container = styled(Grid)`
        padding: 0vw;
        font-family: 'Lato';
        font-size: 0.7vw;
        font-weight: 300;
        letter-spacing: 0.1vw;
        direction: row;
        justify-content: space-between;
        align-items: center;
`
const Text = styled.span`
    font-size: 0.70rem;
    font-family: 'Lato';
    font-weight: 300;
    letter-spacing: 0.015rem;
    color: #3A3A3A;
      @media (max-width: ${devices.tablet}px) {
        font-size: 0.80rem;
  }
`

const Icon = styled.img`
    width: 1.2rem;
    height: 1.2rem;
    margin-right: 0.4rem;
`

function SectionInfo({ data }) {

    return (
        <Container container item xs >
            <Grid container item xs alignItems='center'>
                <Icon src={IconDireccion} alt='Icon Dirección' /> <Text>{data.address}</Text>
            </Grid>
            <Grid container item xs alignItems='center' >
                <Icon src={IconTelefono} alt='Icon Teléfono' /> <Text>{data.phone}</Text>
            </Grid>
            <Grid container item xs alignItems='center' >
                <Icon src={IconWhatsapp} alt='Icon WhatsApp' /> <Text>{data.whatsapp}</Text>
            </Grid>
            <Grid container item xs={5} alignItems='center'>
                <Icon src={IconHorario} alt='Icon Horario' /> <Text>{data.workinghours}</Text>
            </Grid>
        </Container>
    )
}

export default SectionInfo;