import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux';
import TitleSection from './TitlePage'
import { devices } from '../config'
import { alertActions } from '../redux/actions/alert.actions';
import { sectionActions } from '../redux/actions/section.actions';
import { pageActions } from '../redux/actions/page.actions';
import { turnActions } from '../redux/actions/turn.actions';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { apiClinica } from "../config";

const Root = styled.div`
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  position: relative;
`
const Title = styled.section`
  height: 40%;
  width: 100%;
  display: flex;
  flex: auto;
  align-items: stretch;
  justify-content: center;
  `
const Container = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content:center;
height: 100%;
@media (max-width: ${devices.mobileM}px) {
    height: 100%;
}
`
const baseButton = styled.a`
  height: 2rem;
  margin: 0 0.25rem;
  border-radius: 24px;
  font: Bold 0.75rem Lato;
  letter-spacing: 1.35px;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-property: all;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  &:hover {
    background: linear-gradient(270deg, #d5ee51 0%, #3dcce9 100%);
  }
  @media (max-width: ${devices.tablet}px) {
    width: 20%;
    height: 4rem;
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom : 2rem;
  }
  @media (max-width: ${devices.mobileM}px) {
    width: 40%;
    height: 5rem;
    font-size: 1.5rem;
    margin-top: 2rem;
    margin-bottom : 2rem;
  }
`

const SecButton = styled(baseButton)`
  top: 2rem;
  left: 60rem;
  width: 9rem;
  height: 2rem;
  border: 1px solid #3dcce9;
  opacity: 1;
  color: #3dcce9;
  &:hover {
    color: white;
    border: 0;
  }
`

const ConfirmedCancelTurnTitleContainer = styled.div`
    position: relative;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align:left;
    width: 100%;
    height: 100%;
    color: #3DCCE9;
    box-sizing: border-box;
    @media (max-width: ${devices.tablet}px) {
        height: auto;
    }
`
const ConfirmedCancelTurnTitle = styled.div`
    position: relative;
    text-align: left;
    font-family: 'Ubuntu';
    font-weight: 900;
    font-size: 200%;
    width: 410px;
    margin: 30px 10% 20px 10%;
    @media (max-width: ${devices.tablet}px) {
      font-size: 3.5rem;
    }
    @media (max-width: ${devices.mobileM}px) {
      width: 100%;
      font-size: 4rem;
      padding: 0 3rem;
    }
`

const useStylesSpinner = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > * + *': {
            marginLeft: theme.spacing(2),
        },
    },
}));

function CancelTurnosPage({ match, error, isFetching, sections, getSections, alertMessage, pages, getPages }) {
    
    const [turnoCancelado, setTurnoCancelado] = React.useState(0);
    const classesSpinner = useStylesSpinner();
    const { idTurno, claveCancelacion } = match.params;

    React.useEffect( () => {
        window.scrollTo(0, 0)
        if (!sections) {
            getSections()
        }
        if (!pages) {
            getPages()
        }
        let data = {idTurno,claveCancelacion}
        axios
        .post(apiClinica.baseURL+'turno?updateMethod=cancelar',data,{headers:{'Authorization': 'Bearer ' + apiClinica.token, 'Content-Type' :'application/json'}})
            .then(response => {
                if (response.data.status === 'ok') {
                    setTurnoCancelado(1);
                }
                else {
                    setTurnoCancelado(2);
                }   
            })
            .catch(error => {
                console.log(error);
            })
    }, [sections, getSections, getPages, pages, claveCancelacion, idTurno])

    return (
        <Root>
            {
                (!sections || !pages) ? <h1>CARGANDO...</h1> :
                    <React.Fragment>
                        <Title>
                            <TitleSection title={'TURNOS'} background={sections[6].img} />
                        </Title>
                        {
                            turnoCancelado === 1 ?
                            <Container>
                                <ConfirmedCancelTurnTitleContainer>
                                    <ConfirmedCancelTurnTitle>Su turno ha sido cancelado exitosamente.</ConfirmedCancelTurnTitle>
                                    <SecButton target="_self" rel="noopener noreferrer" href="/">VOLVER AL INICIO</SecButton>
                                </ConfirmedCancelTurnTitleContainer>
                            </Container>
                            :
                            turnoCancelado === 2 ?
                            <Container>
                                <ConfirmedCancelTurnTitleContainer>
                                    <ConfirmedCancelTurnTitle>Ha ocurrido un error al cancelar el turno. Intentelo nuevamente</ConfirmedCancelTurnTitle>
                                    <SecButton target="_self" rel="noopener noreferrer" href="/">VOLVER AL INICIO</SecButton>
                                </ConfirmedCancelTurnTitleContainer>
                            </Container>
                            :
                            <div className={classesSpinner.root}>
                                            <CircularProgress />
                            </div> 
                        }
                    </React.Fragment>
            }
        </Root>
    )
}

function mapState(state) {
    const { error, isFetching, sections } = state.section;
    const { turnError, turnIsFetching, turns } = state.turn;
    const { pages } = state.page;
    return { error, isFetching, sections, pages, turns, turnError, turnIsFetching };
}

const actionCreators = {
    getSections: sectionActions.getSections,
    getTurns: turnActions.getTurns,
    getPages: pageActions.getPages,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(CancelTurnosPage);
