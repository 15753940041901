import { qualityPolicyConstants } from '../constants';

let qualityPolicies = JSON.parse(localStorage.getItem('qualityPolicies'));
const initialState = qualityPolicies ? 
  { 
      error: false,
      isFetching: false,
      qualityPolicies: qualityPolicies
  } : 
  { 
    error: false,
    isFetching: true,
  };

export function qualityPolicy(state = initialState, action) {
  switch (action.type) {
    case qualityPolicyConstants.FETCHING:
      return { 
            ...state,
            isFetching: true
        };
    case qualityPolicyConstants.SUCCESS:
      return { 
            ...state,
            isFetching: false,
            qualityPolicies: action.qualityPolicies
        };
    case qualityPolicyConstants.ERROR:
      return { 
            ...state,
            error: true
        };
    default:
      return state
  }
}