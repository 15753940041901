import React from 'react'
import styled from 'styled-components'
import { devices } from '../config'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import PasswordHelperText from '@material-ui/core/FormHelperText';
import axios from 'axios';
import IconDireccion from '../icons/inicio/direccion.svg'
import IconPractice from '../icons/inicio/icon_estudio.svg'
import IconSchedule from '../icons/inicio/icon_fecha.svg'
import { config } from "../config";

var CryptoJS = require("crypto-js");

const Root = styled.div`
position: relative;
display: flex;
flex-direction: column;
align-items: center;
height: auto;
width: 90%;
box-sizing: border-box;
justify-content: flex-start;
margin: 20px;
@media (max-width: ${devices.mobileM}px) {
    width: 90%;
}
`
const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 90%;
    @media (max-width: ${devices.mobileM}px) {
        flex-direction: inherit;
    }
`
const FormButton = styled.div`
    display:flex;
    height: 2rem;
    width: 13rem;
    margin: 20px 0;
    border-radius: 25px;
    border: solid 2px;
    border-color: #3dcce9;
    color: #3dcce9;
    font: Bold 1rem Lato;
    letter-spacing: 1.35px;
    align-items: center;
    justify-content: center;
    ${(props) => props.enabled ? '' : 'opacity: 0.5'};
    ${(props) => props.enabled ? 'cursor: pointer' : 'cursor: default'};
    &:hover {
      ${(props) => props.enabled ? 'background: linear-gradient(270deg, #d5ee51 0%, #3dcce9 100%)' : ''};
      
    }
    
    @media (max-width: ${devices.tablet}px) {
        width: 100%;
        height: 3.5rem;
        font-size: 1.5rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
        height: 40px;
        font-size: 2.5rem;
    }
`

const Icon = styled.img`
    width: 1.2rem;
    height: 1.2rem;
    margin-right:5px;
    @media (max-width: ${devices.mobileM}px) {
        width: 3rem;
        height: 3rem;
    }
`
const Text = styled.span`
    font-size: 18px;
    font-family: 'Lato';
    font-weight: 700;
    color: #000;
    opacity: 0.92;
    text-align: left;
    @media (max-width: ${devices.tablet}px) {
      font-size: 1.5rem; 
     }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 2.5rem;
    }

`
const StyledFormControl = styled(FormControl)`
position: relative;
display: flex;
flex-direction: column;
justify-content: space-around;
width: 45%;
box-sizing: border-box;
& input.MuiInputBase-input {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-family: Lato;
    color: #000;
    opacity: 0.92;
}
& label.MuiFormLabel-root {
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: Lato;
    color: #999;
}
& .MuiInput-underline:after{
    border-bottom: 2px solid #3DCCE9;
}
@media (max-width: ${devices.mobileM}px) {
    width: 95%;
    & label.MuiFormLabel-root {
        font-size: 2.75rem;
        line-height: 3.5rem;
    }
    & input.MuiInputBase-input {
        font-size: 3rem;
        line-height: 3.75rem;
    }
    & .MuiSvgIcon-root {
        font-size: 2.75rem;
    }
}
`
const TextTitle = styled(Text)`
    font-size:24px;
    color: #7A7A7A;
    font-weight: bold;
    font-family: 'Ubuntu';
    @media (max-width: ${devices.mobileM}px) {
        font-size: 3.5rem;
    }
`
const FieldContainer = styled.div`
    display: flex;  
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    margin: 10px 0;
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
        margin:10px 0 ;
        padding: 0 10px;
    }
`
const ForgotPasswordText = styled.a`
    font-size: 0.75rem;
    padding-top: 0.75rem;
    font-family: Lato;
    width: fit-content;
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1.5rem;
        padding-top: 1.5rem;
    }
`

const InputLabelPass = styled(InputLabel)`
    @media (max-width: ${devices.mobileM}px) {
        .MuiFormLabel-root {
            font-size: 2rem;
        }
    }
`

function TurnDetail(props) {

    const [passwordInput, setPasswordInput] = React.useState({
        password: '',
        showPassword: false,
        errors: false
    });

    const [passwordConsulted, setPasswordConsulted] = React.useState(false);
    const title = (props.showDetail) ? "Detalle" : "Cancelar turno";
    const textButton = (props.showDetail) ? "VER DETALLE" : "CANCELAR TURNO";
    const handleChangePassword = (prop) => (e) => {
        setPasswordInput({ ...passwordInput, [prop]: e.target.value, errors: false });
    };

    const handleClickShowPassword = () => {
        setPasswordInput({ ...passwordInput, showPassword: !passwordInput.showPassword });
    };

    const handleMouseDownPassword = (e) => {
        e.preventDefault();
    };

    const checkPassword = async (password) => {
        let data = {
            "idPaciente": props.turnData.pacientesID,
            "dni": props.turnData.dniPaciente,
            "password": password
        };

        // Encrypt
        var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), config.cryptoKey).toString();
        const response = await axios.post("/api/clinica/checkpassword", { data: ciphertext })
        if (response.data.status === 'SUCCESS') {
            return true;
        }
        else if (response.data.status === 'ERROR') {
            return false;
        }
    };

    const ConsultPassword = async (e) => {
        e.preventDefault();
        if(passwordInput.password !== ""){
            const checkPass = await checkPassword(passwordInput.password)
            setPasswordConsulted(true);
            
            if(checkPass || !config.isProduction){
                setPasswordInput({
                    ...passwordInput,
                    errors: false
                });
                if(!props.showDetail) {
                    props.handleCancelTurn(props.turnData);
                }
            }
            else{
                setPasswordInput({
                    ...passwordInput,
                    errors: true
                })
            }
        }
    };

    return(
      <Root>
            <Container>
                <TextTitle>{title}</TextTitle>
                {
                    ((!passwordConsulted) || (passwordInput.errors)) ?
                    <>
                        <StyledFormControl>
                            <InputLabelPass htmlFor="patient_password">Clave</InputLabelPass>
                            <Input
                                id="patient_password"
                                name="patientPassword"
                                type={passwordInput.showPassword ? 'text' : 'password'}
                                value={passwordInput.password}
                                error={passwordInput.errors}
                                onChange={handleChangePassword('password')}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}>
                                            {passwordInput.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {passwordInput.errors && passwordInput.password !== '' ? <PasswordHelperText id="password_error">Contraseña inválida</PasswordHelperText> : ''}
                            <ForgotPasswordText href="https://estudios.centrodm.com.ar/Vistas/resetp.php" target="_blank">¿Olvidó su contraseña?</ForgotPasswordText>
                            <FormButton onClick={ConsultPassword} enabled={passwordInput.password !== ""}>{textButton}</FormButton>
                        </StyledFormControl>
                    </>
                    :
                    <>{
                        (props.showDetail) &&
                        <>
                            <FieldContainer>
                                <Icon src={IconPractice}/> 
                                <Text>Práctica: {props.turnData.practicas} </Text>
                            </FieldContainer>
                            <FieldContainer>
                                <Icon src={IconPractice}/> 
                                <Text>Responsable: {props.turnData.agenda} </Text>
                            </FieldContainer>
                            <FieldContainer>
                                <Icon src={IconDireccion}/> 
                                <Text>Sucursal: {props.turnData.nombreSucursal} </Text>
                            </FieldContainer>
                            <FieldContainer>
                                <Icon src={IconDireccion}/> 
                                <Text>Dirección: {props.turnData.direccionSucursal} </Text>
                            </FieldContainer>
                            <FieldContainer>
                                <Icon src={IconSchedule}/> 
                                <Text>Fecha: {props.turnData.fecha} </Text>
                            </FieldContainer>
                            <FieldContainer>
                                <Icon src={IconSchedule}/> 
                                <Text>Hora: {props.turnData.hora} </Text>
                            </FieldContainer>
                        </>
                        }
                    </>
                }
            </Container>     
      </Root>
    )
}

export default TurnDetail;