import React from 'react'
import styled from 'styled-components'
import { devices } from '../config'

const Root = styled.div`
    position: relative;
    display: flex;
    flex: auto;
    padding-left: 10%;
    padding-right: 10%;
`

const LeftDiv = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 60%;
    height: 100%;
    align-items: center;
    justify-content: center;
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
    }
`

const RightDiv = styled.div`
    position: relative;
    display: flex;
    width: 40%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: ${devices.mobileM}px) {
        display: none;
    }
`

const TitleSection = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 35%;
    align-items: center;
    justify-content: center;
    text-align: left;
    font-family: 'Ubuntu';
    font-size: 2.5rem;
    font-weight: 700;
    padding-left: 5vw;
    padding-right: 5vw;
    box-sizing: border-box;
    color: #3A3A3A;
    @media (max-width: ${devices.tablet}px) {
        font-size: 3rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        height: 25%;
        font-size: 4rem;
    }
`

const Divider = styled.div`
    position: absolute;
    left: 2.5rem;
    bottom: 0;
    width: 5rem;
    height: 1vmin;
    background: linear-gradient(93deg, #29C4FF 0%, #D2EB51 100%);
    @media (max-width: ${devices.mobileM}px) {
        left: 1rem;
    }
`

const TextSection = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 65%;
    padding-left: 5vw;
    padding-right: 5vw;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    font-family: 'Lato';
    font-size: 1rem;
    font-weight: 300;
    text-align: left;
    color: #3A3A3A;
    line-height: 1.5;
    @media (max-width: ${devices.tablet}px) {
        font-size: 1.15rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        height: 75%;
        font-size: 2rem;
        line-height: 1.5;
    }
`

const Image = styled.img`
    height: auto;
    max-height: 100%;
    max-width: 100%;
    width: auto;
    border-radius: 5px;
`

function TwoColumnSection({ title, text, image, altImage }) {

    return(
      <Root>
          <LeftDiv>
            <TitleSection>
                {title}
                <Divider />
            </TitleSection>
            <TextSection dangerouslySetInnerHTML={{ __html: text }}>
            </TextSection>
          </LeftDiv>
          <RightDiv>
            <Image src={image} alt={altImage} />
          </RightDiv>
      </Root>
    )
}

export default TwoColumnSection