import React from 'react'
import { connect } from 'react-redux';
import { alertActions } from '../../redux/actions/alert.actions';
import { coverageActions } from '../../redux/actions/coverage.actions';
import styled from 'styled-components'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
`

const ModalContent = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 30%;
    height: 30%;
    align-items: center;
    justify-content: center;
    overflow: auto;
`

const ModalTitle = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 25%;
    color: white;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #3f51b5;
`

const ModalContentSup = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 75%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 50px;
    align-self: center;
`


const Field = styled(TextField)`
    width: 100%;
    margin-bottom: 25px!important;
`

function ModalUpdateCoverage({ rowData, updateCoverage, alertMessage, user, handleClose, open }) {

    const [ cov, setCov ] = React.useState( { ...rowData, updatedBy: user.id });
    const [ letter, setLetter] = React.useState(rowData.letter);   
    const [ errors, setErrors ] = React.useState({
        letter: false,
        name: false,
    })

    const handleCloseModal = () => {
        setErrors({
            letter: false,
            name: false,
        })
        setCov({})
        handleClose();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCov({
            ...cov,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
    }

    const handleChangeLetter = (event) => {
        setLetter(event.target.value);
        setCov({
            ...cov,
            letter: event.target.value
        })
        setErrors({
            ...errors,
            letter: false
        }) 
    };

    const handleForm = async (e) => {
        e.preventDefault()
        if(cov.letter && cov.name && cov.updatedBy){
            await updateCoverage(rowData.id, cov)
            handleCloseModal()            
        }else{
            setErrors({
                letter: !(cov.letter),
                name: !(cov.name),
            })
        }
    }


    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <ModalContent>
                    <ModalTitle>
                        CREAR COBERTURA
                        <Button variant="contained" onClick={handleForm}>GUARDAR</Button>
                    </ModalTitle>
                    <ModalContentSup>
                        <FormControl style={{ width: '20%'}}>
                            <InputLabel error={errors.letter} id="letter" required>Letra</InputLabel>
                            <Select
                                labelId="letter"
                                id="letter-select"
                                value={letter}
                                onChange={handleChangeLetter}
                                error={errors.letter}
                            >
                                {
                                    ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'Ñ', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'].map((lett) => (
                                        <MenuItem value={lett}> {lett} </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <Field id="name" name="name" label="Cobertura" variant="outlined" size='small' error={errors.name} value={cov.name} onChange={handleChange} required />
                    </ModalContentSup>
                </ModalContent>
            </Fade>
        </ModalStyled>
    )
}

function mapState(state) {
    const { user } = state.authentication;
    return { user };
  }
  
const actionCreators = {
    updateCoverage: coverageActions.updateCoverage,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(ModalUpdateCoverage);