import { recommendationConstants } from '../constants';

let recommendations = JSON.parse(localStorage.getItem('recommendations'));
const initialState = recommendations ? 
  { 
      error: false,
      isFetching: false,
      recommendations: recommendations
  } : 
  { 
    error: false,
    isFetching: true,
  };

export function recommendation(state = initialState, action) {
  switch (action.type) {
    case recommendationConstants.FETCHING:
      return { 
            ...state,
            isFetching: true
        };
    case recommendationConstants.SUCCESS:
      return { 
            ...state,
            isFetching: false,
            recommendations: action.recommendations
        };
    case recommendationConstants.ERROR:
      return { 
            ...state,
            error: true
        };
    default:
      return state
  }
}