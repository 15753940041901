import React from 'react'
import styled from 'styled-components'
import SimpleExpansionPanel from './SimpleExpansionPanel'
import { questionsList } from '../dataEntry'
import { devices } from '../config'

const Root= styled.div`
    height:100%;
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
    box-sizing: border-box;
    font-family: 'Lato';
    letter-spacing: 0.96px;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    flex: auto;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    
    p {
      text-align: left;
    }

    #panel1a-header > div.MuiExpansionPanelSummary-content > p {
      font-size: 1rem;
    }

    #panel1a-header > div.MuiExpansionPanelSummary-content.Mui-expanded > p {
        font-size: 1rem;
        color: #53E2FF;
        font-weight: 900;
      }

    #panel1a-content > div > p {
      font-size: 0.75rem;
    }
    @media (max-width: ${devices.tablet}px) {

      #panel1a-header > div.MuiExpansionPanelSummary-content > p {
        font-size: 1.5rem;
      }

      #panel1a-header > div.MuiExpansionPanelSummary-content.Mui-expanded > p {
        font-size: 1.5rem;
        color: #53E2FF;
      }

      #panel1a-content > div > p {
        font-size: 1rem;
      }
      
    }
    @media (max-width: ${devices.mobileM}px) {
     
      #panel1a-header > div.MuiExpansionPanelSummary-content > p {
        font-size: 2.5rem;
      }

      #panel1a-header > div.MuiExpansionPanelSummary-content.Mui-expanded > p {
        font-size: 2.5rem;
        color: #53E2FF;
      }

      #panel1a-content > div > p {
        font-size: 2rem;
      }
    }
`

const Locations = styled.div`
    display: flex;
    flex: auto;
    width: 33%;
    margin-top: 2rem;
    margin-bottom: 2rem;
    justify-content: center;
    @media (max-width: ${devices.tablet}px) {
      width: 50%;
    }
    @media (max-width: ${devices.mobileM}px) {
      width: 100%;
    }
`

function PreguntasFrecuentesSection(props) {
    return(
        <Root>
            {
                questionsList.map((item, index) => (
                    <Locations key={`Item ${index}`} container item xs={4} justify="flex-start" alignItems="flex-start">
                        <SimpleExpansionPanel question={item.question} answer={item.answer}/>
                    </Locations>
                ))
            }
        </Root>
    )
}

export default PreguntasFrecuentesSection