import React from 'react'
import { connect } from 'react-redux';
import { alertActions } from '../../redux/actions/alert.actions';
import styled from 'styled-components'

const Root = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
`

function ConfigAdmin(props) {

    return(
      <Root>
          CONFIG ADMIN
      </Root>
    )
}

function mapState(state) {
    const { user } = state.authentication;
    return { user };
  }
  
const actionCreators = {
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(ConfigAdmin);