import { medicalServiceConstants } from '../constants';
import { alertActions } from './alert.actions';
import axios from 'axios';
import  { authHeader } from '../auth-header';

export const medicalServiceActions = {
    createMedicalService,
    updateMedicalService,
    getMedicalServices,
    deleteMedicalService,
    getAllMedicalServices
};

function createMedicalService(medicalService) {
    return dispatch => {
        axios
        .post( '/api/medicalservices' , medicalService)
        .then(response => {
            dispatch(alertActions.success('Servicio Médico creado'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function updateMedicalService(id, medicalService) {
    return dispatch => {
        axios
        .put( '/api/medicalservices/' + id, medicalService)
        .then(response => {
            dispatch(alertActions.success('Servicio Médico actualizada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}


function getMedicalServices() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
      }
    return dispatch => {
        dispatch({ type: medicalServiceConstants.FETCHING });
        axios.get('/api/medicalservices',config)
        .then(medicalServicesData => {
            const medicalServices = medicalServicesData.data.medicalServices
            dispatch({ type: medicalServiceConstants.SUCCESS, medicalServices });
        })
        .catch(err => {
            dispatch({ type: medicalServiceConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function getAllMedicalServices() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
      }
    return dispatch => {
        dispatch({ type: medicalServiceConstants.FETCHING_ADMIN });
        axios.get('/api/medicalservices/all',config)
        .then(medicalServicesData => {
            const medicalServices = medicalServicesData.data.medicalServices
            dispatch({ type: medicalServiceConstants.SUCCESS_ADMIN, medicalServices });
        })
        .catch(err => {
            dispatch({ type: medicalServiceConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function deleteMedicalService(id) {
    return dispatch => {
        axios
        .delete( '/api/medicalservices/' + id)
        .then(response => {
            dispatch(alertActions.success('Servicio Médico eliminado'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}