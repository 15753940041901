import { sectionConstants } from '../constants';
import { alertActions } from './alert.actions';
import axios from 'axios';
import  { authHeader } from '../auth-header';

export const sectionActions = {
    createSection,
    updateSection,
    getSections,
    deleteSection
};

function createSection(section) {
    return dispatch => {
        axios
        .post( '/api/sections' , section)
        .then(response => {
            dispatch(alertActions.success('Sección creada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function updateSection(id, section) {
    return dispatch => {
        axios
        .put( '/api/sections/' + id, section)
        .then(response => {
            dispatch(alertActions.success('Sección actualizada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}


function getSections() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
      }
    return dispatch => {
        dispatch({ type: sectionConstants.FETCHING });
        axios.get('/api/sections',config)
        .then(sectionsData => {
            const sections = sectionsData.data.sections
            dispatch({ type: sectionConstants.SUCCESS, sections });
        })
        .catch(err => {
            dispatch({ type: sectionConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function deleteSection(id) {
    return dispatch => {
        axios
        .delete( '/api/sections/' + id)
        .then(response => {
            dispatch(alertActions.success('Sección eliminada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}