import React from "react";
import styled from 'styled-components';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from 'ckeditor5-build-full';


const Root = styled.div`
  width: 100%;
  height: auto;
  max-height: 100%;
  .ck-editor__editable {
    max-height: ${(props) => props.maxContentHeight ? props.maxContentHeight : '200px'};
    min-height: ${(props) => props.minContentHeight ? props.minContentHeight : '200px'};
}
`

function BasicEditor({ onChange, name, value, minContentHeight, maxContentHeight, placeholder, readOnly }) {

    const editorConfig = {
        toolbar: {
            items: ['bold', 'italic', 'fontColor', 'fontSize', '|', 'alignment', 'bulletedList', 'numberedList', '|', 'link', 'blockQuote', 'undo', 'redo', 'removeFormat' ]
        },
        placeholder: placeholder ? placeholder : "Ingrese un texto...",
        isReadOnly: true,
        disabled:true
    }

  return (
    <Root minContentHeight={minContentHeight} maxContentHeight={maxContentHeight} >
      <CKEditor
          type=""
          name={name}
          editor={ClassicEditor}
          data={value}
          config={editorConfig}
          onChange={(event, editor) => {
            const data = editor.getData();
            onChange(event, data);
          }}
          
        />
    </Root>
  );
}

export default BasicEditor;