import { recommendationConstants } from '../constants';
import { alertActions } from './alert.actions';
import axios from 'axios';
import  { authHeader } from '../auth-header';

export const recommendationActions = {
    createRecommendation,
    updateRecommendation,
    getRecommendations,
    deleteRecommendation
};

function createRecommendation(recommendation) {
    return dispatch => {
        axios
        .post( '/api/recommendations' , recommendation)
        .then(response => {
            dispatch(alertActions.success('Recomendación creada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function updateRecommendation(id, recommendation) {
    return dispatch => {
        axios
        .put( '/api/recommendations/' + id, recommendation)
        .then(response => {
            dispatch(alertActions.success('Recomendación actualizada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}


function getRecommendations() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
      }
    return dispatch => {
        dispatch({ type: recommendationConstants.FETCHING });
        axios.get('/api/recommendations',config)
        .then(recommendationsData => {
            const recommendations = recommendationsData.data.recommendations
            dispatch({ type: recommendationConstants.SUCCESS, recommendations });
        })
        .catch(err => {
            dispatch({ type: recommendationConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function deleteRecommendation(id) {
    return dispatch => {
        axios
        .delete( '/api/recommendations/' + id)
        .then(response => {
            dispatch(alertActions.success('Recomendación eliminada'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}