import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import IconForward from '../icons/inicio/b_flecha_adelante.svg'
import { devices } from '../config'

const CardLink = styled(Link)`
    text-decoration: none;
    width: 33%;
    height: 87.5%;
    background: ${(props) => props.background || 'white'};
    border-radius: ${(props) => props.borderradius || 0};
    position: relative;
    top: 3%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    color: white;
    z-index: 50;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    &:hover {
        height: 100%;
        top: 5%;
        border-radius: 10px 10px 10px 10px;
        background: linear-gradient(0deg, #D5EE51 0%, #3DCCE9 100%);
    }
    @media (max-width: ${devices.mobileM}px) {
        width: 90%;
        height: 27.5%;
        &:hover {
            height: 27.5%;
            top: 3%;
            background: ${(props) => props.background || 'white'};
            border-radius: ${(props) => props.borderradius || 0};
        }
    }
`
const CardLinkB = styled.div`
    text-decoration: none;
    width: 33%;
    height: 87.5%;
    background: ${(props) => props.background || 'white'};
    border-radius: ${(props) => props.borderradius || 0};
    position: relative;
    top: 3%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    color: white;
    z-index: 50;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    &:hover {
        height: 100%;
        top: 5%;
        border-radius: 10px 10px 10px 10px;
        background: linear-gradient(0deg, #D5EE51 0%, #3DCCE9 100%);
    }
    @media (max-width: ${devices.mobileM}px) {
        width: 90%;
        height: 27.5%;
        &:hover {
            height: 27.5%;
            top: 3%;
            background: ${(props) => props.background || 'white'};
            border-radius: ${(props) => props.borderradius || 0};
        }
    }
`

const CardLinkA = styled.a`
    text-decoration: none;
    width: 33%;
    height: 87.5%;
    background: ${(props) => props.background || 'white'};
    border-radius: ${(props) => props.borderradius || 0};
    position: relative;
    top: 3%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    color: white;
    z-index: 50;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    &:hover {
        height: 100%;
        top: 5%;
        border-radius: 10px 10px 10px 10px;
        background: linear-gradient(0deg, #D5EE51 0%, #3DCCE9 100%);
    }
    @media (max-width: ${devices.mobileM}px) {
        width: 90%;
        height: 27.5%;
        &:hover {
            height: 27.5%;
            top: 3%;
            background: ${(props) => props.background || 'white'};
            border-radius: ${(props) => props.borderradius || 0};
        }
    }
`

const IconService = styled.img`
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 5rem;
    margin-top: 30%;
    margin-bottom: 2.5%;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    ${CardLink}:hover &{
        margin-top: 15%;
    }
    ${CardLinkA}:hover &{
        margin-top: 15%;
    }
    ${CardLinkB}:hover &{
        margin-top: 15%;
    }
    @media (max-width: ${devices.mobileM}px) {
        position: absolute;
        height: 8rem;
        margin-top: 0;
        margin-bottom: 0;
        top: 35%;
        left: 20%;
        ${CardLink}:hover &{
            margin-top: 0;
        }
        ${CardLinkA}:hover &{
            margin-top: 0;
        }
        ${CardLinkB}:hover &{
            margin-top: 0;
        }
    }
`

const Title = styled.h2`
    position: relative;
    display: flex;
    padding-left: 4rem;
    padding-right: 4rem;
    font-family: 'Gotham-Black';
    font-size: 1.25rem;
    letter-spacing: .2rem;
    margin-top: 1rem;
    @media (max-width: ${devices.mobileM}px) {
        font-size: 2.25rem;
        text-align: left;
        letter-spacing: .1rem;
        padding-left: 0;
        padding-right: 2rem;
        position: absolute;
        top: 37.5%;
        left: 45%;
    }
`

const Divider = styled.div`
    position: relative;
    display: table;
    width: 4vw;
    height: .5vh;
    background-color: white;
    visibility: visible;
    opacity:1;
    margin-top: 1rem;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    ${CardLink}:hover &{
        visibility: hidden;
        opacity:0;
    }
    ${CardLinkA}:hover &{
        visibility: hidden;
        opacity:0;
    }
    ${CardLinkB}:hover &{
        visibility: hidden;
        opacity:0;
    }
    @media (max-width: ${devices.mobileM}px) {
        visibility: visible;
        opacity:1;
        position: absolute;
        top: 65%;
        left: 45%;
        ${CardLink}:hover &{
            visibility: visible;
            opacity:1;
        }
        ${CardLinkA}:hover &{
            visibility: visible;
            opacity:1;
        }
        ${CardLinkB}:hover &{
            visibility: visible;
            opacity:1;
        }
    }
`

const Text = styled.p`
    position: relative;
    font-family: 'Lato';
    font-size: 1.25rem;
    letter-spacing: .05rem;
    padding-left: 4rem;
    padding-right: 4rem;
    visibility: hidden;
    opacity:0;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    ${CardLink}:hover &{
        visibility: visible;
        opacity:1;
    }
    ${CardLinkA}:hover &{
        visibility: visible;
        opacity:1;
    }
    ${CardLinkB}:hover &{
        visibility: visible;
        opacity:1;
    }
    @media (max-width: ${devices.tablet}px) {
        font-size: 1.5rem;
        padding-left: .5rem;
        padding-right: .5rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        display: none;
    }
`

const Arrow = styled.img`
    position: absolute;
    bottom: 5vmin;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 8vmin;
    visibility: hidden;
    opacity:0;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    ${CardLink}:hover &{
        visibility: visible;
        opacity:1;
    }
    ${CardLinkA}:hover &{
        visibility: visible;
        opacity:1;
    }
    ${CardLinkB}:hover &{
        visibility: visible;
        opacity:1;
    }
    @media (max-width: ${devices.tablet}px) {
        height: 4rem;
        bottom: 2rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        display: none;
    }
`

function CardService({ to, background, borderRadius, icon, title, text, link, action }) {

    return(
        <React.Fragment>
            {
                link ?
                <CardLinkA target="_blank" rel="noopener noreferrer" href={link} background={background} borderradius={borderRadius}>
                    <IconService src={icon} alt='Icon service' />
                    <Title>{title}</Title>
                    <Divider />
                    <Text>{text}</Text>
                    <Arrow src={IconForward} alt='Icon forward' />
                </CardLinkA>
                : to ?
                <CardLink to={to} background={background} borderradius={borderRadius} onClick={action}>
                    <IconService src={icon} alt='Icon service' />
                    <Title>{title}</Title>
                    <Divider />
                    <Text>{text}</Text>
                    <Arrow src={IconForward} alt='Icon forward' />
                </CardLink>
                :
                <CardLinkB background={background} borderradius={borderRadius} onClick={action}>
                    <IconService src={icon} alt='Icon service' />
                    <Title>{title}</Title>
                    <Divider />
                    <Text>{text}</Text>
                    <Arrow src={IconForward} alt='Icon forward' />
                </CardLinkB>
            }
        </React.Fragment>
    )
}

export default CardService