import React from 'react'
import styled from 'styled-components'
import { devices } from '../config'

const months = ["ENERO", "FEBRERO", "MARZO", "ABRIL", "MAYO", "JUNIO", "JULIO", "AGOSTO", "SEPTIEMBRE", "OCTUBRE", "NOVIEMBRE", "DICIEMBRE"];

const Root = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
`

const Image = styled.div`
    width: 90%;
    height: 400px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    background-image: url(${(props) => props.background || 'white'});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 3rem;
    @media (max-width: ${devices.tablet}px) {
        height: 300px;
    }
    @media (max-width: ${devices.mobileM}px) {
        height: 200px;
    }
`

const VideoContainer = styled.div`
    width: 90%;
    height: 400px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 5px;
    background-image: url(${(props) => props.background || 'white'});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 3rem;
    @media (max-width: ${devices.tablet}px) {
        height: 300px;
    }
    @media (max-width: ${devices.mobileM}px) {
        height: 200px;
    }
`

const Tag = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8rem;
    height: 2rem;
    color: #3DCCE9;
    font-family: 'Lato';
    font-size: 1rem;
    border: 1px solid #3DCCE9;
    border-radius: 5px;
    box-shadow: 0px 3px 6px #00000029;
    background: white;
    margin-bottom: 2rem;
`

const Title = styled.div`
    position: relative;
    display: flex;
    width: 90%;
    text-align: left;
    font-size: 2.75rem;
    font-family: 'Lato';
    font-weight: 700;
    letter-spacing: 0;
    color: #3A3A3A;
    margin-bottom: 1.5rem;
`

const DateNews = styled.div`
    position: relative;
    display: flex;
    width: 50%;
    text-align: left;
    font-size: 0.75rem;
    font-family: 'Lato';
    letter-spacing: 0.1rem;
    color: #7A7A7A;
    margin-bottom: 1.5rem;
`

const Divider = styled.div`
    position: relative;
    display: flex;
    width: 4rem;
    height: 8px;
    background: linear-gradient(93deg, #29C4FF 0%, #D2EB51 100%);
    margin-bottom: 1.5rem;
`

const ResumenText = styled.div`
    position: relative;
    display: flex;
    width: 90%;
    text-align: left;
    letter-spacing: 0;
    font-size: 1rem;
    font-family: 'Lato';
    font-weight: 700;
    letter-spacing: 0.05rem;
    line-height: 2;
    color: #3A3A3A;
    margin-bottom: 1.5rem;
`

const BodyText = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    text-align: left;
    letter-spacing: 0;
    font-size: 0.8rem;
    font-family: 'Lato';
    font-weight: 400;
    letter-spacing: 0.05rem;
    line-height: 2;
    color: #000000;
    p {
        margin-bottom: 1.5rem;
    }
`

function News({ news }) {
    return(
      <Root>
            {
                news.video ? 
                <VideoContainer>
                    <iframe
                        width="100%"
                        height="100%"
                        src={`https://www.youtube.com/embed/${news.video}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title={news.title}
                        />
                </VideoContainer>
                :
                <Image background={news.img} />
            }
          <Tag>
            {news.tag}
          </Tag>
          <Title>
            {news.title}
          </Title>
          <DateNews>
            {new Date(news.date).getDate()} DE {months[new Date(news.date).getMonth()]} DE {new Date(news.date).getFullYear()}
          </DateNews>
          <Divider />
          <ResumenText>
            {news.copete}
          </ResumenText>
          <BodyText dangerouslySetInnerHTML={{ __html: news.text }} />
      </Root>
    )
}

export default News