import { sliderConstants } from '../constants';
import { alertActions } from './alert.actions';
import axios from 'axios';
import  { authHeader } from '../auth-header';

export const sliderActions = {
    createSlider,
    updateSlider,
    getSliders,
    deleteSlider,
    getKeySliders
};

function createSlider(slider) {
    return dispatch => {
        axios
        .post( '/api/sliders' , slider)
        .then(response => {
            dispatch(alertActions.success('Slider creado'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}

function updateSlider(id, slider) {
    return dispatch => {
        axios
        .put( '/api/sliders/' + id, slider)
        .then(response => {
            dispatch(alertActions.success('Slider actualizado'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}


function getSliders() {
    let config = {
        headers: { ...authHeader(), 'Content-Type': 'application/json' }
      }
    return dispatch => {
        dispatch({ type: sliderConstants.FETCHING });
        axios.get('/api/sliders/all',config)
        .then(slidersData => {
            const sliders = slidersData.data.sliders
            dispatch({ type: sliderConstants.SUCCESS, sliders });
        })
        .catch(err => {
            dispatch({ type: sliderConstants.ERROR });
            dispatch(alertActions.error('Hubo un error...'));
        })
        
    }
}

function getKeySliders(t) {
    return dispatch => {
        if (t === 'principal'){
            axios.get('/api/sliders/key/principal')
            .then(principalData => {
                const principal = principalData.data.sliders
                dispatch({ type: sliderConstants.PRINCIPAL_SUCCESS, principal });
            })
            .catch(err => {
                dispatch({ type: sliderConstants.ERROR });
                dispatch(alertActions.error('Hubo un error...'));
            })
        } else if(t === 'secondary'){
            axios.get('/api/sliders/key/secondary')
            .then(secondaryData => {
                const secondary = secondaryData.data.sliders
                dispatch({ type: sliderConstants.SECONDARY_SUCCESS, secondary });
            })
            .catch(err => {
                dispatch({ type: sliderConstants.ERROR });
                dispatch(alertActions.error('Hubo un error...'));
            })
        }
    }
}

function deleteSlider(id) {
    return dispatch => {
        axios
        .delete( '/api/sliders/' + id)
        .then(response => {
            dispatch(alertActions.success('Slider eliminado'));
        })
        .catch(error => {
            dispatch(alertActions.error('Hubo un error...'));
        })
    };
}