import React, { useState, useEffect } from 'react'
import styled, {keyframes} from 'styled-components'
import { ArrowForwardIos } from '@material-ui/icons';
import { Swipeable } from 'react-touch'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { devices } from '../../config'
import { connect } from 'react-redux';
import { alertActions } from '../../redux/actions/alert.actions';
import { bannerActions } from '../../redux/actions/banner.actions';
import VideoPortada from '../../images/videoPortada.mp4';

const ShowImg = keyframes`
    0% {
        z-index: 1;
        transform: scale(0.8);
        opacity: 0;
    }

    100% {
        z-index: 1;
        transform: scale(1);
        opacity: 1;
    }
`

const ShowInfo = keyframes`
    0% {
        z-index: 30;
        transform: translate(0, -100px);
        opacity: 0;
    }

    100% {
        z-index: 30;
        transform: translate(0, 0);
        opacity: 1;
    }
`

const Hide = keyframes`
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
    }
`

const Slide = styled.div`
    width: 100%;
    height: 100%;
    background: white;
    color: white;
`
const SlideContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    position: absolute;
    top: 0;
    justify-content: center;
    display: flex;
    align-items: center;
`
const Img = styled.img`
    /* object-fit: cover; */
    height: 100%;
    width: auto;
    position: absolute;
    display: flex;
    vertical-align: middle;
    &.animaShow {
        animation: 650ms ${ShowImg} ease-out;
        animation-fill-mode: forwards;
    }
    &.animaHide {
        animation: 650ms ${Hide} ease-out;
        animation-fill-mode: forwards;
    }
`

const ZoneBackground = styled.div`
    z-index: 20;
    width: 100%;
    height:100%;
    background: black;
    opacity: 0.05;
    text-shadow: 1px 1px black;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
`
const InfoZone = styled.div`
    position: absolute;
    width: 40.625%;
    height: 40%;
    justify-content: center;
    z-index: 30;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: ${devices.mobileM}px) {
        width: 80%;
        height: 40%;
    }
`

const Title = styled.div`
    position: relative;
    justify-content: center;
    font-size: 3.6vw;
    font-family: 'Ubuntu';
    font-weight: 700;
    color: white;
    opacity: 0;
    &.animaShow {
        animation: 650ms ${ShowInfo} ease-out;
        animation-fill-mode: forwards;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 4rem;
    }
`

const Button = styled.button`
    position: relative;
    top: 5vmin;
    font-size: 1rem;
    font-family: 'Lato';
    font-weight: 400;
    letter-spacing: 0.2vw;
    color: white;
    border: 2px solid #3DCCE9;
    box-sizing: border-box;
    border-radius: 32px;
    background: transparent;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    width: 15rem;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 32px;
        background: linear-gradient(90deg, #D5EE51 0%, #3DCCE9 100%);
        transition-property: all;
        transition-duration: 300ms;
        transition-timing-function: ease-in-out;
        z-index: -1;
        opacity: 0;
    }
    &:hover {
        border: 0;
        &::before {
            opacity: 1;
        }
    }
    a {
        text-decoration: none;
        color: white;
    }
    @media (max-width: ${devices.mobileM}px) {
        width: 20rem;
        height: 5rem;
        font-size: 1.5rem;
    }
`

const Dot = styled.button`
    z-index: 50;
    width: 2vmin;
    height: 2vmin;
    margin: 1vmin;
    border-radius: 50%;
    border: solid 0.3vmin;
    border-color: white;
    background-color: transparent;
    color: transparent;
    position: relative;
    top: 30vmin;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
    &.active {
        background-color: white;
    }
`

const StyledVideo = styled.video`
    object-fit: cover;
    object-position: center;
`



const Carousel = ({ principal, getKeyBanners, interval }) => {

    const [ activeIndex, setActiveIndex ] = useState(0)

    const matches = useMediaQuery(`(max-width:${(devices.tablet)}px)`);

    useEffect(() => {
        if(!principal){
            getKeyBanners('principal')
        } else {
            const tick = setInterval(() => {
                if(activeIndex < principal.length -1){
                    setActiveIndex(activeIndex + 1)
                } else {
                    setActiveIndex(0)
                }
            }, interval)
    
            return () => clearInterval(tick)
        }
    }, [ activeIndex, principal, interval, getKeyBanners ])

    const handleClick = (e) => {
        e.preventDefault()
        setActiveIndex(parseInt(e.target.value))
    }

    const handleTouchLeft = () => {
        if(activeIndex < principal.length -1){
            setActiveIndex(activeIndex + 1)
        } else {
            setActiveIndex(0)
        }
    }

    const handleTouchRight = () => {
        if(activeIndex > 0){
            setActiveIndex(activeIndex - 1)
        } else {
            setActiveIndex(principal.length -1)
        }
    }

    return (
        <Slide>
            <StyledVideo loop autoPlay src={VideoPortada} muted width="100%" height="100%" />
        </Slide>
        
    )
}

function mapState(state) {
    const { error, isFetching, principal } = state.banner;
    return { error, isFetching, principal };
  }
  
const actionCreators = {
    getKeyBanners: bannerActions.getKeyBanners,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(Carousel);

/* export default Carousel */