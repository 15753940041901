import { userConstants } from '../constants';
import { alertActions } from './alert.actions';
import { history } from '../history';
import axios from 'axios';

export const userActions = {
    register,
    login,
    logout
};

function register(user) {
    return dispatch => {
        dispatch(request(user));

        axios
        .post( '/api/users/register' , {
          first_name: user.firstName,
          last_name: user.lastName,
          email: user.email,
          username: user.username,
          password: user.password,
          cod_rol: 1, // Luego cambiar esto....
        })
        .then(response => {
            dispatch(success(user));
            history.push('/admin');
            dispatch(alertActions.success('Registration successful'));
        })
        .catch(error => {
            /* dispatch(failure(error.response.data.message)); */
            dispatch(alertActions.error('Huvo un error...'));
        })
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    /* function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } } */
}

function login(username, password) {
    return dispatch => {
        dispatch(request());

        axios
        .post( '/api/users/login', {
        username: username,
        password: password
        })
        .then(user => {
            localStorage.setItem('user', JSON.stringify(user.data));
            dispatch(success(user.data));
            history.push('/admin');
        })
        .catch(err => {
            dispatch(failure(err.response.data.message));
            dispatch(alertActions.error(err.response.data.message));
        })
    };

    function request() { return { type: userConstants.LOGIN_REQUEST } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout(exitFlag = true) {
    return dispatch => {
        localStorage.removeItem('user');
        if(exitFlag){
            history.push('/admin/login');
        }
        dispatch({ type: userConstants.LOGOUT });
    }
    
}