import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import Arrow from '@material-ui/icons/ArrowForwardIos';



const MenuIcon = styled.img`
    position: relative;
    display: flex;
    height: 3rem;
    width: 3rem;
    margin-left: 5rem;
    margin-right: 2rem;
    filter: invert(.6);
`
const ArrowIcon = styled(Arrow)`
    position: relative;
    color: #707070;
    transform: rotate(90deg);
`

const SubmenuArrowIcon = styled(Arrow)`
    position: absolute;
    right: 5rem;
    color: #707070; 
`

const ContentArrow = styled.div`
    position: absolute;
    display: ${(props) => props.enable ? 'flex' : 'none'};
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    height: 8rem;
    width: 15rem;
`

const Menu = styled.li`
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    position: relative;
    font-size: 2rem;
    font-weight: 500;
    line-height: 4;
    a {
        color: #707070;
        width: 100%;
        text-decoration: none;
        font-family: 'Lato';
        display: flex;
        align-items: center;
    }
    a.active {
        color: white;
        background: linear-gradient(270deg, #D5EE51 0%, #3DCCE9 100%);
        ${MenuIcon} {
            filter: none;
        }
        ${ArrowIcon} {
            color: white;
            transform: ${(props) => props.open ? 'rotate(270deg)' : 'rotate(0deg)'}; ;
        }
    }
`

const SubMenu = styled.ul`
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
    position: relative;
    display: ${(props) => props.open ? 'flex' : 'none'};
    flex-direction: column;
    width: 100%;
`
const SubMenuItem = styled.li`
    display: flex;
    flex: auto;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    background-color: #FFFFFF;
    a {
        color: #707070;
        width: 100%;
        text-decoration: none;
        font-family: 'Lato';
        font-size: 1.75rem;
        display: flex;
        align-items: center;
        font-weight: 300;
        padding-left: 5rem;
    }
`


const LevelOne = styled(NavLink)`
    background-color: #F8F8F8;
`

function NavItem({ closeAction, to, name, icon, menuItems }) {

    const [open, setOpen] = React.useState(false)

    const handleArrow = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setOpen(!open);
    }

    return (
        <Menu open={open}>
            <LevelOne onClick={closeAction} to={to} exact > 
                <MenuIcon src={icon} alt={`Icon ${name}`} /> 
                {name}  
                <ContentArrow enable={menuItems} onClick={handleArrow}> <ArrowIcon style={{fontSize: '3rem'}} />  </ContentArrow> 
            </LevelOne>
            {menuItems && 
                <SubMenu open={open}> 
                        {
                            menuItems.map((item, index) => (
                                <SubMenuItem key={index} >
                                    <NavLink onClick={closeAction} to={item.link} exact > {item.name} <SubmenuArrowIcon style={{fontSize: '2rem'}} /> </NavLink>
                                </SubMenuItem>
                                
                            ))
                        }
                </SubMenu>
            }
        </Menu>
    )
}

export default NavItem;