import React from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route} from 'react-router-dom';
import { userActions } from '../../redux/actions/user.actions';
import clsx from 'clsx';
import { config } from '../../config'
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
/* import Badge from '@material-ui/core/Badge'; */
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
/* import NotificationsIcon from '@material-ui/icons/Notifications'; */
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { mainListItems } from './listItems';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Resumen from '../Resumen';
import BannerAdmin from '../AdminPages/BannerAdmin'
import PublicationsAdmin from '../AdminPages/PublicationsAdmin'
import SliderAdmin from '../AdminPages/SliderAdmin'
import ProfessionalAdmin from '../AdminPages/ProfessionalAdmin'
import IndicationAdmin from '../AdminPages/IndicationAdmin'
import CoverageAdmin from '../AdminPages/CoverageAdmin'
import MedicalServiceAdmin from '../AdminPages/MedicalServiceAdmin'
import RecommendationAdmin from '../AdminPages/RecommendationAdmin'
import QualityPolicyAdmin from '../AdminPages/QualityPolicyAdmin'
import PageAdmin from '../AdminPages/PageAdmin'
import SectionAdmin from '../AdminPages/SectionAdmin'
import SomeNumbersAdmin from '../AdminPages/SomeNumbersAdmin'
import ContactFormAdmin from '../AdminPages/ContactFormAdmin'
import ConfigAdmin from '../AdminPages/ConfigAdmin'
import RegisterPage from '../RegisterPage';
import { Grid } from '@material-ui/core';


const Enterprise = () => {
  return (
    <Typography variant="h5" color="primary" align="center">
      <Link color="inherit" href="#"> { config.companyName } </Link>
    </Typography>
  );
}


const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  toolbarEnterprise:{
    display: 'flex',
    alignItems: 'center',
    margin: '0 auto',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    flexGrow: 1,
    height: `calc(100% - 64px)`,
    overflow: 'auto',
    position:'relative'
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})(props => (
  <Menu
    elevation={0}
    getContentAnchorEl={ null }
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
));


function Dashboard(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false); // Por simplicidad se inicia en false, pero puede ser administrado con Redux con un nuevo Reducer de estado de la app
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  
  const handleExit = (e) => {
    props.logout();
  }

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            <Router>
              <Route path="/" exact render={() => 'Dashboard'} />
            </Router>
          </Typography>
          {/* <IconButton color="inherit">
            <Badge badgeContent={1} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}

          <IconButton 
            color="inherit" 
            aria-controls="profile-menu" 
            aria-haspopup="true" 
            onClick={handleClick}
            >
                <AccountCircle />
          </IconButton>
          <StyledMenu
            id="profile-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Typography variant="subtitle1" color="primary" gutterBottom noWrap align="center">
              { props.user.firstname + ' ' + props.user.lastname }
            </Typography>
            <Divider />
            <MenuItem onClick={handleExit}>
              <ListItemIcon >
                  <ExitToAppIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Log Out" />
            </MenuItem>
          </StyledMenu>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <div  className={classes.toolbarEnterprise}>
            <Enterprise />
          </div>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          {
            mainListItems.map(menu => {
              return ( ( props.user.rol.scopes.split(" ").includes(menu.name_access) ) && <div key={menu.key} > { menu.itemmenu  } </div> )
            })
          }
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Grid container className={classes.container}>
              <Route path="/admin" exact component={Resumen} />
              <Route path="/admin/banner" component={BannerAdmin} />
              <Route path="/admin/publications" component={PublicationsAdmin} />
              <Route path="/admin/slider" component={SliderAdmin} />
              <Route path="/admin/professional" component={ProfessionalAdmin} />
              <Route path="/admin/indication" component={IndicationAdmin} />
              <Route path="/admin/coverage" component={CoverageAdmin} />
              <Route path="/admin/medicalservice" component={MedicalServiceAdmin} />
              <Route path="/admin/recommendation" component={RecommendationAdmin} />
              <Route path="/admin/qualitypolicy" component={QualityPolicyAdmin} />
              <Route path="/admin/page" component={PageAdmin} />
              <Route path="/admin/section" component={SectionAdmin} />
              <Route path="/admin/somenumbers" component={SomeNumbersAdmin} />
              <Route path="/admin/contactform" component={ContactFormAdmin} />
              <Route path="/admin/config" component={ConfigAdmin} />
              <Route path="/admin/register" component={RegisterPage} />
        </Grid>
      </main>
    </div>
  );
}


function mapState(state) {
  const { loggedIn, user } = state.authentication;
  return { loggedIn, user };
}

const actionCreators = {
  logout: userActions.logout
};

export default connect(mapState, actionCreators)(Dashboard);