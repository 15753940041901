import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import CardSlider from './CardSliderNews'
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons'
import { devices } from '../../config'
import { Swipeable } from 'react-touch'
import { connect } from 'react-redux';
import { alertActions } from '../../redux/actions/alert.actions';
import { publicationActions } from '../../redux/actions/publication.actions';


const Root = styled(Grid)`
    flex: auto;
`
const TitleSection = styled(Grid)`
    flex: auto;
    align-items: flex-start;
    justify-content: center;
    height: 22.5%;
    @media (max-width: ${devices.mobileM}px) {
        height: 17.5%;
    }
`
const Title = styled.h2`
    font-family: 'Lato';
    font-weight: 900;
    font-size: 2.5rem;
    letter-spacing: 0.5rem;
    color: #707070;
    border-bottom: solid 1vh;
    border-image: linear-gradient(270deg, #D5EE51 0%, #3DCCE9 100%);
    border-image-slice: 1;
    @media (max-width: ${devices.mobileM}px) {
        font-size: 4rem;
        letter-spacing: 0.3rem;
    }
`

const SlideContainer = styled(Grid)`
    flex: auto;
    height: 77.5%;
    overflow: hidden;
    @media (max-width: ${devices.mobileM}px) {
        height: 82.5%;
    }
`
const ArrowLeft = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 11%;
    border-radius: 50%;
    border: solid #3DCCE9 0.1rem;
    height: 5.5vmin;
    width: 5.5vmin;
    color: #3DCCE9;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
    &:hover {
        height: 6vmin;
        width: 6vmin;
    }
    @media (max-width: ${devices.mobileM}px) {
        display: none;
    }
`

const ArrowRight = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    right: 11%;
    border-radius: 50%;
    border: solid #3DCCE9 0.1rem;
    height: 5.5vmin;
    width: 5.5vmin;
    color: #3DCCE9;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: linear;
    &:hover {
        height: 6vmin;
        width: 6vmin;
    }
    @media (max-width: ${devices.mobileM}px) {
        display: none;
    }
`


function SliderNews({title, news, getTypePublications, interval}) {
    const [ forward, setForward ] = React.useState(0)

    React.useEffect(() => {
        if(!news){
            getTypePublications('noticia')
        }
        if(forward === 0 || forward === 2){
            /* const tick = setInterval(() => {
                var primero = news.shift()
                news.push(primero)
                setForward((forward === 0) ? 2 : 0)
            }, interval)
            return () => clearInterval(tick) */
        } else if(forward === 1){
            var primero = news.shift()
            news.push(primero)
            setForward(0)
        } else if(forward === -1){
            var last = news.pop()
            news.unshift(last)
            setForward(0)
        }
    }, [ forward, news, getTypePublications, interval ])

    const handleBack = (e) => {
        e.preventDefault()
        setForward((-1))
    }

    const handleForward = (e) => {
        e.preventDefault()
        setForward(1)
    }

    const handleTouchBack = () => {
        setForward(1)
    }

    const handleTouchForward = () => {
        setForward((-1))
    }

    return(
        <Root container justify="center">
            <TitleSection container item>
                <Title> {title} </Title>
            </TitleSection>  
            {
                (!news) ? <h1>CARGANDO...</h1> :
                    <Swipeable onSwipeLeft={handleTouchBack} onSwipeRight={handleTouchForward} >
                        <SlideContainer container item xs={8} justify="center" >
                            <ArrowLeft onClick={handleForward}><ArrowBackIos style={{marginLeft: '.5vmin', height: '2vmin', width: '2vmin'}} /></ArrowLeft>
                            {
                                (news.length > 0) && <CardSlider event={news[0]} />
                            }
                            {
                                (news.length > 1) && <CardSlider event={news[1]} />
                            }
                            {
                                (news.length > 2) && <CardSlider event={news[2]} />
                            }
                            <ArrowRight onClick={handleBack}><ArrowForwardIos style={{height: '2vmin', width: '2vmin'}} /></ArrowRight>
                        </SlideContainer>
                    </Swipeable>
            }
        </Root>  
    )
}

function mapState(state) {
    const { error, isFetching, news } = state.publication;
    return { error, isFetching, news };
  }
  
const actionCreators = {
    getTypePublications: publicationActions.getTypePublications,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(SliderNews);

/* export default SliderNews */