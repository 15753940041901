import React from 'react'
import styled from 'styled-components'
import TitleSection from './TitlePage'
import ImgTitle from '../images/FrenteLocal.webp'  
import { devices } from '../config'
import News from './News'
import SideBarNews from './SideBarNews'
import IconFacebook from '../icons/inicio/b_facebook.svg'
/* import IconInstagram from '../icons/inicio/b_instagram.svg' */
import IconMail from '../icons/inicio/b_mail.svg'
import { connect } from 'react-redux';
import { alertActions } from '../redux/actions/alert.actions';
import { publicationActions } from '../redux/actions/publication.actions';

const PageName="NOTICIAS"

const Section = styled.section`
  height: auto;
  min-height: 500px;
  display: flex;
  flex: auto;
  align-items: stretch;
  justify-content: center;
  position: relative;
  margin-top: 10vh;
  margin-bottom: 10vh;
  padding-left: 10%;
  padding-right: 10%;
  @media (max-width: ${devices.tablet}px) {
    margin-top: 8vh;
    margin-bottom: 8vh;
  }
`

const Container = styled.div`
    position: relative;
    display: flex;
    width: 70%;
    align-items: flex-start;
    justify-content: flex-start;
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
    }
`

const Sidebar = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 30%;
    @media (max-width: ${devices.mobileM}px) {
        display: none;
    }
`

const ShareSection = styled.section`
    height: auto;
    min-height: 3rem;
    display: flex;
    flex: auto;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 10vh;
    margin-bottom: 10vh;
    padding-left: 10%;
    padding-right: 10%;
`

const Title = styled.section`
  height: 40vh;
  width: 100%;
  display: flex;
  flex: auto;
  align-items: stretch;
  justify-content: center;
`

const ShareText = styled.div`
    position: relative;
    display: flex;
    height: 100%;
    text-align: left;
    font-size: 1rem;
    font-family: Gotham;
    font-weight: 400;
    letter-spacing: 0.1rem;
    color: #C4C4C4;
    align-items: center;
    margin-right: 1.5rem;
`

const SocialIcon = styled.img`
    padding: 0;
    margin-left: .5rem;
    margin-right: .5rem;
    border-radius: 25px;
    width: 1.5rem;
    letter-spacing: 0.1rem;
    font-weight: 700;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`

function NewsPage({ news, getTypePublications, match}) {
  React.useEffect(() => {
    if(!news){
        getTypePublications('noticia')
    }
    window.scrollTo(0, 0)
  }, [news, getTypePublications])

  const { id } = match.params

    return(
      <React.Fragment>
        <Title>
          <TitleSection title={PageName} background={ImgTitle}/>
        </Title>
        {
          (!news) ? <h1>CARGANDO...</h1> : 
            <Section>
                <Container>
                    <News news={news.find(element => element.id === parseInt(id))} />
                </Container>
                <Sidebar>
                    <SideBarNews id={id} items={news.filter(n => n.id !== parseInt(id))} />
                </Sidebar>
            </Section>
        }
        {
          (!news) ? <h1>CARGANDO...</h1> : 
            <ShareSection>
                <ShareText>
                    COMPARTIR: 
                </ShareText>
                <a href={'http://www.facebook.com/sharer.php?u='+window.location.href+'&t='+news.find(element => element.id === parseInt(id)).title} ><SocialIcon src={IconFacebook} alt='Icon Facebook'/></a>
                {/* <a href={shareIcons.instagram} ><SocialIcon src={IconInstagram} alt='Icon Instagram'/></a> */}
                <a href={'mailto:?subject='+news.find(element => element.id === parseInt(id)).title+'&body='+window.location.href} ><SocialIcon src={IconMail} alt='Icon Mail'/></a> 
            </ShareSection>
        }
      </React.Fragment>
    )
}

function mapState(state) {
  const { error, isFetching, news } = state.publication;
  return { error, isFetching, news };
}

const actionCreators = {
  getTypePublications: publicationActions.getTypePublications,
  alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(NewsPage);


/* export default NewsPage */