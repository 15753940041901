import React from 'react'
import styled from 'styled-components'
import { devices } from '../config'
import Selector from './Selector'
import IconDireccion from '../icons/inicio/direccion.svg'
import axios from 'axios';
import { apiClinica } from "../config";

const FormContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    height:300px;
    width:50%;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-around;
    @media (max-width: ${devices.mobileM}px) {
        width: 90%;
    }
`
const Container = styled.div`
position: relative;
display: flex;
box-sizing: border-box;
flex-direction: column;
align-items: center;
width: 100%;
@media (max-width: ${devices.mobileM}px) {
    height: 95%;
}
`

function SucursalesSelector(props) {

    const selectSucursal = (name,label) => {
        props.handleAction(name,label)
    }
    const [subsidiaries, setSubsidiaries] = React.useState(null);

    React.useEffect(() => {
        if(!subsidiaries){
                axios
                .get(apiClinica.baseURL+'sucursal?turnosOnline=1',{headers:{'Authorization': 'Bearer ' + apiClinica.token}})
                .then(response => {
                    if(response.data.length > 0){
                        if(response.data){
                            setSubsidiaries(response.data)
                        }
                    }
                })
                .catch(error => {
                    alert('Hubo un error')
                })
            }
    }, [subsidiaries])
    return(
        <Container>
            <FormContainer>
            {
                subsidiaries && subsidiaries.map((subsidiarie) => 
                    <Selector IconLeft={IconDireccion} key={`subsidiarie${subsidiarie.idSucursal}`} selected={props.selected===subsidiarie.idSucursal} name={subsidiarie.idSucursal} label={subsidiarie.direccion?subsidiarie.direccion:subsidiarie.nombre} handleAction={selectSucursal} enabled={true}></Selector>   
                )
            }
            </FormContainer>  
        </Container>
    )
}

export default SucursalesSelector;