import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

// This component does not allowing login if you are already logged in

function PrivateRouteLogin({ component: Component, ...rest }){
    return (
        <Route {...rest} render={props => (
            rest.loggedIn
                ? <Redirect to={{ pathname: '/admin', state: { from: props.location } }} />
                : <Component {...props} />
        )} />
    )
} 

function mapState(state) {
    const { loggedIn, user } = state.authentication;
    return { loggedIn, user };
}
 
export default connect(mapState)(PrivateRouteLogin)