import React from 'react'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField';
import { LocationOn, PhoneInTalk, Email } from '@material-ui/icons';
import axios from 'axios';
import { devices, siteData } from '../config'

const Root = styled.div`
    position: relative;
    display: flex;
    flex: auto;
    flex-direction: column;
    padding-left: 15%;
    padding-right: 15%;
    box-sizing: border-box;
`

const TitleStyled = styled.div`
    position: relative;
    display: flex;
    flex: auto;
    box-sizing: border-box;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-start;
    color: #3A3A3A;
    font-family: 'Ubuntu';
    font-weight: 700;
    font-size: 5vmin;
    height: 11.5%;
    padding-left: 2.5vw;
    padding-bottom: 4vh;
    @media (max-width: ${devices.mobileM}px) {
        height: 7.5%;
        padding-bottom: 1rem;
    }
`

const Divider = styled.div`
    position: relative;
    display: flex;
    width: 5vw;
    height: 1%;
    background: linear-gradient(270deg, #D5EE51 0%, #3DCCE9 100%);
    @media (max-width: ${devices.mobileM}px) {
        height: .75rem;
    }
`

const Container = styled.div`
    position: relative;
    display: flex;
    flex: auto;
    flex-direction: row;
    height: 87.5%;
    @media (max-width: ${devices.mobileM}px) {
        flex-direction: column;
        align-items: center;
        height: 95%;
    }
`

const FormContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 65%;
    height: 100%;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2.5%;
    box-sizing: border-box;
    align-items: center;
    justify-content: space-around;
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
        margin-bottom: 15rem;
    }
`

const FormCompleteContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 65%;
    height: 100%;
    padding-left: 2vw;
    padding-right: 2vw;
    padding-top: 2.5%;
    box-sizing: border-box;
    align-items: flex-start;
    justify-content: flex-start;
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
        padding-left: 15%;
        padding-right: 15%;
    }
`

const TitleFormComplete = styled.div`
    position: relative;
    display: flex;
    box-sizing: border-box;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: #3A3A3A;
    font-family: 'Ubuntu';
    font-weight: 700;
    font-size: 1.5rem;
    text-align: left;
    margin-top: 10%;
`

const Form = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 95%;
    height: 60%;
    box-sizing: border-box;
`

const Message = styled.div`
    position: relative;
    display: flex;
    font-family: 'Lato';
    font-size: 1vw;
    color: #7A7A7A;
    text-align: left;
    line-height: 1.5;
    @media (max-width: ${devices.tablet}px) {
        font-size: 1rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1.5rem;
    }
`

const FormButton = styled.div`
    width: 10rem;
    height: 2rem;
    border-radius: 24px;
    background: linear-gradient(96deg, #3DCCE9 0%, #87E8FC 100%);
    color: white;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    &:hover {
        background: linear-gradient(270deg, #D5EE51 0%, #3DCCE9 100%);
    }
    @media (max-width: ${devices.tablet}px) {
        width: 30%;
        height: 7.5%;
        font-size: 1.5rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        width: 30%;
        height: 8%;
        font-size: 2rem;
    }
`

const DataContact = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 35%;
    height: 100%;
    box-sizing: border-box;
    @media (max-width: ${devices.mobileM}px) {
        width: 90%;
    }
`

const DataContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 90%;
    padding-top: 8%;
    padding-left: 5%;
    box-sizing: border-box;
    align-items: flex-start;
    background: linear-gradient(180deg, #87E8FC 0%, #3DCCE9 100%);
    font-family: 'Lato';
    font-size: 1vw;
    color: white;
    @media (max-width: ${devices.mobileM}px) {
        width: 100%;
        align-items: flex-start;
        padding-left: 15%;
        padding-top: 2.5%;
    }
`
const DataTitle = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    margin-left: 8%;
    padding-left: 1vmin;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    font-family: 'Lato';
    font-size: 1.2rem;
    font-weight: 700;
    svg {
        margin-right: 1rem;
    }
    @media (max-width: ${devices.mobileM}px) {
        margin-left: 0;
        padding-left: 0;
        font-size: 2.25rem;
        svg {
            width: 2.75rem;
            height: 2.75rem;
            margin-right: 1.5rem;
        }
    }
`

const DataValue = styled.li`
    list-style: none;
    margin-left: 20%;
    margin-bottom: 0.8vmin;
    margin-top: 0.8vmin;
    font-size: 1rem;
    @media (max-width: ${devices.mobileM}px) {
        margin-left: 0;
        padding-left: 10%;
        font-size: 1.75rem;
    }
`

const DataDivider = styled.div`
    position: relative;
    display: flex;
    width: 1vw;
    height: 0.3vh;
    background-color: white;
    margin-left: 10%;
    margin-bottom: 1vmin;
    @media (max-width: ${devices.mobileM}px) {
        margin-left: 0.5rem;
        width: 2rem;
    }
`


function ContactUs(props) {

    const [ state, setState ] = React.useState({
        message: {
            userName: '',
            userPhone: '',
            userEmail: '',
            userMessage: '',
        },
        errors:{
            userName: false,
            userPhone: false,
            userEmail: false,
            userMessage: false
        },
        submitted: false
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        const { message, errors } = state;
        setState({
            ...state,
            message: {
                ...message,
                [name]: value
            },
            errors:{ ...errors }
        });
    }

    const handleForm = (e) => {
        e.preventDefault()

        const { message } = state;
        if (message.userName && message.userPhone && message.userEmail && message.userMessage) {
            axios.post( siteData.url + '/api/send/email' , {
                name: state.message.userName,
                phone: state.message.userPhone,
                email: state.message.userEmail,
                message: state.message.userMessage
                })
                .then(response => {
                    setState({ 
                        ...state,
                        submitted: true 
                    });
                })
                .catch(error => {
                    alert('Hubo un error con el envío...')
                })
        } else {
            setState({ 
                ...state,
                errors:{
                    userName: !(message.userName),
                    userPhone: !(message.userPhone),
                    userEmail: !(message.userEmail),
                    userMessage: !(message.userMessage)
                },
                submitted: false 
            });
        }
    }

    return(
      <Root>
            <TitleStyled>
                Contactanos
            </TitleStyled>
            <Divider />
            <Container>
                {
                    (!state.submitted) &&
                    <FormContainer>
                        <Message>
                            Ante cualquier duda o consulta, puede utilizar el formulario de contacto que se encuentra a continuación. Si su consulta requiere respuesta inmediata, le solicitamos que visite nuestro centro médico o se comunique telefónicamente.
                        </Message>
                        <Form>
                            <TextField id="contact_name" name="userName" label="Nombre y apellido" variant="outlined" size='small' error={state.errors.userName} onChange={handleChange} required />
                            <TextField id="contact_phone" name="userPhone" label="Teléfono" variant="outlined" size='small' error={state.errors.userPhone} onChange={handleChange} required />
                            <TextField id="contact_email" name="userEmail" label="Email" variant="outlined" size='small' error={state.errors.userEmail} onChange={handleChange} required />
                            <TextField id="contact_question" name="userMessage" label="Consulta" variant="outlined" size='small' multiline rows="6" error={state.errors.userMessage} onChange={handleChange} required />
                        </Form>
                        <FormButton onClick={handleForm}>
                            ENVIAR
                        </FormButton>
                    </FormContainer>
                }
                {
                    state.submitted &&
                    <FormCompleteContainer>
                        <TitleFormComplete>
                            Muchas gracias por <br/>contactarte con nosotros.
                        </TitleFormComplete>
                        <br/>
                        <Message>
                            A la brevedad nos pondremos en contacto <br/> para resolver tus dudas.
                        </Message>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                    </FormCompleteContainer>
                }
                
                <DataContact>
                    <DataContainer>
                        <DataTitle> <LocationOn  /> DIRECCIÓN </DataTitle>
                        <DataDivider />
                        {
                            props.dataContact.addresses.map((address, index) => (
                               <DataValue key={index}>{address}</DataValue> 
                            ))
                        }
                        <DataTitle> <PhoneInTalk  /> TELÉFONOS </DataTitle>
                        <DataDivider />
                        {
                            props.dataContact.phones.map((phone, index) => (
                               <DataValue key={index}>{phone}</DataValue> 
                            ))
                        }
                        <DataTitle> <Email  /> CORREO </DataTitle>
                        <DataDivider />
                        {
                            props.dataContact.emails.map((email, index) => (
                               <DataValue key={index}>{email}</DataValue> 
                            ))
                        }
                    </DataContainer>
                </DataContact>
            </Container>
      </Root>
    )
}

export default ContactUs