import React from 'react'
import { connect } from 'react-redux';
import { alertActions } from '../../redux/actions/alert.actions';
import { qualityPolicyActions } from '../../redux/actions/quality_policy.actions';
import axios from 'axios';
import styled from 'styled-components'
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import TextField from '@material-ui/core/TextField';
import { Input } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Button from '@material-ui/core/Button';
import BasicEditor from '../../components/textEditors/basicEditor';

const ModalStyled = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px 10px 10px 10px!important;
`

const ModalContent = styled.form`
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: white;
    color: black;
    box-sizing: border-box;
    border-radius: 10px 10px 10px 10px;
    width: 45%;
    height: 80%;
    align-items: center;
    justify-content: center;
    overflow: auto;
`

const ModalTitle = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 10%;
    color: white;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    align-items: center;
    justify-content: space-between;
    padding-left: 25px;
    padding-right: 25px;
    background-color: #3f51b5;
`

const ModalContentSup = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 40%;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 50px;
    align-self: center;
`

const ModalContentSupLeft = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 65%;
    height: 100%;
`

const ModalContentSupRight = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 35%;
    height: 100%;
`

const Img = styled.img`
    position: relative;
    display: flex;
    width: 150px;
    height: 150px;
    align-self: center;
    margin-bottom: 10px;
`

const ModalContentInf = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    height: 50%;
    align-items: flex-start;
    justify-content: center;
    padding-left: 25px;
    padding-right: 25px;
`

const MediumField = styled(TextField)`
    width: 45%;
`


const UploadImage = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;
    margin-bottom: 50px;
`

//Root Wrapper of the Editor 
const EditorWrapper = styled.div`
  min-width: 700px;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 95%;
  * {
    box-sizing: border-box;
  }
`
//DraftEditor Container 
const EditorContainer = styled.div`
  display: flex;
  min-height: 9em;
  height: 100%;
  border-radius: 0 0 3px 3px;
  background-color: #fff;
  padding: 10px;
  font-size: 16px;
  font-weight: 300;
  box-shadow: 0px 0px 3px 1px rgba(15, 15, 15, 0.17);
  overflow: auto;
`

function ModalUpdateQualityPolicy({ rowData, updateQualityPolicy, alertMessage, user, handleClose, open }) {

    const [ image, setImage ] = React.useState('')
    const [ qua, setQua ] = React.useState({ ...rowData, updatedBy: user.id });
    const [ errors, setErrors ] = React.useState({
        order: false,
        text: false,
        img: false,
    })

    const handleCloseModal = () => {
        setErrors({
            order: false,
            text: false,
            img: false,
        })
        setImage('')
        setQua({})
        handleClose();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setQua({
            ...qua,
            [name]: value
        })
        setErrors({
            ...errors,
            [name]: false
        })
    }

    const onFileChange = (e) => {
        setImage(e.target.files[0])
    }

    const handleFileUpload = (e) => {
        if(image !== '' ){
            if(!qua.img){
                const formData = new FormData()
                formData.append('image', image)
                axios.post("/api/images/upload", formData, {
                }).then(res => {
                    const url_img = '/uploads/'+res.data.filename
                    setQua({
                        ...qua,
                        img: url_img
                    })
                    setErrors({
                        ...errors,
                        img: false
                    })    
                })
                .catch(error => {
                    alertMessage('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                })
            } else {
                // eslint-disable-next-line no-restricted-globals
                if(confirm('¿Desea subir esta nueva imagen y reemplazar la anterior?')){
                    const formData = new FormData()
                    formData.append('image', image)
                    axios.post("/api/images/upload", formData, {
                    }).then(res => {
                        const url_img = '/uploads/'+res.data.filename
                        setQua({
                            ...qua,
                            img: url_img
                        })
                        setErrors({
                            ...errors,
                            img: false
                        })    
                    })
                    .catch(error => {
                        alertMessage('Error al cargar la imagen. Sólo se soporta hasta 2MB y los tipos jpeg-jpg-png-gif-webp.')
                    })  
                }
            }
        }else{
            alertMessage("Debe selecionar una imagen...")
        }
    }    

    const handleForm = (e) => {
        e.preventDefault()
        if(qua.order && qua.text && qua.img && qua.updatedBy){
            updateQualityPolicy(rowData.id, qua)
            handleCloseModal()            
        }else{
            setErrors({
                order: !(qua.order),
                text: !(qua.text),
                img: !(qua.img),
            })
        }
    }

    const handleEditor = (e, dataeditor) => {
        setQua({
            ...qua,
            text: dataeditor
        })
    }


    return(
        <ModalStyled
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <ModalContent>
                    <ModalTitle>
                        EDITAR POLÍTICA DE CALIDAD
                        <Button variant="contained" onClick={handleForm}>GUARDAR</Button>
                    </ModalTitle>
                    <ModalContentSup>
                        <ModalContentSupLeft>
                            <MediumField id="order" name="order" label="Orden" type="number" variant="outlined" size='small' value={qua.order} error={errors.order} onChange={handleChange} required />
                        </ModalContentSupLeft>
                        <ModalContentSupRight>
                            <Img src={qua.img ? qua.img : "https://colegiolovaina.cl/wp-content/plugins/learnpress/assets/images/no-image.png"} alt="Imagen subida" />
                            <UploadImage>
                                <Input style={{fontSize: '0.75rem', width: '300px', color: errors.img ? 'red' : 'black'}} id="image" name="image" type="file" onChange={onFileChange} error={errors.img} required /><CloudUploadIcon onClick={handleFileUpload} />
                            </UploadImage>
                        </ModalContentSupRight>
                    </ModalContentSup>
                    <ModalContentInf>
                        {   
                            qua &&
                            <BasicEditor
                                name="text"
                                value={qua.text}
                                onChange={handleEditor}
                                maxContentHeight="200px"
                                minContentHeight="200px"
                                placeholder="Inserte aquí el contenido de la política de calidad"
                            />
                        }
                    </ModalContentInf>
                </ModalContent>
            </Fade>
        </ModalStyled>
    )
}

function mapState(state) {
    const { user } = state.authentication;
    return { user };
  }
  
const actionCreators = {
    updateQualityPolicy: qualityPolicyActions.updateQualityPolicy,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(ModalUpdateQualityPolicy);