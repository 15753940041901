import React, { useState, useEffect } from 'react'
import styled, {keyframes} from 'styled-components'
/* import propTypes from 'prop-types' */
import { ArrowForwardIos } from '@material-ui/icons';
import { devices } from '../../config'
import { Swipeable } from 'react-touch'
import { connect } from 'react-redux';
import { alertActions } from '../../redux/actions/alert.actions';
import { bannerActions } from '../../redux/actions/banner.actions';

const ShowImg = keyframes`
    0% {
        z-index: 1;
        transform: scale(0.8);
        opacity: 0;
    }

    100% {
        z-index: 1;
        transform: scale(1);
        opacity: 1;
    }
`

const ShowInfo = keyframes`
    0% {
        z-index: 30;
        transform: translate(0, -100px);
        opacity: 0;
    }

    100% {
        z-index: 30;
        transform: translate(0, 0);
        opacity: 1;
    }
`

const Hide = keyframes`
    0% {
        opacity: 0.5;
    }

    100% {
        opacity: 0;
    }
`

const Slide = styled.div`
    width: 100%;
    height: 100%;
    background: white;
    color: white;
`
const SlideContainer = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin: 0 auto;
    position: absolute;
    top: 0;
    justify-content: center;
    display: flex;
    align-items: center;
`
const Img = styled.img`
    /* object-fit: cover; */
    /* height: 100%;
    width: auto; */
    width: 100%;
    height: auto;
    position: absolute;
    display: flex;
    vertical-align: middle;
    &.animaShow {
        animation: 650ms ${ShowImg} ease-out;
        animation-fill-mode: forwards;
    }
    &.animaHide {
        animation: 650ms ${Hide} ease-out;
        animation-fill-mode: forwards;
    }
`

const ZoneBackground = styled.div`
    z-index: 20;
    width: 100%;
    height:100%;
    background: black;
    opacity: 0.3;
    text-shadow: 1px 1px black;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
`
const InfoZone = styled.div`
    position: absolute;
    left: 30vmin;
    width: 30%;
    height: 20%;
    justify-content: flex-start;
    z-index: 30;
    opacity: 1;
    text-align: left;
    @media (max-width: ${devices.mobileM}px) {
        left: 25%;
        width: 50%;
        height: 30%;
        justify-content: center;
        z-index: 30;
        opacity: 1;
        text-align: center;
    }
`

const Title = styled.div`
    position: relative;
    justify-content: flex-start;
    font-size: 3rem;
    font-family: 'Ubuntu';
    font-weight: 700;
    color: white;
    opacity: 0;
    &.animaShow {
        animation: 650ms ${ShowInfo} ease-out;
        animation-fill-mode: forwards;
    }
    @media (max-width: ${devices.mobileM}px) {
        justify-content: center;
        font-size: 4rem;
    }
`

const Button = styled.button`
    position: relative;
    top: 5vmin;
    font-size: 1rem;
    font-family: 'Lato';
    font-weight: 400;
    letter-spacing: 0.2rem;
    color: white;
    border: 2px solid #3DCCE9;
    box-sizing: border-box;
    border-radius: 32px;
    background: transparent;
    align-items: center;
    justify-content: center;
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: ease-in-out;
    width: 20vw;
    height: 6vmin;
    &::before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 32px;
        background: linear-gradient(90deg, #D5EE51 0%, #3DCCE9 100%);
        transition-property: all;
        transition-duration: 300ms;
        transition-timing-function: ease-in-out;
        z-index: -1;
        opacity: 0;
    }
    &:hover {
        border: 0;
        &::before {
            opacity: 1;
        }
    }
    a {
        text-decoration: none;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 1rem;
            display: flex;
            height: 1rem;
        }
    }
    @media (max-width: ${devices.mobileM}px) {
        font-size: 1.5rem;
        width: 20rem;
        height: 4.5rem;
    }
`

const Dot = styled.button`
    z-index: 50;
    width: 2vmin;
    height: 2vmin;
    margin: 1vmin;
    border-radius: 50%;
    border: solid 0.3vmin;
    border-color: white;
    background-color: transparent;
    color: transparent;
    position: relative;
    top: -30vmin;
    transition-property: all;
    transition-duration: 200ms;
    transition-timing-function: ease-in-out;
    &.active {
        background-color: white;
    }
`



const Carousel = ({ secondary, getKeyBanners, interval }) => {

    const [ activeIndex, setActiveIndex ] = useState(0)

    useEffect(() => {
        if(!secondary){
            getKeyBanners('secondary')
        } else {
            const tick = setInterval(() => {
                if(activeIndex < secondary.length -1){
                    setActiveIndex(activeIndex + 1)
                } else {
                    setActiveIndex(0)
                }
            }, interval)
    
            return () => clearInterval(tick)
        }
        
    }, [ activeIndex, secondary, interval, getKeyBanners ])

    const handleClick = (e) => {
        e.preventDefault()
        setActiveIndex(parseInt(e.target.value))
    }

    const handleTouchLeft = () => {
        if(activeIndex < secondary.length -1){
            setActiveIndex(activeIndex + 1)
        } else {
            setActiveIndex(0)
        }
    }

    const handleTouchRight = () => {
        if(activeIndex > 0){
            setActiveIndex(activeIndex - 1)
        } else {
            setActiveIndex(secondary.length -1)
        }
    }

    return (
        <Slide>
            {
                (!secondary) ? <h1 style={{color: 'black'}}>CARGANDO...</h1> :
                <Swipeable onSwipeLeft={handleTouchLeft} onSwipeRight={handleTouchRight} >
                    <SlideContainer>
                        {secondary.map((image, index) => (
                            <React.Fragment key={index}>
                                <Img
                                    src={image.img}
                                    alt={image.title}
                                    
                                    className= {
                                        index === activeIndex
                                        ? 'animaShow'
                                        : 'animaHide'
                                    }
                                />
                                {
                                    secondary[activeIndex].shadow && <ZoneBackground/>
                                }
                                <InfoZone >
                                    {
                                        secondary[activeIndex].title && 
                                        <Title className= {
                                            index === activeIndex
                                            ? 'animaShow'
                                            : 'animaHide'
                                        }>{ secondary[activeIndex].title }</Title>
                                    }
                                    {
                                        secondary[activeIndex].textLink &&
                                        <Button> <a href={secondary[activeIndex].link}> {secondary[activeIndex].textLink} <ArrowForwardIos /> </a> </Button>
                                    }
                                </InfoZone>
                                <Dot onClick={handleClick}  value={index}  className= {
                                        index === activeIndex
                                        ? 'active'
                                        : 'inactive'
                                    }
                                />
                            </React.Fragment>
                        ))}
                    </SlideContainer>
                </Swipeable>
            }
        </Slide>
    )
}


function mapState(state) {
    const { error, isFetching, secondary } = state.banner;
    return { error, isFetching, secondary };
}
  
const actionCreators = {
    getKeyBanners: bannerActions.getKeyBanners,
    alertMessage: alertActions.error
}

export default connect(mapState, actionCreators)(Carousel);


/* export default Carousel */